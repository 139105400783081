import clsx from 'clsx';
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowForward, Drafts, Groups, Mail } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { ProgressPanel } from '../../components';
import { getYesterdayVisitors, query } from '../../common/NetworkManager';

const useStyles = makeStyles(theme => ({
    container: {
        flexDirection: 'column',
        flex: 1,
        padding: 14,
    },
    content1Container: {
        flexWrap: 'wrap',
    },
    card: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'rgba(255, 255, 255, 0.09)',
        margin: 14,
        borderRadius: 8,
        minWidth: 480,
    },
    cardTop: {
        flex: 1,
        padding: 24,
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
    cardBottom: {
        padding: 24,
    },
    cardTextContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
    linkText: {
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.primary.dark,
    },
    avatar: { '&&': {
        width: 86,
        height: 86,
        marginRight: 32,
    }},
    mailIcon: { '&&': {
        fontSize: 48
    }},
    newMail: { '&&': {
        color: theme.palette.primary.dark,
    }},
}));

const Home = () => {
    const [counselingCount, setCounselingCount] = useState();
    const [visitorCount, setVisitorCount] = useState();
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async() => {
            const counselingResult = await query('getCounselingUnreadCount');
            setCounselingCount(counselingResult[0].cnt);

            const visitorResult = await getYesterdayVisitors();
            setVisitorCount(Object.values(visitorResult)[0]);
        }
        fetchData();
    }, []);

    return (
        <div className={classes.container}>
            <Typography fontSize={32} fontWeight='bold' style={{ margin: 32, marginLeft: 64 }}>OVERVIEW</Typography>
            <div className={classes.content1Container}>
                <div className={classes.card}>
                    <div className={classes.cardTop}>
                        <Avatar className={classes.avatar}>
                            {0 ?
                            <Drafts className={classes.mailIcon} /> :
                            <Mail className={clsx(classes.mailIcon, classes.newMail)} />}
                        </Avatar>
                        {counselingCount == null ? <ProgressPanel /> :
                        <div className={classes.cardTextContainer}>
                            <Typography fontSize={28} fontWeight='bold' style={{ marginBottom: 8 }}>
                                {0 ? '상담 신청이 없습니다.' : `창업 상담 ${counselingCount}건`}
                            </Typography>
                            <Typography color='rgba(255, 255, 255, 0.6)'>
                                {0 ? '창업 상담' : '새로운 상담 신청이 도착했습니다.'}
                            </Typography>
                        </div>}
                    </div>
                    <div className={classes.cardBottom}>
                        <Link to='/admin/counseling' className={classes.linkText}>
                            <Typography fontSize={15} fontWeight='bold' style={{ marginRight: 8 }}>
                                모든 상담 조회
                            </Typography>
                            <ArrowForward />
                        </Link>
                    </div>
                </div>
                <div className={classes.card}>
                    <div className={classes.cardTop}>
                        <Avatar className={classes.avatar}>
                            <Groups className={classes.mailIcon} />
                        </Avatar>
                        {visitorCount == null ? <ProgressPanel /> :
                        <div className={classes.cardTextContainer}>
                            <Typography fontSize={28} fontWeight='bold' style={{ marginBottom: 8 }}>
                                {`방문자 ${visitorCount}명`}
                            </Typography>
                            <Typography color='rgba(255, 255, 255, 0.6)'>어제의 총 방문자 수</Typography>
                        </div>}
                    </div>
                    <div className={classes.cardBottom}>
                        <Link to='/admin/visitor' className={classes.linkText}>
                            <Typography fontSize={15} fontWeight='bold' style={{ marginRight: 8 }}>
                                자세히 보기
                            </Typography>
                            <ArrowForward />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;