import { useCallback, useEffect, useState } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import Layout from "./Layout";
import { getBanner, getMenu, query } from "./common/NetworkManager";
import { BrandStory, Home, MenuScreen } from "./screens";
import { AdminLayout, AdminHome, AdminMenu, AdminCategory, AdminNoticeWrite, AdminCounseling, AdminBanner, AdminTopping, AdminFaq, AdminEnv, AdminLogin, AdminVisitorReport } from './screens/admin'
import { Competition, Counseling, Faq, Finance, Procedure } from "./screens/foundation";
import { Notice, NoticeRead } from "./screens/community";
import Identity from "./screens/Identity";
import { createTheme, ThemeProvider } from "@mui/material";
import themeData from './theme.json';
import { useSnackbar } from "notistack";
import { PopupBanner } from "./components";
import Stores from "./screens/Stores";

const normalTheme = createTheme(themeData);

const App = () => {
    const [menuData, setMenuData] = useState();
    const [cateData, setCateData] = useState();
    const [bannerData, setBannerData] = useState();
    const [companyData, setCompanyData] = useState();
    
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (enqueueSnackbar) {
            window.enqueueSnackbar = enqueueSnackbar;
        }
    }, [enqueueSnackbar]);

    const fetchMenuData = useCallback(async() => {
        const data = await getMenu();

        const menuData = data.menu.map(item => ({
            ...item,
            topping: JSON.parse(item.topping),
        }))

        const cateData = data.category.filter(item => item.parentId == null).map(item => {
            const children = data.category.filter(childItem => childItem.parentId === item.id).map(childItem => ({
                ...childItem,
                menuCnt: data.menu.filter(menuItem => menuItem.categoryId === childItem.id).length,
            }));
            return {
                ...item,
                menuCnt: children.reduce((result, item) => result + item.menuCnt, 0),
                children,
            }
        });

        setMenuData(menuData);
        setCateData(cateData);
    }, []);

    const fetchBannerData = useCallback(async() => {
        const bannerData = await getBanner();
        setBannerData(bannerData);
    }, []);

    const fetchCompanyData = useCallback(async() => {
        const companyData = await query('getCompany');
        setCompanyData(companyData.reduce((result, item) => ({
            ...result,
            [item.id]: item.value
        }), {}));
    }, []);

    useEffect(() => {
        fetchMenuData();
        fetchBannerData();
        fetchCompanyData();
    }, [fetchMenuData, fetchBannerData, fetchCompanyData]);

    return (
        menuData && cateData && bannerData && companyData &&
        <ScrollToTop cateData={cateData}>
        <PopupBanner />
        <Routes>
            <Route path='/*' element={<Layout companyData={companyData} cateData={cateData} />}>
                <Route path='*' element={<Home bannerData={bannerData} cateData={cateData} menuData={menuData} companyData={companyData} />} />
                <Route path='brandstory' element={<Outlet />}>
                    <Route index element={<BrandStory />} />
                    <Route path='brandstory' element={<BrandStory />} />
                    <Route path='identity' element={<Identity />} />
                </Route>
                <Route path='menu' element={<MenuScreen cateData={cateData} menuData={menuData} companyData={companyData} />}>
                    <Route path=':categoryId' element={<Outlet />} />
                </Route>
                <Route path='foundation' element={<Outlet />}>
                    <Route index element={<Competition />} />
                    <Route path='competition' element={<Competition />} />
                    <Route path='procedure' element={<Procedure />} />
                    <Route path='finance' element={<Finance />} />
                    <Route path='counseling' element={<Counseling />} />
                    <Route path='faq' element={<Faq />} />
                    <Route path='faq/:page' element={<Faq />} />
                </Route>
                <Route path='stores' element={<Outlet />}>
                    <Route index element={<Stores />} />
                </Route>
                <Route path='community' element={<Outlet />}>
                    <Route index element={<Notice />} />
                    <Route path='notice' element={<Notice />} />
                    <Route path='notice/:page' element={<Notice />} />
                    <Route path='notice/read/:id' element={<NoticeRead />} />
                </Route>
            </Route>
            <Route path='admin' element={<AdminLayout />}>
                <Route index path='*' element={<AdminHome />} />
                <Route path='login' element={<AdminLogin />} />
                <Route path='category' element={<AdminCategory fetchMenuData={fetchMenuData} data={cateData} />} />
                <Route path='menu' element={<AdminMenu fetchMenuData={fetchMenuData} menuData={menuData} cateData={cateData} />} />
                <Route path='topping' element={<AdminTopping />} />
                <Route path='counseling' element={<AdminCounseling />} />
                <Route path='faq' element={<AdminFaq />} />
                <Route path='notice' element={<ThemeProvider theme={normalTheme}><Notice admin /></ThemeProvider>} />
                <Route path='notice/:page' element={<ThemeProvider theme={normalTheme}><Notice admin /></ThemeProvider>} />
                <Route path='notice/read/:id' element={<ThemeProvider theme={normalTheme}><NoticeRead admin /></ThemeProvider>} />
                <Route path='notice/edit' element={<ThemeProvider theme={normalTheme}><AdminNoticeWrite /></ThemeProvider>} />
                <Route path='visitor' element={<AdminVisitorReport />} />
                <Route path='banner' element={<AdminBanner fetchBannerData={fetchBannerData} data={bannerData} />} />
                <Route path='env' element={<AdminEnv fetchCompanyData={fetchCompanyData} />} />
            </Route>
        </Routes>
        </ScrollToTop>
    );
};

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        const splits = location.pathname.split('/');
        if (splits[1] === 'menu') {
            if (props.cateData.findIndex(item => item.id === splits[2]) !== -1) {
                return;
            }
        }
        window.scrollTo(0, 0);
    }, [location, props.cateData]);
  
    return props.children
};

export default App;