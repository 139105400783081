import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { createRef, forwardRef, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { getImageUrl } from "../common/NetworkManager";
import { IconButton, ImageBox, MenuItem, MenuItemSlider, MenuPopup } from "../components";
import { Dialog, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RouteChangeTracker } from '../common/Utils';

const useStyles = makeStyles(theme => ({
    container: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 250,
        width: '100%',
        [theme.breakpoints.down('mobile')]: {
            marginBottom: 65,
        },
    },
    bannerContainer: {
        position: 'relative',
        justifyContent: 'center',
    },
    bannerImage: {
        width: '100%',
    },
    titleContainer: {
        position: 'absolute',
        width: 'calc(100% - 40px)',
        maxWidth: 1300,
        marginLeft: 40,
        flexDirection: 'column',
        bottom: -70,
        whiteSpace: 'nowrap',
        zIndex: 1,
        [theme.breakpoints.down('mobile')]: {
            marginLeft: 20,
            bottom: -22,
        }
    },
    titleMask: {
        width: 552,
        filter: 'brightness(0) invert(1)',
        opacity: 0.86,
        [theme.breakpoints.down('mobile')]: {
            width: 150,
        },
    },
    titleText: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 90,
        letterSpacing: -0.63,
        marginTop: 12,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 29,
            letterSpacing: -0.2,
            marginTop: 0,
        },
    },
    menuContainer: {
        position: 'sticky',
        top: 100,
        marginTop: 220,
        paddingBottom: 20,
        width: '100%',
        overflowX: 'auto',
        justifyContent: 'center',
        backgroundColor: 'white',
        transition: theme.transitions.create('box-shadow'),
        [theme.breakpoints.down('tablet')]: {
            top: 70,
        },
        [theme.breakpoints.down('mobile')]: {
            marginTop: 73,
            paddingBottom: 10,
        }
    },
    appbarShadow: {
        zIndex: 6,
        boxShadow: '2px 2px 2px #00000040',
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.default,
        boxShadow: '2px 2px 2px #00000029',
        borderRadius: 13,
        minWidth: 140,
        padding: 20,
        marginLeft: 25,
        marginRight: 25,
        [theme.breakpoints.down('mobile')]: {
            borderRadius: 7,
            minWidth: 95,
            padding: 15,
            marginLeft: 6,
            marginRight: 6,
        },
        [theme.breakpoints.up('mobile')]: {
            '&:hover': {
                '& img': {
                    filter: 'brightness(0) saturate(100%) invert(96%) sepia(29%) saturate(2138%) hue-rotate(298deg) brightness(111%) contrast(93%)',
                },
                '& div': {
                    color: '#F6C59A',
                },
            },
        },
    },
    menuIcon: {
        width: 40,
        height: 40,
        marginRight: 18,
        objectFit: 'contain',
        [theme.breakpoints.down('mobile')]: {
            width: 20,
            height: 20,
        },
    },
    itemText: {
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontSize: 17,
        letterSpacing: -0.12,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 14,
            marginLeft: 14,
            letterSpacing: -0.1,
        },
    },
    mask: {
        width: '100%',
        marginTop: 80,
        marginBottom: 50,
        [theme.breakpoints.down('mobile')]: {
            marginBottom: 0,
        },
    },
    maskOdd: {
        filter: 'brightness(0) saturate(100%) invert(11%) sepia(94%) saturate(3925%) hue-rotate(215deg) brightness(102%) contrast(102%)',
    },
}));

const MenuScreen = ({ cateData, menuData, companyData }) => {
    RouteChangeTracker('/menu');
    const [menuDetail, setMenuDetail] = useState();
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);
    const categoryRefs = useMemo(() => Array.from({ length: cateData.length }).map(() => createRef()), [cateData]);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
    const headerRef = useRef();
    const params = useParams();
    const classes = useStyles();

    useEffect(() => {
        if (params.categoryId) {
            const idx = cateData.findIndex(item => item.id === params.categoryId);
            if (idx !== -1) {
                menuClick(idx);
            }
        }
        //
    }, [params]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        const handleScroll = () => {
            const appbarHeight = isMobile ? 70 : 100;
            if (Math.round(window.scrollY) >= headerRef.current.offsetTop - appbarHeight) {
                setIsHeaderSticky(true);
            } else {
                setIsHeaderSticky(false);
            }
        }
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const menuGroupedData = useMemo(() => (
        cateData.reduce((result, item) => ({
            ...result,
            [item.id]: item.children.flatMap(childItem => menuData.filter(menuItem => menuItem.categoryId === childItem.id)),
        }), {})
    ), [cateData, menuData]);

    const menuClick = index => {
        const marginTop = isMobile ? 140 : 250;
        window.scroll(0, categoryRefs[index].current.offsetTop - marginTop);
    }

    return (
        <div className={classes.container}>
            <div className={classes.bannerContainer}>
                <img alt=' ' className={classes.bannerImage} src={require('../res/common/banner_menu.jpg')} />
                <div className={classes.titleContainer}>
                    <img alt=' ' className={classes.titleMask} src={require('../res/ui/mask_blue.png')} />
                    <div className={classes.titleText}>WAFFLE STUDIO</div>
                </div>
            </div>
            <div ref={headerRef} className={clsx(classes.menuContainer, { [classes.appbarShadow]: isHeaderSticky })}>
                {cateData.map((item, index) => (
                    <div key={item.id} className={classes.menuItem} onClick={() => menuClick(index)}>
                        {item.image &&
                        <img alt=' ' className={classes.menuIcon} src={getImageUrl(item.image)} />}
                        <div className={classes.itemText}>{item.name}</div>
                    </div>
                ))}
            </div>
            {cateData.map((item, index) => (
                <Fragment key={item.id}>
                    <Category
                        ref={categoryRefs[index]}
                        cateData={item}
                        menuData={menuGroupedData[item.id]}
                        itemClick={data => setMenuDetail(data)}
                        sliderSpeed={companyData.sliderMenuSpeed}
                        isMobile={isMobile}
                    />
                    {index !== cateData.length - 1 &&
                    <img
                        alt=' '
                        className={clsx(classes.mask, { [classes.maskOdd]: index % 2 === 1 })}
                        src={require('../res/ui/mask_orange.png')}
                    />}
                </Fragment>
            ))}
            <MenuPopup
                open={menuDetail != null}
                onClose={() => setMenuDetail()}
                data={menuDetail}
            />
        </div>
    )
}

const useItemStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 77,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 61,
        },
    },
    mainCateTitle: {
        position: 'relative',
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontWeight: 600,
        fontSize: 50,
        letterSpacing: -0.35,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 24,
            letterSpacing: -0.17,
        },
    },
    mainCateTitleMask: {
        position: 'absolute',
        width: '100%',
        bottom: -10,
        filter: 'brightness(0) saturate(100%) invert(45%) sepia(92%) saturate(870%) hue-rotate(347deg) brightness(98%) contrast(94%)',
        [theme.breakpoints.down('mobile')]: {
            bottom: -5,
        },
    },
    bestContainer: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontWeight: 500,
        fontSize: 28,
        letterSpacing: -0.2,
        marginTop: 58,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 14,
            letterSpacing: -0.1,
            marginTop: 24,
        },
    },
    scrollContainer: {
        width: '100%',
        overflowX: 'auto',
        justifyContent: 'center',
        paddingBottom: 3,
    },
    scrollButton: {
        boxShadow: '2px 2px 2px #00000029',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontSize: 28,
        letterSpacing: -0.2,
        padding: 20,
        marginTop: 105,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: 13,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 14,
            letterSpacing: -0.1,
            padding: 11,
            marginTop: 54,
            marginLeft: 5,
            marginRight: 5,
            borderRadius: 7,
        },
        [theme.breakpoints.up('mobile')]: {
            '&:hover': {
                color: '#F6C59A',
            },
        },
    },
    subCateContainer: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    subCateTitle: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontWeight: 500,
        fontSize: 42,
        letterSpacing: -0.29,
        marginTop: 70,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 21,
            letterSpacing: -0.15,
            marginTop: 50,
        },
    },
    subCateDesc: {
        whiteSpace: 'pre-line',
        textAlign: 'center',
        wordBreak: 'keep-all',
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 24,
        letterSpacing: -0.72,
        marginTop: 24,
        lineHeight: '34px',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 13,
            letterSpacing: -0.52,
            marginTop: 12,
            lineHeight: '17px',
        }
    },
    coffeeButtonContainer: {
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    coffeeButton: {
        justifyContent: 'center',
        boxShadow: '2px 2px 2px #00000029',
        cursor: 'pointer',
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontWeight: 300,
        fontSize: 30,
        letterSpacing: -0.21,
        marginTop: 50,
        marginLeft: 40,
        marginRight: 40,
        paddingTop: 10,
        paddingBottom: 10,
        width: 288,
        borderRadius: 13,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 15,
            letterSpacing: -0.1,
            marginTop: 25,
            marginLeft: 6,
            marginRight: 6,
            paddingTop: 5,
            paddingBottom: 5,
            width: 144,
            borderRadius: 7,
        },
    },
    selectedButton: {
        color: '#F37428'
    },
    coffeeDescContainer: {
        position: 'relative',
        flex: 'unset',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: 500,
        marginTop: 100,
        marginBottom: 10,
    },
    coffeeImageFilter: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        opacity: 0.20,
    },
    coffeeDesc: {
        position: 'absolute',
        fontFamily: 'NotoSans',
        whiteSpace: 'pre-line',
        wordBreak: 'keep-all',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: 870,
        width: '100%',
        zIndex: 1,
        transition: theme.transitions.create('opacity'),
        opacity: 0,
    },
    coffeeDescSelected: {
        opacity: 1,
    },
    coffeeDesc1: {
        flexDirection: 'column',
        width: 256,
        marginLeft: 16,
    },
    coffeeDesc1Title: {
        color: '#FEFEFE',
        fontWeight: 500,
        fontSize: 34,
        letterSpacing: -0.24,
    },
    coffeeDesc1Content: {
        color: '#F5F5F5',
        fontSize: 16,
        letterSpacing: -0.61,
        lineHeight: '20px',
        marginTop: 13,
    },
    coffeeDesc2: {
        flexDirection: 'column',
        width: 256,
    },
    coffeeDesc2Title: {
        color: '#FEFEFE',
        fontWeight: 500,
        fontSize: 24,
        letterSpacing: -0.36,
    },
    coffeeDesc2Content: {
        color: '#FEFEFE',
        fontSize: 18,
        letterSpacing: -0.27,
        lineHeight: '28px',
        marginTop: 13,
    },
    coffeeDialog: {
        justifyContent: 'center',
        '& .MuiDialog-container': {
            width: '100%',
        },
    },
    coffeeDialogPaper: {
        width: '100%',
        fontFamily: 'NotoSans',
        color: theme.palette.text.primary,
        whiteSpace: 'pre-line',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: 300,
        paddingTop: 67,
        paddingBottom: 30,
        borderRadius: 7,
    },
    coffeeDialogTitle: {
        fontFamily: 'SCDream',
        fontSize: 17,
        letterSpacing: -0.58,
    },
    coffeeDialogContent: {
        fontWeight: 300,
        fontSize: 13,
        letterSpacing: -0.48,
        lineHeight: '16px',
        marginTop: 12,
    },
    coffeeDialogBlendTitle: {
        fontSize: 12,
        letterSpacing: -0.18,
        marginTop: 30,
    },
    coffeeDialogBlend: {
        fontWeight: 300,
        fontSize: 12,
        letterSpacing: -0.18,
        marginTop: 3,
    },
    coffeeDialogClose: { '&&': {
        position: 'absolute',
        top: 30,
        right: 30,
    }},
    closeImage: {
        width: 20,
        height: 20,
    },
    menuItemContainer: {
        flexWrap: 'wrap',
        width: 1360,
        marginTop: 30,
        [theme.breakpoints.down(1360)]: {
            width: 1020,
        },
        [theme.breakpoints.down(1020)]: {
            width: 680,
        },
        [theme.breakpoints.down('mobile')]: {
            width: 664,
        },
        [theme.breakpoints.down('664')]: {
            width: 498,
        },
        [theme.breakpoints.down('498')]: {
            width: 332,
        },
        [theme.breakpoints.down('332')]: {
            width: 166,
        },
    },
    comingsoonImage: {
        width: 400,
        height: 400,
        marginLeft: 'calc(50% - 200px)',
        [theme.breakpoints.down('mobile')]: {
            width: 200,
            height: 200,
            marginLeft: 'calc(50% - 100px)',
        },
    },
}));

const coffeeData = [
    {
        title: '캠프파이어 블렌드',
        content: `NSWF 시그니처 블렌드이자
            복합적인 향미를 균형감 있게 나타내는데
            중점을 둔 커피입니다.
            은은한 산미, 이를 뒤따르는
            단맛의 보드라운 질감이 긴 여운으로
            잔잔하게 입안을 감돕니다.
            어떤 요소 하나 두드러짐 없는
            편안한 커피를 의도하였습니다.
            누구나 매일 마시기 좋은
            데일리 커피로 추천합니다.`,
        blending: `브라질 몬테알토
            콜롬비아 포파얀
            과테말라 와이칸`,
    },
    {
        title: '포트리스 블렌드',
        content: `농밀하고도 묵직한 맛이 인상적이며
            구운견과의 늬앙스,
            고소하고 쌉싸름한 다크초콜릿의
            진득한 풍미가 처음부터 끝까지 유지되는
            단순함이 특징입니다.
            수원 화성 안을 거니는 휴식의 순간처럼
            온전한 쉼의 기분을 선사하는 커피입니다.`,
        blending: `브라질 세하도
            콜롬비아 안티오키아 수프리모`,
    }
]

const Category = forwardRef(({ cateData, menuData, itemClick, sliderSpeed, isMobile }, ref) => {
    const [blending, setBlending] = useState(0);
    const [blendingPopup, setBlendingPopup] = useState({});
    const subRefs = useMemo(() => Array.from({ length: cateData.children.length }).map(() => createRef()), [cateData]);
    const classes = useItemStyles();

    const menuGroupedData = useMemo(() => (
        menuData.reduce((result, item) => ({
            ...result,
            [item.categoryId]: [...(result[item.categoryId] || []), item],
        }), {})
    ), [menuData])
    const bestData = useMemo(() => menuData.filter(item => !!item.best), [menuData]);

    const subClick = index => {
        const marginTop = isMobile ? 100 : 180;
        window.scroll(0, subRefs[index].current.offsetTop - marginTop);
    }

    return (
        <div ref={ref} className={classes.container}>
            <div className={classes.mainCateTitle}>
                {cateData.name}
                <img alt=' ' className={classes.mainCateTitleMask} src={require('../res/ui/mask_blue.png')} />
            </div>
            {bestData.length > 3 &&
            <div className={classes.bestContainer}>
                BEST
                <MenuItemSlider data={bestData} onClick={item => itemClick(item)} style={{ marginTop: 0 }} speed={sliderSpeed} />
            </div>}
            <div className={classes.scrollContainer}>
                {cateData.children.length > 1 && cateData.children.map((item, index) => (
                    <div key={item.id} className={classes.scrollButton} onClick={() => subClick(index)}>
                        {item.name}
                    </div>
                ))}
            </div>
            {cateData.children.map((cateItem, index) => (
                <div key={cateItem.id} ref={subRefs[index]} className={classes.subCateContainer}>
                    {cateItem.id !== '1669395526990' ?
                        <Fragment>
                            <div className={classes.subCateTitle}>{cateItem.name}</div>
                            <div className={classes.subCateDesc}>{cateItem.description}</div>
                        </Fragment> :
                        <Fragment>
                            <div style={{ marginTop: 40 }} className={classes.subCateDesc}>{cateItem.description}</div>
                            <div className={classes.coffeeButtonContainer}>
                                <div
                                    className={clsx(classes.coffeeButton, {
                                        [classes.selectedButton]: !isMobile && blending === 0
                                    })}
                                    onMouseEnter={() => !isMobile && setBlending(0)}
                                    onClick={() => isMobile && setBlendingPopup(coffeeData[0])}
                                >
                                    캠프파이어 블랜드
                                </div>
                                <div
                                    className={clsx(classes.coffeeButton, {
                                        [classes.selectedButton]: !isMobile && blending === 1
                                    })}
                                    onMouseEnter={() => !isMobile && setBlending(1)}
                                    onClick={() => isMobile && setBlendingPopup(coffeeData[1])}
                                >
                                    포트리스 블랜드
                                </div>
                            </div>
                            {isMobile ?
                            <Dialog
                                open={blendingPopup.title != null}
                                onClose={() => setBlendingPopup({})}
                                className={classes.coffeeDialog}
                                PaperProps={{ className: classes.coffeeDialogPaper }}
                            >
                                <div className={classes.coffeeDialogTitle}>{blendingPopup.title}</div>
                                <div className={classes.coffeeDialogContent}>{blendingPopup.content}</div>
                                <div className={classes.coffeeDialogBlendTitle}>블렌딩</div>
                                <div className={classes.coffeeDialogBlend}>{blendingPopup.blending}</div>
                                <IconButton
                                    className={classes.coffeeDialogClose}
                                    tooltip='닫기'
                                    icon={<img alt='닫기' className={classes.closeImage} src={require('../res/icon/btn_close.png')} />}
                                    onClick={() => setBlendingPopup({})}
                                />
                            </Dialog> :
                            <ImageBox className={classes.coffeeDescContainer} src={require('../res/common/banner_coffee.jpg')}>
                                <div className={classes.coffeeImageFilter} />
                                <div className={clsx(classes.coffeeDesc, { [classes.coffeeDescSelected]: blending === 0 })}>
                                    <div className={classes.coffeeDesc1}>
                                        <div className={classes.coffeeDesc1Title}>{coffeeData[0].title}</div>
                                        <div className={classes.coffeeDesc1Content}>{coffeeData[0].content}</div>
                                    </div>
                                    <div className={classes.coffeeDesc2}>
                                        <div className={classes.coffeeDesc2Title}>블렌딩</div>
                                        <div className={classes.coffeeDesc2Content}>{coffeeData[0].blending}</div>
                                    </div>
                                </div>
                                <div className={clsx(classes.coffeeDesc, { [classes.coffeeDescSelected]: blending === 1 })}>
                                    <div className={classes.coffeeDesc1}>
                                        <div className={classes.coffeeDesc1Title}>{coffeeData[1].title}</div>
                                        <div className={classes.coffeeDesc1Content}>{coffeeData[1].content}</div>
                                    </div>
                                    <div className={classes.coffeeDesc2}>
                                        <div className={classes.coffeeDesc2Title}>블렌딩</div>
                                        <div className={classes.coffeeDesc2Content}>{coffeeData[1].blending}</div>
                                    </div>
                                </div>
                            </ImageBox>}
                        </Fragment>
                    }
                    <div className={classes.menuItemContainer}>
                        {menuGroupedData[cateItem.id] ?
                        menuGroupedData[cateItem.id].map(menuItem => (
                            <MenuItem key={menuItem.id} data={menuItem} onClick={() => itemClick(menuItem)} />
                        )) :
                        <img alt='Coming soon' className={classes.comingsoonImage} src={require('../res/common/comingsoon.png')} />}
                    </div>
                </div>
            ))}
        </div>
    )
})

export default MenuScreen;