import clsx from 'clsx';
import { Add, AddPhotoAlternate, Check, Clear, Close, Delete, Edit, ImageSearch, KeyboardArrowDown, KeyboardArrowUp, Sort } from "@mui/icons-material";
import { Dialog, DialogTitle, InputLabel, List, ListItem, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useMemo, useState } from "react";
import { FilePicker, IconButton, ProgressDialog, QuestionDialog } from "../../components";
import { getImageUrl, query, uploadImage } from '../../common/NetworkManager';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    container: {
        flexDirection: 'column',
        flex: 1,
    },
    header: {
        alignItems: 'center',
    },
    title: { '&&': {
        margin: 24,
        fontSize: 30,
        fontWeight: 'bold',
    }},
    list: { '&&': {
        borderTop: '1px solid rgba(255, 255, 255, 0.12)',
        padding: 0,
        overflowY: 'auto',
    }},
    dialog: {
        justifyContent: 'center',
    },
    dialogTitle: {
        width: '100vw',
        maxWidth: 450,
        display: 'flex',
        justifyContent: 'space-between',
    },
    dialogImageContainer: {
        backgroundColor: theme.palette.grey[800],
        position: 'relative',
        flexDirection: 'column',
        alignSelf: 'stretch',
        height: 100,
        paddingLeft: 12,
        paddingRight: 8,
        paddingTop: 8,
    },
    dialogImage: {
        margin: 8,
        objectFit: 'contain',
        alignSelf: 'center',
        width: 42,
        height: 42,
    },
    dialogPicker: { '&&': {
        position: 'absolute',
        bottom: 4,
        right: 4,
    }},
    noImage: { '&&': {
        position: 'unset',
    }},
}))

const AdminCategory = ({ data, fetchMenuData }) => {
    const [editData, setEditData] = useState();
    const [sortData, setSortData] = useState();
    const [selected, setSelected] = useState();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const isValid = () => {
        if (!editData.name || editData.name.trim() === '') {
            enqueueSnackbar('카테고리명을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onSave = async() => {
        if (isValid()) {
            setLoading(true);

            await query('saveCate', editData);
            await fetchMenuData();
            
            setEditData();
            enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
        }
        setLoading(false);
    }

    const onDelete = async() => {
        await query('delCate', { id: editData.id });
        await fetchMenuData();
        enqueueSnackbar('삭제가 완료되었습니다.', { variant: 'success' });
        setEditData();
    }

    const onSortSave = async() => {
        setLoading(true);

        await query('saveCateSort', { json: sortData.map((item, idx) => ({ id: item.id, sort: idx })) });
        await fetchMenuData();

        setSortData();
        enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
        setLoading(false);
    }

    const selectedIdx = useMemo(() => sortData && sortData.findIndex(item => item.id === selected), [sortData, selected]);

    const onSortMode = (data) => {
        setSelected(data[0].id);
        setSortData([...data]);
    }

    const onSortUp = () => {
        const selected = sortData.splice(selectedIdx, 1);
        sortData.splice(selectedIdx - 1, 0, selected[0]);
        setSortData([...sortData]);
    }

    const onSortDown = () => {
        const selected = sortData.splice(selectedIdx, 1);
        sortData.splice(selectedIdx + 1, 0, selected[0]);
        setSortData([...sortData]);
    }

    const onImageSelect = async(file) => {
        setLoading(true);

        const key = await uploadImage(file);
        if (key != null) {
            setEditData({ ...editData, image: key });
        }

        setLoading(false);
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.header}>
                <Typography className={classes.title}>카테고리 관리</Typography>
                <div>
                    {!sortData ?
                    <Fragment>
                        <IconButton
                            tooltip='추가'
                            icon={<Add />}
                            onClick={() => setEditData({})}
                        />
                        <IconButton
                            tooltip='정렬'
                            icon={<Sort />}
                            onClick={() => onSortMode(data)}
                            disabled={data.length === 0}
                        />
                    </Fragment> :
                    <Fragment>
                        <IconButton
                            tooltip='위로'
                            icon={<KeyboardArrowUp />}
                            onClick={onSortUp}
                            disabled={selectedIdx === 0}
                        />
                        <IconButton
                            tooltip='뒤로'
                            icon={<KeyboardArrowDown />}
                            onClick={onSortDown}
                            disabled={selectedIdx === sortData.length - 1}
                        />
                        <IconButton
                            tooltip='저장'
                            icon={<Check />}
                            onClick={onSortSave}
                        />
                        <IconButton
                            tooltip='취소'
                            icon={<Clear />}
                            onClick={() => setSortData()}
                        />
                    </Fragment>}
                </div>
            </div>
            <List className={classes.list}>
                {(sortData != null && sortData.includes(data[0]) ? sortData : data).map(item => (
                    <Fragment key={item.id}>
                        <CategoryItem
                            data={item}
                            selection={sortData != null && selected === item.id}
                            selectable={sortData == null || sortData.includes(item)}
                            isSortMode={sortData != null}
                            onClick={() => setSelected(item.id)}
                            onEdit={() => setEditData(item)}
                            onAdd={() => setEditData({ parentId: item.id })}
                            onSort={() => onSortMode(item.children)}
                        />
                        {(sortData != null && sortData.includes(item.children[0]) ? sortData : item.children).map(childItem => (
                            <CategoryItem
                                key={childItem.id}
                                data={childItem}
                                selection={sortData != null && selected === childItem.id}
                                selectable={sortData == null || sortData.includes(childItem)}
                                isSortMode={sortData != null}
                                onClick={() => setSelected(childItem.id)}
                                onEdit={() => setEditData(childItem)}
                            />
                        ))}
                    </Fragment>
                ))}
            </List>
            {editData &&
            <Dialog open className={classes.dialog}>
                <div className={classes.dialogTitle}>
                    <DialogTitle>{editData.id ? '수정' : '추가'}</DialogTitle>
                    <div>
                        {editData.id &&
                        <IconButton
                            tooltip='삭제'
                            icon={<Delete />}
                            onClick={() => setDeleteDialog(true)}
                            style={{ color: 'red' }}
                        />}
                        <IconButton
                            tooltip='취소'
                            icon={<Close />}
                            onClick={() => setEditData()}
                        />
                    </div>
                </div>
                {editData.parentId != null &&
                <TextField
                    className={classes.name}
                    defaultValue={data.find(item => item.id === editData.parentId).name}
                    onChange={evt => setEditData({ ...editData, name: evt.target.value })}
                    label='상위 카테고리'
                    variant="filled"
                    disabled
                />}
                <TextField
                    className={classes.name}
                    defaultValue={editData.name}
                    onChange={evt => setEditData({ ...editData, name: evt.target.value })}
                    label='카테고리명'
                    variant="filled"
                    inputProps={{ maxLength: 20 }}
                />
                {editData.parentId != null &&
                <TextField
                    className={classes.name}
                    defaultValue={editData.description}
                    onChange={evt => setEditData({ ...editData, description: evt.target.value })}
                    label='설명'
                    variant="filled"
                    multiline
                    rows={8}
                    inputProps={{ maxLength: 300 }}
                />}
                {editData.parentId == null &&
                <div className={classes.dialogImageContainer}>
                    <InputLabel shrink>아이콘</InputLabel>
                    {editData.image &&
                    <img alt=' ' className={classes.dialogImage} src={getImageUrl(editData.image)} />}
                    <FilePicker
                        icon={editData.image ? <ImageSearch /> : <AddPhotoAlternate />}
                        className={clsx(classes.dialogPicker, {
                            [classes.noImage]: editData.image == null
                        })}
                        onChange={onImageSelect}
                        accept='image/png'
                    />
                </div>}
                <IconButton
                    tooltip='저장'
                    icon={<Check />}
                    onClick={onSave}
                />
            </Dialog>}
            <QuestionDialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                onConfirm={onDelete}
                variant='error'
                msg='삭제하시겠습니까?'
            />
            <ProgressDialog open={loading} />
        </Paper>
    )
}

const useCategoryItemStyles = makeStyles(theme => ({
    listItem: { '&&': {
        backgroundColor: theme.palette.grey[800],
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        padding: 16,
        paddingLeft: 48,
        paddingRight: 36,
        justifyContent: 'space-between',
    }},
    childItem: { '&&': {
        backgroundColor: 'transparent',
        paddingLeft: 80,
    }},
    leftContainer: {
        alignItems: 'center',
    },
    countContainer: {
        flexDirection: 'column',
        background: 'rgba(144, 202, 249, 0.24)',
        borderRadius: 16,
        padding: 8,
        alignItems: 'center',
        marginRight: 24,
    },
    icon: {
        width: 42,
        height: 42,
        marginRight: 16,
        objectFit: 'contain',
    },
    listUrl: { '&&': {
        color: 'rgba(255, 255, 255, 0.60)'
    }},
    sortMode: { '&&': {
        backgroundColor: 'transparent',
    }},
    selected: { '&&&': {
        background: 'rgba(144, 202, 249, 0.12)',
    }},
    unselectable: {
        filter: 'contrast(0%)',
    },
}))

const CategoryItem = ({ data, selection, onClick, onEdit, onAdd, onSort, isSortMode, selectable }) => {
    const classes = useCategoryItemStyles();

    return (
        <ListItem
            className={clsx(classes.listItem, {
                [classes.childItem]: !onAdd,
                [classes.sortMode]: isSortMode,
                [classes.selected]: selection,
                [classes.unselectable]: !selectable,
            })}
            onClick={evt => {
                evt.target.nodeName !== 'path' && evt.target.nodeName !== 'svg' && !evt.target.className.includes('MuiButtonBase-root') && selectable && onClick()
            }}
        >
            <div className={classes.leftContainer}>
                <div className={classes.countContainer}>
                    <Typography fontSize={16}>항목</Typography>
                    <Typography fontSize={18} fontWeight='bold'>{data.menuCnt}</Typography>
                </div>
                {data.image &&
                <img alt=' ' className={classes.icon} src={getImageUrl(data.image)} />}
                <Typography fontSize={16} fontWeight='bold'>{data.name}</Typography>
            </div>
            {!isSortMode &&
            <div>
                {onSort &&
                <IconButton
                    tooltip='정렬'
                    icon={<Sort />}
                    onClick={onSort}
                    disabled={data.children.length === 0}
                />}
                <IconButton
                    tooltip='수정'
                    icon={<Edit />}
                    onClick={onEdit}
                    style={{ marginLeft: 8 }}
                />
                {onAdd &&
                <IconButton
                    tooltip='추가'
                    icon={<Add />}
                    onClick={onAdd}
                    style={{ marginLeft: 8 }}
                />}
            </div>}
        </ListItem>
    )
}

export default AdminCategory;