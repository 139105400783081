import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { getWeekVisitors } from "../../common/NetworkManager";
import { ProgressPanel } from "../../components";

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
        flexDirection: 'column',
    },
    title: { '&&': {
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
        margin: 24,
    }},
    content: {
        flex: 1,
        height: 0,
    },
}));

const AdminVisitorReport = () => {
    const [data, setData] = useState();
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async() => {
            const result = await getWeekVisitors();
            const labels = Object.keys(result).map(item => item.substring(0, 4) + '-' + item.substring(4, 6) + '-' + item.substring(6));
            const datasets = [
                {
                    type: 'line',
                    data: Object.values(result),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    type: 'bar',
                    data: [],
                },
            ];
            setData({ labels, datasets });
        }
        fetchData();
    }, []);
    
    return (
        <div className={classes.container}>
            <Typography className={classes.title}>방문자 보고서</Typography>
            {!data ? <ProgressPanel /> :
            <div className={classes.content}>
                <Chart
                    type='bar'
                    data={data}
                    options={{
                        responsive: true,
                        layout: {
                            padding: 40,
                        },
                        scales: {
                            x: {
                                ticks: {
                                    font: {
                                        size: 18,
                                    },
                                },
                            },
                            y: {
                                ticks: {
                                    font: {
                                        size: 18,
                                    },
                                },
                            },
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'white',
                                align: 'top',
                                font: {
                                    size: '16px',
                                },
                            },
                        },
                    }}
                />
            </div>}
        </div>
    )
}

export default AdminVisitorReport;