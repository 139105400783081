import { IconButton, Tooltip } from '@mui/material';
import { AttachFile } from "@mui/icons-material";
import React from 'react';

const FilePicker = ({ onChange, className, disabled = false, icon = <AttachFile />, tooltip = '파일 선택', ...props }) => {
    const handleChange = async(e) => {
        const files = [];
        for (let i = 0; i < e.target.files.length; i++) {
            files.push(e.target.files[i]);
        }
        await onChange(props.multiple ? files : files[0]);
    }

    return (
        <Tooltip title={tooltip} className={className}>
            <IconButton
                component="label"
                disabled={disabled}
            >
                {icon}
                <input type='file' value='' style={{ display: 'none' }} onChange={handleChange} {...props} />
            </IconButton>
        </Tooltip>
    )
}

export default FilePicker;