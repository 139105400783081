import { useCallback, useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const RouteChangeTracker = path => {
    const location = useLocation();
    if (!path) {
        path = location.pathname;
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', path, location: path, title: path });
    }, [location, path]);
}

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight, });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

const moveArrayItem = (items, moveFromIndex, moveToIndex) => {
    const movingItem = items[moveFromIndex];
    items.splice(moveFromIndex, 1);
    items.splice(moveToIndex, 0, movingItem);

    return items;
}

const toNumber = (value) => {
    value = (value != null && value.replaceAll) ? value.replaceAll(',', '') : '';
    value = value !== '' ? Number(value) : NaN;
    return isNaN(value) ? null : value;
}

const useFieldInputs = (defValue = {}) => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            const { type, value } = action;
            switch (type) {
                case 'FIELD_UPDATE':
                    return { ...state, [value.field]: value.value };
                case 'UPDATE':
                    return { ...state, ...value };
                case 'INIT':
                    return value || defValue;
                default:
                    return state;
            }
        }, defValue);
    const onChange = useCallback((fieldName, value) => {
        if (typeof(fieldName) === 'object') {
            dispatch(fieldName)
        } else {
            dispatch({
                type : 'FIELD_UPDATE',
                value : {
                    field: fieldName,
                    value: value
                }
            });
        }
    }, []);
    const init = useCallback(() => {
        dispatch({ type : 'INIT' })
    }, []);
    return [state, onChange, init];
}

const numberFormat = (value) => value && parseInt(value).toLocaleString(undefined, { maximumFractionDigits: 4 })

const telFormat = value => value && value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

const weekOfYear = value => {
    return moment(value).isoWeek();
}

const rpadByteOfStr = (text, padLen, padStr) => {
    text = text == null ? '' : text;
    text += '';
    padStr += '';

    let str = text;
    let useLen = 0;
    let usePadLen = 0;
    if (padStr.length > padLen) {
        console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
        return str + "";
    }
    

    for (let i = 0; i < str.length; i++) {
        escape(str.charAt(i)).length >= 4 ? useLen = useLen + 2 : useLen = useLen + 1;
    }
    
    usePadLen = padLen - useLen;
    for (let i = 0; i < usePadLen; i++) {
        str += padStr;
    }

        
    return str;
}

const lpadByteOfStr = (text, padLen, padStr) => {
    text = text == null ? '' : text;
    text += '';
    padStr += '';

    let str = text;
    let useLen = 0;
    let usePadLen = 0;
    if (padStr.length > padLen) {
        console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
        return str + "";
    }
    

    for (let i = 0; i < str.length; i++) {
        escape(str.charAt(i)).length >= 4 ? useLen = useLen + 2 : useLen = useLen + 1;
    }
    
    usePadLen = padLen - useLen;
    for (let i = 0; i < usePadLen; i++) {
        str = padStr + str;
    }

        
    return str;
}

const readBlobAsync = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
        resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
})

const isFieldDiff = (fields, condition, param) => {
    const diff = fields.reduce((result, field) => (
        result || condition[field] !== (param && param[field])
    ), false);
    return fields.reduce((result, field) => (
        result && condition[field] !== undefined)
    , diff);
}


let JSONfn = {};

JSONfn.stringify = function(obj) {
    return JSON.stringify(obj, function(key, value) {
        return (typeof value === 'function' ) ? value.toString() : value;
    });
}

JSONfn.parse = function(str) {
    return JSON.parse(str, function(key, value) {
        if(typeof value != 'string') return value;
        // eslint-disable-next-line
        return (value.substring(0,8) === 'function' || value.includes('=>')) ? eval('('+value+')') : value;
    });
}

export {
    RouteChangeTracker,
    useWindowSize,
    moveArrayItem,
    toNumber,
    useFieldInputs,
    numberFormat,
    telFormat,
    weekOfYear,
    rpadByteOfStr,
    lpadByteOfStr,
    readBlobAsync,
    isFieldDiff,
    JSONfn,
}