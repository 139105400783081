import clsx from 'clsx';
import { Add, Delete, Remove, Save } from "@mui/icons-material";
import { AppBar, Divider, List, ListItem, ListItemText, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useCallback, useEffect, useState } from "react";
import { query } from "../../common/NetworkManager";
import { IconButton, ProgressDialog, ProgressPanel, QuestionDialog } from "../../components";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
    container: {
        flex: 1,
    },
    leftPanel: {
        flexDirection: 'column',
    },
    rightPanel: {
        flexDirection: 'column',
        flex: 1,
    },
    header: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: { '&&': {
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
        margin: 24,
    }},
    list: {
        flexDirection: 'column',
        width: 320,
        overflowY: 'scroll',
        flex: 1,
    },
    listItem: {
        flexDirection: 'column',
        backgroundColor: 'rgba(255, 255, 255, 0.06)',
    },
    selected: {
        background: 'rgba(144, 202, 249, 0.20)',
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    content: {
        flex: 1,
    },
}));

const DEFAULT_DATA = {
    name: '',
    content: [],
}

const DEFAULT_SUB_DATA = {
    name: '',
    price: '0',
}

const AdminTopping = () => {
    const [data, setData] = useState();
    const [selected, setSelected] = useState(JSON.parse(JSON.stringify(DEFAULT_DATA)));
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [warningData, setWarningData] = useState();
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const fetchData = useCallback(async() => {
        setData();
        const data = (await query('getTopping')).map(item => ({
            ...item,
            content: JSON.parse(item.content),
        }));
        if (data.length > 0) {
            setSelected(JSON.parse(JSON.stringify(data[0])));
        }
        setData(data);
    }, []);

    useEffect(() => { fetchData(); }, [fetchData]);

    const itemSelect = (item, forced) => {
        const selectedStr = JSON.stringify(selected);
        const originStr = JSON.stringify(data.find(origin => origin.id === selected.id) || DEFAULT_DATA);
        if (selectedStr === originStr || forced) {
            setSelected(JSON.parse(JSON.stringify(item)));
        } else {
            setWarningData(item);
        }
    }

    const isValid = () => {
        if (selected.name.trim() === '') {
            enqueueSnackbar('이름을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        for (let value of selected.content) {
            if (value.name.trim() === '') {
                enqueueSnackbar('항목명을 입력해 주세요.', { variant: 'error' });
                return false;
            }
            if (value.name.trim() === '' || value.price === '') {
                enqueueSnackbar('가격을 입력해 주세요.', { variant: 'error' });
                return false;
            }
        }
        return true;
    }

    const onSave = async() => {
        if (isValid()) {
            setLoading(true);
    
            const result = await query('saveTopping', selected);
            await fetchData();
    
            const id = selected.id != null ? selected.id : result.insertId;
            setSelected({ ...selected, id });
    
            enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
            setLoading(false);
        }
    }

    const onDelete = async() => {
        await query('delTopping', { id: selected.id });
        await fetchData();
        enqueueSnackbar('삭제가 완료되었습니다.', { variant: 'success' });
        itemSelect(DEFAULT_DATA);
    }

    const onContentChange = (index, value, fieldName) => {
        const result = [...selected.content];
        result[index][fieldName] = value;
        setSelected({ ...selected, content: result });
    }

    const onContentRemove = (index) => {
        const result = [...selected.content];
        result.splice(index, 1);
        setSelected({ ...selected, content: result });
    }

    return (!data ? <ProgressPanel /> :
        <Paper className={classes.container}>
            <div className={classes.leftPanel}>
                <div className={classes.header}>
                    <Typography className={classes.title}>토핑 관리</Typography>
                    <IconButton
                        tooltip='추가'
                        icon={<Add />}
                        onClick={() => itemSelect(DEFAULT_DATA)}
                    />
                </div>
                <List className={classes.list}>
                    {data.map((item, index) => (
                        <ListItem
                            key={item.id}
                            className={clsx(classes.listItem, {
                                [classes.selected]: selected != null && selected.id === item.id
                            })}
                            onClick={() => itemSelect(item)}
                        >
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </div>
            <div className={classes.rightPanel}>
                <AppBar position='static'>
                    <Toolbar className={classes.toolbar} component="h2" variant="h6">
                        <div>
                            {selected.id ?
                            <Typography style={{ color: 'white' }}>
                                {data.find(item => item.id === selected.id).name}
                            </Typography> :
                            <Typography fontSize={18}>새 아이템 추가</Typography>}
                        </div>
                        <div>
                            {selected.id &&
                            <IconButton
                                tooltip='삭제'
                                icon={<Delete style={{ color: 'red' }} />}
                                onClick={() => setDeleteDialog(true)}
                                style={{ marginLeft: 8 }}
                            />}
                            <IconButton
                                tooltip='추가'
                                icon={<Add />}
                                onClick={() => setSelected({ ...selected, content: [...selected.content, { ...DEFAULT_SUB_DATA }] })}
                                style={{ marginLeft: 8 }}
                            />
                            <IconButton
                                tooltip='저장'
                                icon={<Save />}
                                onClick={onSave}
                                style={{ marginLeft: 8 }}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                <List className={classes.content}>
                    <ListItem>
                        <TextField
                            label='이름'
                            value={selected.name}
                            onChange={evt => setSelected({ ...selected, name: evt.currentTarget.value })}
                            variant='standard'
                            inputProps={{ maxLength: 30 }}
                        />
                    </ListItem>
                    <Divider />
                    {selected.content.map((item, index) => (
                        <Fragment key={index}>
                            <ListItem>
                                <TextField
                                    label='항목명'
                                    value={item.name}
                                    onChange={evt => onContentChange(index, evt.currentTarget.value, 'name')}
                                />
                                <TextField
                                    label='가격'
                                    value={item.price}
                                    onChange={evt => onContentChange(index, evt.currentTarget.value.replace(/[^0-9]/g, ''), 'price')}
                                />
                                <IconButton
                                    tooltip='삭제'
                                    icon={<Remove />}
                                    onClick={() => onContentRemove(index)}
                                    style={{ marginLeft: 8 }}
                                />
                            </ListItem>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            </div>
            <QuestionDialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                onConfirm={onDelete}
                variant='error'
                msg='삭제하시겠습니까?'
            />
            <QuestionDialog
                open={warningData != null}
                onClose={() => setWarningData()}
                onConfirm={() => itemSelect(warningData, true)}
                variant='warning'
                msg={`저장되지 않은 내용이 사라집니다.\n계속하시겠습니까?`}
            />
            <ProgressDialog open={loading} />
        </Paper>
    )
}

export default AdminTopping;