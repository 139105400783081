import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Checkbox, createTheme, Dialog, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, TextField, ThemeProvider, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Add, Close, Check, Sort, ChevronLeft, ChevronRight, Clear, Delete, AddPhotoAlternate, ImageSearch } from "@mui/icons-material";
import FilePicker from "../../components/FilePicker";
import { getImageUrl, query, uploadMenuImage } from "../../common/NetworkManager";
import { IconButton, ImageBox, MenuItem as Item, ProgressDialog, ProgressPanel, QuestionDialog } from "../../components";
import themeData from '../../theme.json';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
        flexDirection: 'column',
    },
    header: {
        alignItems: 'center',
    },
    title: { '&&': {
        margin: 24,
        fontSize: 30,
        fontWeight: 'bold',
    }},
    dropdownPaper: {
        display: 'block',
    },
    content: {
        flexWrap: 'wrap',
        overflowY: 'auto',
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: '#FEFEFE',
        flex: 1,
        alignItems: 'start',
    },
    editIcon: { '&&' : {
        color: 'black',
        position: 'absolute',
        top: 24,
        right: 32,
    }},
    dialog: {
        justifyContent: 'center',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dialogContent: {
        flexDirection: 'column',
        width: 400,
    },
    dialogPreview: {
        padding: 16,
        flexDirection: 'column',
        backgroundColor: '#FEFEFE',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogImageContainer: {
        position: 'relative',
        justifyContent: 'center',
        height: 300,
    },
    dialogImage: {
        margin: 8,
    },
    dialogPicker: { '&&': {
        position: 'absolute',
        bottom: 4,
        right: 4,
    }},
    noImage: { '&&': {
        bottom: 48,
        right: 48,
        top: 48,
        left: 48,
    }},
    dialogCheckContainer: {
        justifyContent: 'center',
    },
    selected: {
        filter: 'brightness(85%)',
    },
}));

const normalTheme = createTheme(themeData);

const AdminMenu = ({ menuData, cateData, fetchMenuData }) => {
    const [condition, setCondition] = useState('');
    const [toppingData, setToppingData] = useState();
    const [editData, setEditData] = useState();
    const [sortData, setSortData] = useState();
    const [selected, setSelected] = useState();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async() => {
            const data = await query('getTopping');
            setToppingData(data);
        }
        fetchData();
    }, [])

    useEffect(() => {
        for (let item of cateData) {
            if (item.children.length > 0) {
                setCondition(item.children[0].id);
                break;
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const data = useMemo(() => menuData.filter(item => item.categoryId === condition), [menuData, condition]);

    const isValid = () => {
        if (editData.categoryId == null) {
            enqueueSnackbar('카테고리를 선택해 주세요.', { variant: 'error' });
            return false;
        }
        if (!editData.name || editData.name.trim() === '') {
            enqueueSnackbar('상품명을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        if (!editData.image) {
            enqueueSnackbar('이미지를 선택해 주세요.', { variant: 'error' });
            return false;
        }
        if (!editData.price || editData.price === '') {
            enqueueSnackbar('가격을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        if (!editData.description || editData.description.trim() === '') {
            enqueueSnackbar('설명을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onSave = async() => {
        if (isValid()) {
            setLoading(true);

            if (editData.toppingData === '') {
                editData.toppingData = null;
            }
            await query('saveMenu', editData);
            await fetchMenuData();

            setEditData();
            setCondition(editData.categoryId);
            enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
        }
        setLoading(false);
    }

    const onDelete = async() => {
        await query('delMenu', editData.id);
        await fetchMenuData();
        enqueueSnackbar('삭제가 완료되었습니다.', { variant: 'success' });
        setEditData();
    }

    const onImageSelect = async(file) => {
        setLoading(true);

        const key = await uploadMenuImage(file);
        if (key != null) {
            setEditData({ ...editData, image: key });
        }

        setLoading(false);
    }

    const onSortMode = () => {
        setSelected(data[0].id);
        setSortData([...data]);
    }

    const selectedIdx = useMemo(() => sortData && sortData.findIndex(item => item.id === selected), [sortData, selected]);

    const onSortUp = () => {
        const selected = sortData.splice(selectedIdx, 1);
        sortData.splice(selectedIdx - 1, 0, selected[0]);
        setSortData([...sortData]);
    }

    const onSortDown = () => {
        const selected = sortData.splice(selectedIdx, 1);
        sortData.splice(selectedIdx + 1, 0, selected[0]);
        setSortData([...sortData]);
    }

    const onSortSave = async() => {
        setLoading(true);

        await query('saveMenuSort', { json: sortData.map((item, idx) => ({ id: item.id, sort: idx })) });
        await fetchMenuData();

        setSortData();
        enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
        setLoading(false);
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.header}>
                <Typography className={classes.title}>메뉴 관리</Typography>
                {!sortData ?
                <Fragment>
                    <FormControl>
                        <InputLabel>카테고리</InputLabel>
                        <Select
                            label='카테고리'
                            style={{ minWidth: 160 }}
                            MenuProps={{ classes: { paper: classes.dropdownPaper }}}
                            value={condition}
                            onChange={evt => setCondition(evt.target.value)}
                        >
                            {cateData.map(item => [
                                <Typography style={{ color: 'darkgray' }}>{item.name}</Typography>,
                                item.children.map(childItem => (
                                    <MenuItem value={childItem.id}>{childItem.name}</MenuItem>
                                ))
                            ])}
                        </Select>
                    </FormControl>
                    <IconButton
                        tooltip='추가'
                        icon={<Add />}
                        onClick={() => setEditData({ categoryId: condition })}
                        disabled={condition == null || condition === ''}
                    />
                    <IconButton
                        tooltip='정렬'
                        icon={<Sort />}
                        onClick={onSortMode}
                        disabled={data.length === 0}
                    />
                </Fragment> :
                <Fragment>
                    <IconButton
                        tooltip='앞으로'
                        icon={<ChevronLeft />}
                        onClick={onSortUp}
                        disabled={selectedIdx === 0}
                    />
                    <IconButton
                        tooltip='뒤로'
                        icon={<ChevronRight />}
                        onClick={onSortDown}
                        disabled={selectedIdx === data.length - 1}
                    />
                    <IconButton
                        tooltip='저장'
                        icon={<Check />}
                        onClick={onSortSave}
                    />
                    <IconButton
                        tooltip='취소'
                        icon={<Clear />}
                        onClick={() => setSortData()}
                    />
                </Fragment>}
            </div>
            <div className={classes.content}>
                <ThemeProvider theme={normalTheme}>
                    {(sortData || data).map(item => (
                        <div
                            key={item.id}
                            className={clsx({
                                [classes.selected]: sortData != null && selected === item.id,
                            })}
                            onClick={() => setSelected(item.id)}
                        >
                            <Item data={item} onClick={() => sortData ? setSelected(item.id) : setEditData({ ...item })} />
                        </div>
                    ))}
                </ThemeProvider>
            </div>
            {editData &&
            <Dialog open className={classes.dialog}>
                <div className={classes.dialogTitle}>
                    <DialogTitle>{editData.id ? '수정' : '추가'}</DialogTitle>
                    <div>
                        {editData.id &&
                        <IconButton
                            tooltip='삭제'
                            icon={<Delete />}
                            onClick={() => setDeleteDialog(true)}
                            style={{ color: 'red' }}
                        />}
                        <IconButton
                            tooltip='취소'
                            icon={<Close />}
                            onClick={() => setEditData()}
                        />
                    </div>
                </div>
                <div>
                    <div className={classes.dialogContent}>
                        <FormControl variant="filled">
                            <InputLabel>카테고리</InputLabel>
                            <Select
                                label='카테고리'
                                MenuProps={{ classes: { paper: classes.dropdownPaper }}}
                                value={editData.categoryId}
                                onChange={evt => setEditData({ ...editData, categoryId: evt.target.value })}
                            >
                                {cateData.map(item => [
                                    <Typography style={{ color: 'darkgray' }}>{item.name}</Typography>,
                                    item.children.map(childItem => (
                                        <MenuItem value={childItem.id}>{childItem.name}</MenuItem>
                                    ))
                                ])}
                            </Select>
                        </FormControl>
                        <TextField
                            className={classes.name}
                            defaultValue={editData.name}
                            onChange={evt => setEditData({ ...editData, name: evt.target.value })}
                            label='상품명'
                            variant="filled"
                            inputProps={{ maxLength: 20 }}
                        />
                        <TextField
                            className={classes.name}
                            value={editData.price || ''}
                            onChange={evt => setEditData({ ...editData, price: evt.target.value.replace(/[^0-9]/g, '') })}
                            label='가격'
                            variant="filled"
                            inputProps={{ maxLength: 10 }}
                        />
                        <div className={classes.dialogImageContainer}>
                            {editData.image &&
                            <ImageBox className={classes.dialogImage} src={getImageUrl(editData.image)} />}
                            <FilePicker
                                icon={editData.image ? <ImageSearch /> : <AddPhotoAlternate />}
                                className={clsx(classes.dialogPicker, {
                                    [classes.noImage]: editData.image == null
                                })}
                                onChange={onImageSelect}
                                accept='image/png'
                            />
                        </div>
                        <div className={classes.dialogCheckContainer}>
                            <FormControlLabel
                                label='BEST'
                                control={<Checkbox
                                    checked={!!editData.best}
                                    onChange={evt => setEditData({ ...editData, best: evt.target.checked})}
                                />}
                            />
                            <FormControlLabel
                                label='SEASON'
                                control={<Checkbox
                                    checked={!!editData.season}
                                    onChange={evt => setEditData({ ...editData, season: evt.target.checked})}
                                />}
                            />
                            <FormControlLabel
                                label='HOT'
                                control={<Checkbox
                                    checked={!!editData.hot}
                                    onChange={evt => setEditData({ ...editData, hot: evt.target.checked})}
                                />}
                            />
                            <FormControlLabel
                                label='ICE'
                                control={<Checkbox
                                    checked={!!editData.ice}
                                    onChange={evt => setEditData({ ...editData, ice: evt.target.checked})}
                                />}
                            />
                        </div>
                        {!toppingData ? <ProgressPanel /> :
                        <FormControl variant="filled">
                            <InputLabel>토핑</InputLabel>
                            <Select
                                label='토핑'
                                MenuProps={{ classes: { paper: classes.dropdownPaper }}}
                                value={editData.toppingId || ''}
                                onChange={evt => setEditData({ ...editData, toppingId: evt.target.value })}
                            >
                                <MenuItem value=''>선택 안함</MenuItem>
                                <Divider />
                                {toppingData.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        <TextField
                            className={classes.description}
                            defaultValue={editData.description}
                            onChange={evt => setEditData({ ...editData, description: evt.target.value })}
                            label='설명'
                            variant="filled"
                            multiline
                            rows={5}
                            inputProps={{ maxLength: 200 }}
                        />
                        <IconButton
                            tooltip='저장'
                            icon={<Check />}
                            onClick={onSave}
                        />
                    </div>
                    <div className={classes.dialogPreview}>
                        <Typography color='#191919'>Preview</Typography>
                        <ThemeProvider theme={normalTheme}>
                            <Item data={editData} />
                        </ThemeProvider>
                    </div>
                </div>
            </Dialog>}
            <QuestionDialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                onConfirm={onDelete}
                variant='error'
                msg='삭제하시겠습니까?'
            />
            <ProgressDialog open={loading} />
        </Paper>
    )
}

export default AdminMenu;