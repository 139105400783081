import clsx from 'clsx';
import { Add, Check, Clear, Delete, KeyboardArrowDown, KeyboardArrowUp, Save, Sort } from "@mui/icons-material";
import { AppBar, Divider, List, ListItem, ListItemText, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { query } from "../../common/NetworkManager";
import { IconButton, ProgressDialog, QuestionDialog } from "../../components";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
    container: {
        flex: 1,
    },
    leftPanel: {
        flexDirection: 'column',
        flex: 2,
    },
    rightPanel: {
        flexDirection: 'column',
        flex: 1,
    },
    header: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: { '&&': {
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
        margin: 24,
    }},
    list: {
        flexDirection: 'column',
        overflowY: 'scroll',
        flex: 1,
    },
    listItem: {
    },
    selected: {
        background: 'rgba(144, 202, 249, 0.20)',
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    content: {
        flex: 1,
        flexDirection: 'column',
    },
}));

const DEFAULT_DATA = {
    question: '',
    questionDetail: '',
    answer: '',
}

const AdminFaq = () => {
    const [data, setData] = useState();
    const [selected, setSelected] = useState(DEFAULT_DATA);
    const [sortData, setSortData] = useState();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [warningCallback, setWarningCallback] = useState();
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const sortSelectedIdx = useMemo(() => sortData && sortData.findIndex(item => item.id === selected.id), [sortData, selected]);

    const fetchData = useCallback(async() => {
        setLoading(true);
        setData();
        const data = await query('getFaqAll');
        if (data.length > 0) {
            setSelected(data[0]);
        }
        setData(data);
        setLoading(false);
    }, []);

    useEffect(() => { fetchData(); }, [fetchData]);

    const checkDifference = () => {
        const selectedStr = JSON.stringify(selected);
        const originStr = JSON.stringify(data.find(origin => origin.id === selected.id) || DEFAULT_DATA);
        if (selectedStr === originStr) {
            return true;
        } else {
            return false;
        }
    }

    const itemSelect = (item) => {
        if (checkDifference()) {
            setSelected(JSON.parse(JSON.stringify(item)));
        } else {
            setWarningCallback(() => () => setSelected(JSON.parse(JSON.stringify(item))));
        }
    }

    const isValid = () => {
        if (selected.question.trim() === '') {
            enqueueSnackbar('질문명을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        if (selected.questionDetail.trim() === '') {
            enqueueSnackbar('질문 내용을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        if (selected.answer.trim() === '') {
            enqueueSnackbar('답변을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onSave = async() => {
        if (isValid()) {
            setLoading(true);
    
            const result = await query('saveFaq', selected);
            await fetchData();
    
            const id = selected.id != null ? selected.id : result.insertId;
            setSelected({ id, ...selected });
    
            enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
            setLoading(false);
        }
    }

    const onDelete = async() => {
        await query('delFaq', { id: selected.id });
        await fetchData();
        enqueueSnackbar('삭제가 완료되었습니다.', { variant: 'success' });
        itemSelect(DEFAULT_DATA);
    }

    const onSortSave = async() => {
        setLoading(true);

        await query('saveFaqSort', { json: sortData.map((item, idx) => ({ id: item.id, sort: idx })) });
        await fetchData();

        setSortData();
        enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
        setLoading(false);
    }

    const handleSort = () => {
        if (checkDifference()) {
            onSortMode();
        } else {
            setWarningCallback(() => onSortMode);
        }
    }

    const onSortMode = () => {
        setSelected(data[0]);
        setSortData([...data]);
    }

    const onSortUp = () => {
        const selected = sortData.splice(sortSelectedIdx, 1);
        sortData.splice(sortSelectedIdx - 1, 0, selected[0]);
        setSortData([...sortData]);
    }

    const onSortDown = () => {
        const selected = sortData.splice(sortSelectedIdx, 1);
        sortData.splice(sortSelectedIdx + 1, 0, selected[0]);
        setSortData([...sortData]);
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.leftPanel}>
                <div className={classes.header}>
                    <Typography className={classes.title}>FAQ</Typography>
                    {!sortData ?
                    <div>
                        <IconButton
                            tooltip='추가'
                            icon={<Add />}
                            onClick={() => itemSelect(DEFAULT_DATA)}
                        />
                        <IconButton
                            tooltip='정렬'
                            icon={<Sort />}
                            onClick={handleSort}
                            disabled={data && data.length === 0}
                        />
                    </div> :
                    <div>
                        <IconButton
                            tooltip='위로'
                            icon={<KeyboardArrowUp />}
                            onClick={onSortUp}
                            disabled={sortSelectedIdx === 0}
                        />
                        <IconButton
                            tooltip='뒤로'
                            icon={<KeyboardArrowDown />}
                            onClick={onSortDown}
                            disabled={sortSelectedIdx === sortData.length - 1}
                        />
                        <IconButton
                            tooltip='저장'
                            icon={<Check />}
                            onClick={onSortSave}
                        />
                        <IconButton
                            tooltip='취소'
                            icon={<Clear />}
                            onClick={() => setSortData()}
                        />
                    </div>}
                </div>
                <List className={classes.list}>
                    <Divider />
                    {(sortData || data || []).map((item, index) => (
                        <Fragment key={item.id}>
                            <ListItem
                                className={clsx(classes.listItem, {
                                    [classes.selected]: selected != null && selected.id === item.id
                                })}
                                onClick={() => itemSelect(item)}
                            >
                                <ListItemText primary={item.question} />
                            </ListItem>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            </div>
            <div className={classes.rightPanel}>
                <AppBar position='static'>
                    <Toolbar className={classes.toolbar} component="h2" variant="h6">
                        <div>
                            {sortData == null && (
                            selected.id ?
                            <Typography fontSize={18}>수정</Typography> :
                            <Typography fontSize={18}>추가</Typography>)}
                        </div>
                        <div>
                            {selected.id &&
                            <IconButton
                                tooltip='삭제'
                                icon={<Delete style={{ color: 'red' }} />}
                                onClick={() => setDeleteDialog(true)}
                                style={{ marginLeft: 8 }}
                                disabled={sortData != null}
                            />}
                            <IconButton
                                tooltip='저장'
                                icon={<Save />}
                                onClick={onSave}
                                style={{ marginLeft: 8 }}
                                disabled={sortData != null}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    <TextField
                        label='질문명'
                        value={selected.question}
                        onChange={evt => setSelected({ ...selected, question: evt.currentTarget.value })}
                        variant='filled'
                        inputProps={{ maxLength: 100 }}
                        disabled={sortData != null}
                    />
                    <TextField
                        label='질문 내용'
                        value={selected.questionDetail}
                        onChange={evt => setSelected({ ...selected, questionDetail: evt.currentTarget.value })}
                        multiline
                        rows={5}
                        variant='filled'
                        inputProps={{ maxLength: 100 }}
                        disabled={sortData != null}
                    />
                    <TextField
                        label='답변'
                        value={selected.answer}
                        onChange={evt => setSelected({ ...selected, answer: evt.currentTarget.value })}
                        multiline
                        rows={5}
                        variant='filled'
                        inputProps={{ maxLength: 1000 }}
                        disabled={sortData != null}
                    />
                </div>
            </div>
            <QuestionDialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                onConfirm={onDelete}
                variant='error'
                msg='삭제하시겠습니까?'
            />
            <QuestionDialog
                open={warningCallback != null}
                onClose={() => setWarningCallback()}
                onConfirm={warningCallback}
                variant='warning'
                msg={`저장되지 않은 내용이 사라집니다.\n계속하시겠습니까?`}
            />
            <ProgressDialog open={loading} />
        </Paper>
    )
}

export default AdminFaq;