import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { getImageUrl } from "../common/NetworkManager";
import { numberFormat } from "../common/Utils";
import IconButton from "./IconButton";

const useStyles = makeStyles(theme => ({
    dialog: {
        justifyContent: 'center',
        '& .MuiDialog-container': {
            width: '100%',
        },
    },
    dialogPaper: { '&&': {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 690,
        minHeight: 340,
        padding: 70,
        borderRadius: 13,
        [theme.breakpoints.down('mobile')]: {
            flexDirection: 'column',
            maxWidth: 300,
            padding: 30,
            paddingTop: 55,
            borderRadius: 7,
        },
    }},
    detailImage: {
        position: 'relative',
        width: 240,
        height: 240,
        objectFit: 'contain',
        [theme.breakpoints.down('mobile')]: {
            width: 150,
            height: 150,
        },
    },
    fixedContainer: {
        position: 'absolute',
        alignItems: 'start',
        top: -20,
        left: 0,
        [theme.breakpoints.down('mobile')]: {
            top: -20,
            left: -10,
        },
    },
    best: {
        justifyContent: 'center',
        color: theme.palette.secondary.main,
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: -0.49,
        paddingLeft: 4,
        paddingRight: 4,
        border: '2px solid ' + theme.palette.secondary.main,
        [theme.breakpoints.down('mobile')]: {
            transform: 'scale(0.5)',
        },
    },
    season: {
        justifyContent: 'center',
        color: '#FF8826',
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: -0.49,
        paddingLeft: 4,
        paddingRight: 4,
        border: '2px solid #FF8826',
        [theme.breakpoints.down('mobile')]: {
            transform: 'scale(0.5)',
        },
    },
    hot: {
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: 4,
        right: 12,
        bottom: 0,
        backgroundColor: '#F85C5C',
        [theme.breakpoints.down('mobile')]: {
            width: 4,
            height: 4,
            borderRadius: 2,
            right: 6,
        },
    },
    ice: {
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: 4,
        right: 0,
        bottom: 0,
        backgroundColor: '#5CAAF8',
        [theme.breakpoints.down('mobile')]: {
            width: 4,
            height: 4,
            borderRadius: 2,
        },
    },
    detailContent: {
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        flexDirection: 'column',
        wordBreak: 'keep-all',
        marginLeft: 106,
        [theme.breakpoints.down('mobile')]: {
            flex: 1,
            alignItems: 'center',
            marginLeft: 0,
            width: '100%',
        },
    },
    detailName: {
        fontFamily: 'SCDream',
        fontWeight: 500,
        fontSize: 29,
        letterSpacing: -0.99,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 25,
            fontSize: 17,
            letterSpacing: -0.58,
        },
    },
    detailDesc: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: -0.81,
        lineHeight: '23px',
        marginTop: 18,
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('mobile')]: {
            textAlign: 'center',
            fontSize: 13,
            letterSpacing: -0.45,
            lineHeight: '15px',
            marginTop: 8,
        },
    },
    detailPrice: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: -0.39,
        marginTop: 22,
        [theme.breakpoints.down('mobile')]: {
            alignItems: 'center',
            fontSize: 16,
            letterSpacing: -0.24,
            marginTop: 21,
        },
    },
    detailUnit: {
        alignItems: 'center',
        fontWeight: 300,
        fontSize: 16,
        marginLeft: 9,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            marginLeft: 2,
        },
    },
    detailTopping: {
        flexDirection: 'column',
        fontSize: 15,
        letterSpacing: -0.23,
        marginTop: 34,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.18,
            marginTop: 11,
            alignSelf: 'stretch',
        },
    },
    detailToppingTitle: {
        fontWeight: 500,
        marginBottom: 6,
        [theme.breakpoints.down('mobile')]: {
            marginBottom: 2,
        },
    },
    detailToppingItem: {
        fontSize: 15,
        letterSpacing: -0.23,
        justifyContent: 'space-between',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.18,
        },
    },
    dialogClose: { '&&': {
        position: 'absolute',
        top: 40,
        right: 40,
        [theme.breakpoints.down('mobile')]: {
            top: 30,
            right: 30,
        },
    }},
    dialogCloseImage: {
        width: 25,
        height: 25,
        [theme.breakpoints.down('mobile')]: {
            width: 20,
            height: 20,
        },
    },
}));

const MenuPopup = ({ open, data, onClose }) => {
    const [openState, setOpenState] = useState(false);
    const [storedData, setStoredData] = useState({});
    const classes = useStyles();

    useEffect(() => {
        if (open) {
            setStoredData(data || {});
            setOpenState(true);
        } else {
            setOpenState(false);
        }
    }, [data, open]);

    return (
        <Dialog
            open={openState}
            onClose={onClose}
            className={classes.dialog}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.detailImage}>
                <img alt=' ' className={classes.detailImage} src={getImageUrl(storedData.image + '_240')} />
                <div className={classes.fixedContainer}>
                    {!!storedData.best && <div className={classes.best}>BEST</div>}
                    {!!storedData.season && <div className={classes.season}>SEASON</div>}
                </div>
                {!!storedData.hot && <div className={classes.hot} />}
                {!!storedData.ice && <div className={classes.ice} />}
            </div>
            <div className={classes.detailContent}>
                <div className={classes.detailName}>{storedData.name}</div>
                <div className={classes.detailDesc}>{storedData.description}</div>
                <div className={classes.detailPrice}>
                    {numberFormat(storedData.price)}
                    <div className={classes.detailUnit}>원</div>
                </div>
                {storedData.topping &&
                <div className={classes.detailTopping}>
                    <div className={classes.detailToppingTitle}>토핑추가</div>
                    {storedData.topping.map((item, index) =>
                        <div key={index} className={classes.detailToppingItem}>
                            <div>{item.name}</div>
                            <div>{item.price === 0 ? 'Free' : numberFormat(item.price) + '원'}</div>
                        </div>
                    )}
                </div>}
            </div>
            <IconButton
                className={classes.dialogClose}
                tooltip='닫기'
                icon={<img alt='닫기' className={classes.dialogCloseImage} src={require('../res/icon/btn_close.png')} />}
                onClick={onClose}
            />
        </Dialog>
    );
}

export default MenuPopup;