import clsx from 'clsx';
import { CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const ProgressPanel = ({ className, ...props }) => {
    const classes = useStyles();
    
    return (
        <div className={clsx(classes.container, className)} {...props}>
            <CircularProgress />
        </div>
    )
}

export default ProgressPanel;