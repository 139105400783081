import { Save } from "@mui/icons-material";
import { AppBar, Divider, List, ListItem, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useCallback, useEffect, useState } from "react";
import { query } from "../../common/NetworkManager";
import { IconButton, ProgressDialog } from "../../components";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
    container: {
        flexDirection: 'column',
        flex: 1,
    },
    title: { '&&': {
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
        margin: 24,
    }},
    toolbar: { '&&': {
        margin: 0,
        padding: 0,
    }},
    content: {
        flex: 1,
        overflowY: 'auto',
    },
}));

const AdminEnv = ({ fetchCompanyData }) => {
    const [data, setData] = useState([]);
    const [input, setInput] = useState();
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const fetchData = useCallback(async() => {
        setLoading(true);
        setData([]);

        const data = await query('getEnv');

        setInput({});
        setData(data);
        setLoading(false);
    }, []);

    useEffect(() => { fetchData(); }, [fetchData]);

    const onSave = async() => {
        setLoading(true);

        const json = Object.keys(input).map(id => ({
            id,
            value: input[id],
        }))
        await query('saveEnv', { json });
        await fetchData();
        await fetchCompanyData();

        enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
        setLoading(false);
    }

    return (
        <Paper className={classes.container}>
            <AppBar position='static'>
                <Toolbar className={classes.toolbar} component="h2" variant="h6">
                    <Typography className={classes.title}>환경 변수</Typography>
                    <div>
                        <IconButton
                            tooltip='저장'
                            icon={<Save />}
                            onClick={onSave}
                            style={{ marginLeft: 8 }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <List className={classes.content}>
                {data.map(item => (
                    <Fragment key={item.id}>
                        <ListItem>
                            <TextField
                                label='KEY'
                                value={item.name}
                                disabled
                            />
                            <TextField
                                label='VALUE'
                                defaultValue={item.value}
                                onChange={evt => setInput({ ...input, [item.id]: evt.currentTarget.value })}
                                inputProps={{ maxLength: 100 }}
                                disabled={!item.editable}
                                fullWidth
                            />
                        </ListItem>
                        <Divider />
                    </Fragment>
                ))}
            </List>
            <ProgressDialog open={loading} />
        </Paper>
    )
}

export default AdminEnv;