import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { RouteChangeTracker } from "../common/Utils";
import { PaperStylePage } from '../components';
import { Fade } from 'react-awesome-reveal';

const useStyles = makeStyles(theme => ({
    conatiner: {
        maxWidth: 854,
        marginTop: 100,
        width: '100%',
        [theme.breakpoints.down('mobile')]: {
            width: 'min-content',
            marginTop: 30,
        },
    },
    textOuterContainer: {
        position: 'relative',
        width: '100%',
        marginBottom: 71,
        justifyContent: 'space-between',
        [theme.breakpoints.down('mobile')]: {
            marginBottom: 7,
        },
    },
    textContainer: {
        alignItems: 'center',
    },
    contentText: {
        flexDirection: 'column',
        fontFamily: 'NotoSans',
        fontWeight: 300,
        fontSize: 28,
        letterSpacing: -2.24,
        lineHeight: '30px',
        whiteSpace: 'pre-line',
        marginLeft: 52,
        zIndex: 1,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            lineHeight: '14.5px',
            letterSpacing: -0.96,
            marginLeft: 9,
        },
    },
    contentTextBold: {
        fontWeight: 400,
    },
    textHighlight1: {
        position: 'absolute',
        backgroundColor: '#F46E0F',
        width: 245,
        height: 24,
        top: 91,
        [theme.breakpoints.down('mobile')]: {
            width: 92,
            height: 7,
            top: 20,
        },
    },
    textHighlight2: {
        position: 'absolute',
        backgroundColor: '#F46E0F',
        width: 176,
        height: 24,
        top: 124,
        [theme.breakpoints.down('mobile')]: {
            width: 61,
            height: 7,
            top: 36,
        },
    },
    shoesLogo: {
        width: 418,
        [theme.breakpoints.down('mobile')]: {
            width: 111,
        },
    },
    storyImage: {
        width: '100%',
        marginTop: 11,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 8,
            width: 240,
        },
    },
    slogunContainer: {
        position: 'relative',
        width: '100%',
        marginTop: 47,
        marginBottom: 133,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 15,
            marginBottom: 35,
            height: 100,
        },
    },
    slogunBgText: {
        fontFamily: 'Ssurround',
        fontSize: 178,
        letterSpacing: -6.23,
        textAlign: 'center',
        color: '#929292',
        opacity: 0.05,
        marginTop: 16,
        [theme.breakpoints.down('mobile')]: {
            display: 'none',
        },
    },
    slogunText: {
        position: 'absolute',
        fontFamily: 'SCDream',
        fontSize: 78,
        letterSpacing: -3.35,
        zIndex: 2,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 25,
            letterSpacing: -1.08,
        },
    },
    slogun1: {
        color: theme.palette.text.primary,
        left: 81,
        [theme.breakpoints.down('mobile')]: {
            left: 4,
        },
    },
    slogun2: {
        color: '#858585',
        left: 305,
        top: 93,
        [theme.breakpoints.down('mobile')]: {
            left: 113,
            top: 30,
        },
    },
    slogun3: {
        color: theme.palette.text.primary,
        right: 63,
        top: 193,
        [theme.breakpoints.down('mobile')]: {
            right: 2,
            top: 62,
        },
    },
    underline1: {
        position: 'absolute',
        width: 371,
        height: 36,
        backgroundColor: theme.palette.secondary.light,
        top: 75,
        zIndex: 1,
        [theme.breakpoints.down('mobile')]: {
            width: 113,
            height: 12,
            top: 23,
        }
    },
    underline2: {
        position: 'absolute',
        width: 376,
        height: 83,
        background: '#F4F4F4',
        top: 93,
        left: 204,
        [theme.breakpoints.down('mobile')]: {
            width: 132,
            height: 27,
            top: 31,
            left: 86,
        }
    },
    underline3: {
        position: 'absolute',
        width: 669,
        height: 21,
        backgroundColor: theme.palette.primary.light,
        top: 280,
        right: 0,
        [theme.breakpoints.down('mobile')]: {
            width: 152,
            height: 7,
            top: 89,
        }
    },
}));

const BrandStory = () => {
    RouteChangeTracker();
    const classes = useStyles();

    return (
        <PaperStylePage className={classes.conatiner}>
            <div className={classes.textOuterContainer}>
                <Fade direction='left' duration={1500} fraction={0.66} triggerOnce>
                    <div className={classes.textContainer}>
                        <div className={classes.textHighlight1} />
                        <div className={classes.textHighlight2} />
                        <div className={classes.contentText}>
                            <div>와플스튜디오의 와플은</div>
                            <div>
                                <div className={classes.contentTextBold}>특별한 믹스파우더</div>
                                와
                            </div>
                            <div>
                                <div className={classes.contentTextBold}>수제 생크림</div>
                                으로
                            </div>
                            {`매일 아침 정성을
                            가득 담아 만듭니다`}
                        </div>
                    </div>
                </Fade>
                <Fade direction='right' duration={1800} fraction={0.66} triggerOnce>
                    <img alt=' ' className={classes.shoesLogo} src={require('../res/logo/shoes2.png')} />
                </Fade>
            </div>
            <Fade direction='left' fraction={0.66} triggerOnce>
                <img
                    alt='누구나 좋아하는 기본에 충실한 와플'
                    className={classes.storyImage}
                    src={require('../res/common/story1.jpg')}
                />
            </Fade>
            <Fade direction='right' fraction={0.66} triggerOnce>
                <img
                    alt='와플스튜디오만의 독자적인 와플'
                    className={classes.storyImage}
                    src={require('../res/common/story2.jpg')}
                />
            </Fade>
            <Fade direction='left' fraction={0.66} triggerOnce>
                <img
                    alt='맛까지 앞서가는 트렌디한 와플까지!'
                    className={classes.storyImage}
                    src={require('../res/common/story3.jpg')}
                />
            </Fade>
            <div className={classes.slogunContainer}>
                <div className={classes.slogunBgText}>
                    {`WAFFLE
                    STUDIO`}
                </div>
                <Fade className={clsx(classes.slogunText, classes.slogun1)} direction='left' fraction={0.66} triggerOnce>
                    Choose
                </Fade>
                <div className={clsx(classes.slogunText, classes.slogun2)}>your</div>
                <Fade className={clsx(classes.slogunText, classes.slogun3)} direction='right' fraction={0.66} triggerOnce>
                    preference!
                </Fade>
                <div className={classes.underline1} />
                <div className={classes.underline2} />
                <div className={classes.underline3} />
            </div>
        </PaperStylePage>
    )
}

export default BrandStory;