import { makeStyles } from "@mui/styles";
import { Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getImageUrl, query } from "../../common/NetworkManager";
import { PaperStylePage, QuestionDialog } from "../../components";
import { Fab, Portal } from "@mui/material";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        marginBottom: 100,
        [theme.breakpoints.down('mobile')]: {
            marginBottom: 35,
        }
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 75,
        letterSpacing: -4.13,
        lineHeight: '78px',
        marginTop: 70,
        textAlign: 'center',
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 22,
            letterSpacing: -1.12,
            lineHeight: '22px',
            marginTop: 40,
        },
    },
    table: {
        width: 'calc(100% - 32px)',
        maxWidth: 1110,
        borderSpacing: 0,
        flexDirection: 'column',
        marginTop: 184,
        borderTop: '2px solid #F5841E',
        fontFamily: 'NotoSans',
        color: theme.palette.text.primary,
        [theme.breakpoints.down('mobile')]: {
            maxWidth: 270,
            marginTop: 30,
        },
        '& thead td': {
            borderBottom: '2px solid #F3F3F3',
            padding: 20,
            [theme.breakpoints.down('mobile')]: {
                padding: 15,
            },
        },
        '& thead tr:nth-child(1)': {
            fontSize: 24,
            letterSpacing: -0.17,
            [theme.breakpoints.down('mobile')]: {
                fontSize: 14,
                letterSpacing: -0.1,
            },
        },
        '& thead tr:nth-child(n+2)': {
            fontWeight: 300,
            fontSize: 16,
            letterSpacing: -0.11,
            [theme.breakpoints.down('mobile')]: {
                fontSize: 14,
                letterSpacing: -0.1,
            }
        },
        '& thead td:nth-child(1)': {
            width: '1%',
            whiteSpace: 'nowrap',
        },
        '& tbody td': {
            verticalAlign: 'top',
            fontWeight: 300,
            fontSize: 16,
            letterSpacing: -0.11,
            height: 690,
            padding: 30,
            [theme.breakpoints.down('mobile')]: {
                fontSize: 13,
                letterSpacing: -0.09,
                height: 333,
                padding: 15,
            }
        }
    },
    contentImage: {
        maxWidth: '100%',
    },
    listButton: {
        width: 140,
        height: 63,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 13,
        border: '2px solid #AEAEAE',
        fontFamily: 'NotoSans',
        fontSize: 24,
        letterSpacing: -0.17,
        color: '#A1A1A1',
        [theme.breakpoints.down('mobile')]: {
            width: 193,
            height: 50,
            borderRadius: 6.5,
            fontSize: 15,
            letterSpacing: -0.1,
        },
    },
    moveContainer: {
        width: 'calc(100% - 32px)',
        maxWidth: 1110,
        flexDirection: 'column',
        borderTop: '2px solid #F3F3F3',
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontSize: 16,
        letterSpacing: -0.11,
        marginTop: 50,
        [theme.breakpoints.down('mobile')]: {
            maxWidth: 270,
            fontSize: 14,
            letterSpacing: -0.1,
            marginTop: 40,
        },
    },
    moveButton: {
        cursor: 'pointer',
        alignItems: 'center',
        padding: 22.5,
        borderBottom: '2px solid #F3F3F3',
        [theme.breakpoints.down('mobile')]: {
            padding: 15,
        },
        '& div': {
            marginLeft: 8,
            marginRight: 8,
        }
    },
    moveImage: {
        width: 19,
        height: 19,
    },
}));

const NoticeRead = ({ admin }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = useState();
    const [deleteDialog, setDeleteDialog] = useState(false);

    useEffect(() => {
        const fetchData = async() => {
            const result = await query('getNoticeDetail', { id: params.id, update: admin !== true });
            let data;
            if (result[1]) {
                data = result[1][0];
            } else {
                data = result[0];
            }

            if (!data) {
                navigate('/');
                return;
            }

            setData({ ...data, content: JSON.parse(data.content) });
        }
        fetchData();
    }, [params, navigate, admin]);

    const goBack = () => navigate(`/${admin ? 'admin' : 'community'}/notice/${data.page}`);
    
    const movePost = id => navigate(`/${admin ? 'admin' : 'community'}/notice/read/${id}`);

    const onDelete = async() => {
        await query('delNotice', { id: data.id });
        goBack();
    }

    return (data &&
        <PaperStylePage className={classes.container}>
            <div className={classes.title}>와플스튜디오 공지사항</div>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <td>제목</td>
                        <td>{data.title}</td>
                    </tr>
                    <tr>
                        <td>작성자</td>
                        <td>와플스튜디오</td>
                    </tr>
                    <tr>
                        <td>작성일</td>
                        <td>{data.regDttm}</td>
                    </tr>
                    <tr>
                        <td>조회</td>
                        <td>{data.views}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            {data.content.map((item, index) => (
                                <div key={index}>
                                    {typeof(item) === 'string' ? 
                                    item :
                                    <img alt={'image' + index} className={classes.contentImage} src={getImageUrl(item)} />}
                                </div>
                            ))}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className={classes.listButton} onClick={goBack}>
                목 록
            </div>
            {(data.beforeId != null || data.nextId != null) &&
            <div className={classes.moveContainer}>
                {data.beforeId != null &&
                <div onClick={() => movePost(data.beforeId)} className={classes.moveButton}>
                    <img alt=' ' className={classes.moveImage} src={require('../../res/icon/post_up.png')} />
                    <div>이전글</div>
                    {data.beforeTitle}
                </div>}
                {data.nextId != null &&
                <div onClick={() => movePost(data.nextId)} className={classes.moveButton}>
                    <img alt=' ' className={classes.moveImage} src={require('../../res/icon/post_down.png')} />
                    <div>다음글</div>
                    {data.nextTitle}
                </div>}
            </div>}
            {admin &&
            <Portal>
                <div style={{ position: 'fixed', bottom: 32, right: 32 }}>
                    <Fab
                        color='error'
                        onClick={() => setDeleteDialog(true)}
                        style={{ marginRight: 16, }}
                    >
                        <Delete />
                    </Fab>
                    <Link to='/admin/notice/edit' state={{ data }}>
                        <Fab
                            color='primary'
                        >
                            <Edit />
                        </Fab>
                    </Link>
                </div>
                <QuestionDialog
                    open={deleteDialog}
                    onClose={() => setDeleteDialog(false)}
                    onConfirm={onDelete}
                    variant='error'
                    msg='삭제하시겠습니까?'
                />
            </Portal>}
        </PaperStylePage>
    )
}

export default NoticeRead;