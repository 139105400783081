import clsx from 'clsx';
import { Slider } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { useMemo, useRef } from "react";
import { useWindowSize } from "../common/Utils";

const useStyles = makeStyles(theme => ({
    scroll: {
        '&&&': {
            width: '100%',
            height: 4,
            padding: 0,
            backgroundColor: '#FFFFFF',
            color: '#EEEEEE',
            marginTop: 20,
            marginLeft: 8,
            marginRight: 8,
        },
        '& .MuiSlider-rail': {
            opacity: 1,
        },
        '& .MuiSlider-thumb': {
            height: 4,
            width: '30%',
            borderRadius: 4,
            color: '#C2C2C2',
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
            },
            '&:before': {
                boxShadow: 'none',
            },
        }
    },
}));

const ScrollProgress = ({ value, className, ...props }) => {
    const scrollRef = useRef();
    const windowSize = useWindowSize();
    const classes = useStyles();

    const margin = useMemo(() => {
        if (!scrollRef.current) return 0;

        const width = scrollRef.current.offsetWidth;
        const thumbWidth = width * 0.3;
        return thumbWidth / 2 / (width - thumbWidth);
    }, [windowSize]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Slider
            ref={scrollRef}
            className={clsx(classes.scroll, className)}
            track={false}
            min={0 - (margin || 0)}
            max={1 + (margin || 0)}
            value={value}
            disabled
            {...props}
        />
    )
}

export default ScrollProgress