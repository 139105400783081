import clsx from 'clsx';
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from "@mui/icons-material";
import { Backdrop, Paper, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, forwardRef, useImperativeHandle, useRef, useState } from "react";

const useStyles = makeStyles(theme => ({
    container: { '&&': {
        flexDirection: 'column',
        position: 'fixed',
        width: '100%',
        zIndex: 16,
        borderRadius: 0,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        touchAction: 'none',
        transition: theme.transitions.create(['top']),
    }},
    open: { '&&': {
        top: props => window.innerHeight - props.offsetHeight,
    }},
    close: { '&&': {
        top: props => window.innerHeight - props.titleHeight,
    }},
    arrowContainer: {
        position: 'relative',
        justifyContent: 'center',
        fontSize: 18,
        color: 'darkgray',
    },
    arrowActionArea: {
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 1,
    },
    arrow: {
        position: 'absolute',
        top: 0,
    }
}));

const BottomSheet = forwardRef(({ children, className, style, titleHeight = 60, ...props }, ref) => {
    const [open, setOpen] = useState(false);
    const compRef = useRef();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));

    useImperativeHandle(ref, () => ({
        setOpen,
    }))

    const getOriginY = () => window.innerHeight - (open ? compRef.current.offsetHeight : titleHeight);

    const onTitleClick = e => {
        setOpen(open => !open);
    }

    const onDragStart = e => {
        compRef.current.style.transition = 'unset';
        compRef.current.startY = e.touches[0].clientY;
    }

    const onDrag = e => {
        let change = e.changedTouches[0].pageY - compRef.current.startY;
        let endPoint;
        const maxValue = compRef.current.offsetHeight - titleHeight;
        if (open) {
            endPoint = window.innerHeight - compRef.current.offsetHeight;
            change = change < maxValue ? change : maxValue;
            change = change > 0 ? change : 0;
        } else {
            endPoint = window.innerHeight - titleHeight;
            change = change > -maxValue ? change : -maxValue;
            change = change < 0 ? change : 0;
        }
        compRef.current.style.top = endPoint + change + 'px';
    }

    const onDragEnd = e => {
        compRef.current.style.transition = null;

        const change = e.changedTouches[0].pageY - compRef.current.startY;
        const std = 50;
        if (open && change > std) {
            setOpen(false);
        } else if (!open && change < -std) {
            setOpen(true);
        } else {
            compRef.current.style.top = getOriginY() + 'px';
        }
    }
    
    return (
        <Fragment>
            <Backdrop open={open && isMobile} sx={{ zIndex: 15 }} onClick={() => setOpen(false)} />
            <Paper
                ref={compRef}
                className={clsx(classes.container, className)}
                style={{ top: getOriginY(), ...style }}
                {...props}
            >
                {isMobile &&
                <div className={classes.arrowContainer}>
                    <div
                        className={classes.arrowActionArea}
                        style={{ height: titleHeight }}
                        onTouchStart={onDragStart}
                        onTouchMove={onDrag}
                        onTouchEnd={onDragEnd}
                        onClick={onTitleClick}
                    />
                    {open ?
                    <KeyboardDoubleArrowDown className={classes.arrow} fontSize='inherit' /> :
                    <KeyboardDoubleArrowUp className={classes.arrow} fontSize='inherit' />}
                </div>}
                {children}
            </Paper>
        </Fragment>
    )
})

export default BottomSheet;