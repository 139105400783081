import clsx from 'clsx';
import { Divider, List, ListItem, ListItemButton, Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { query } from "../../common/NetworkManager";
import { PaperStylePage } from "../../components";
import { RouteChangeTracker } from '../../common/Utils';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        marginBottom: 100,
        [theme.breakpoints.down('mobile')]: {
            marginBottom: 37,
        },
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 75,
        letterSpacing: -4.13,
        lineHeight: '78px',
        marginTop: 70,
        textAlign: 'center',
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 22,
            letterSpacing: -1.12,
            lineHeight: '22px',
            marginTop: 40,
        },
    },
    list: { '&&': {
        width: 'calc(100% - 32px)',
        display: 'block',
        maxWidth: 1110,
        whiteSpace: 'nowrap',
        marginTop: 184,
        marginBottom: 16,
        padding: 0,
        borderTop: '2px solid #F5841E',
        [theme.breakpoints.down('mobile')]: {
            maxWidth: 270,
            marginTop: 30,
        },
    }},
    question: { '&&': {
        color: theme.palette.text.primary,
        textAlign: 'left',
        fontFamily: 'NotoSans',
        fontSize: 22,
        letterSpacing: -0.17,
        padding: 0,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 14,
            letterSpacing: -0.1,
        },
    }},
    questionButton: { '&&': {
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: 20,
        [theme.breakpoints.down('mobile')]: {
            padding: 15,
        },
    }},
    answer: { '&&': {
        whiteSpace: 'pre-line',
        flexDirection: 'column',
        alignItems: 'start',
        overflow: 'hidden',
        padding: 0,
        height: 0,
        minHeight: 0,
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontWeight: 300,
        fontSize: 24,
        letterSpacing: -0.17,
        transition: theme.transitions.create(['margin', 'min-height'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('mobile')]: {
            fontSize: 14,
            letterSpacing: -0.1,
        },
    }},
    answerOpen: { '&&': {
        margin: 40,
        width: 'calc(100% - 40px)',
        minHeight: 374,
        [theme.breakpoints.down('mobile')]: {
            margin: 20,
            width: 'calc(100% - 20px)',
            minHeight: 183,
        },
    }},
    answerText: {
        whiteSpace: 'pre-line',
        marginLeft: 20,
        marginTop: 40,
        [theme.breakpoints.down('mobile')]: {
            marginLeft: 10,
            marginTop: 20,
        },
    },
    pagination: {
        '&& .Mui-selected': {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
        },
    },
}));

const Faq = () => {
    RouteChangeTracker();
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = useState();
    const [selection, setSelection] = useState();
    const [page, setPage] = useState(1);
    const [pageCnt, setPageCnt] = useState();

    useEffect(() => {
        const fetchData = async() => {
            const data = await query('getFaq', { page: params.page || 1 });
            setData(data[0]);
            setPage(data[1][0].page);
            setPageCnt(data[1][0].pageCnt);
        }
        fetchData();
    }, [params]);

    const onClick = (index) => {
        if (selection === index) {
            setSelection();
        } else {
            setSelection(index);
        }
    }

    return (
        <PaperStylePage className={classes.container}>
            <div className={classes.title}>와플스튜디오 FAQ</div>
            <List className={classes.list}>
                {data && data.map((item, index) => (
                    <Fragment key={index}>
                        <ListItem className={classes.question}>
                            <ListItemButton className={classes.questionButton} onClick={() => onClick(index)}>
                                {item.question}
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem
                            className={clsx(classes.answer, {
                                [classes.answerOpen]: selection === index
                            })}
                        >
                            <div>{item.questionDetail}</div>
                            <div className={classes.answerText}>{item.answer}</div>
                        </ListItem>
                        <Divider />
                    </Fragment>
                ))}
            </List>
            <Pagination
                className={classes.pagination}
                page={page}
                count={pageCnt}
                shape='circular'
                onChange={(evt, number) => navigate(`/foundation/faq/${number}`)}
                color= 'secondary'
            />
        </PaperStylePage>
    )
}

export default Faq;