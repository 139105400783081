import clsx from 'clsx';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, createTheme, Drawer, List, ListItemButton, ThemeProvider, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconButton, ImageBox } from './components';
import themeData from './theme.json';

const theme = createTheme(themeData);

const App = ({ companyData, cateData }) => (
    <ThemeProvider theme={theme}>
        <Layout companyData={companyData} cateData={cateData} />
    </ThemeProvider>
)

const useStyles = makeStyles(theme => ({
    page: {
        width: '100%',
        flexDirection: 'column',
        flex: 1,
    },
    appbar: {
        position: 'fixed',
        width: '100%',
        top: 0,
        backgroundColor: theme.palette.background.default,
        justifyContent: 'center',
        zIndex: 10,
        height: 100,
        transition: theme.transitions.create(['height', 'box-shadow']),
        [theme.breakpoints.down('tablet')]: {
            height: 70,
            paddingRight: 12,
        },
    },
    appbarHover: {
        height: 400,
    },
    appbarShadow: {
        boxShadow: '2px 2px 2px #00000040',
    },
    appbarContent: {
        width: '100%',
        position: 'relative',
        maxWidth: 1280,
        overflow: 'hidden',
    },
    logoContainer: {
        flex: 1,
        flexDirection: 'column',
        marginTop: 30,
        marginLeft: 13,
        marginBottom: 10,
        [theme.breakpoints.down('tablet')]: {
            marginTop: 10,
            marginLeft: 'calc(50% - 77px)',
            marginBottom: 'unset',
        },
    },
    logo: {
        width: 120,
        justifyContent: 'center',
        [theme.breakpoints.down('tablet')]: {
            width: 'unset',
            height: 50,
        },
    },
    appbarBannerContainer: {
        position: 'relative',
        marginTop: 30,
        maxWidth: 768,
        height: 280,
        backgroundSize: 'cover',
    },
    appbarBannerImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        opacity: 0,
        transition: theme.transitions.create('opacity'),
    },
    appbarBannerImageShow: {
        opacity: 1,
    },
    menuContainer: {
        marginRight: 13,
        marginTop: 33,
        [theme.breakpoints.down('tablet')]: {
            marginRight: 12,
            marginTop: 'unset',
        },
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        width: 100,
        alignItems: 'center',
    },
    menuLink: {
        fontFamily: 'Roboto',
        fontSize: 15,
        padding: 8,
        color: '#999999',
        textDecoration: 'none',
    },
    hoverText: {
        color: '#F37021',
    },
    menuHoverBar: {
        width: 76,
        height: 2,
        backgroundColor: '#F37021',
        opacity: 1,
        transition: theme.transitions.create('opacity'),
    },
    menuSubLink: {
        fontFamily: 'NotoSans',
        fontSize: 15,
        margin: 8,
        color: '#999999',
        textDecoration: 'none',
        '&:hover': {
            color: '#F37021',
        }
    },
    hidden: {
        opacity: 0,
    },
    menuSubList: {
        marginTop: 33,
        flexDirection: 'column',
        alignItems: 'center',
    },
    menuSelected: { '&&': {
        fontWeight: 500,
        color: theme.palette.text.primary,
    }},
    content: {
        flex: 1,
        flexDirection: 'column',
    },
    body: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        justifyContent: 'center',
    },
    bottom: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        paddingTop: 101,
        paddingBottom: 164,
        [theme.breakpoints.down('mobile')]: {
            flexDirection: 'column',
            paddingTop: 50,
            paddingBottom: 52,
            paddingLeft: 20,
        },
    },
    bottomTextBase: {
        flexDirection: 'column',
        fontFamily: 'NotoSans',
        fontWeight: 300,
        color: '#ECECEC',
        whiteSpace: 'pre-wrap',
        wordBreak: 'keep-all',
    },
    bottomTextTitle: {
        flex: 1,
        maxWidth: 575,
        alignItems: 'end',
        whiteSpace: 'nowrap',
        fontSize: 36,
        letterSpacing: -0.72,
        [theme.breakpoints.down('mobile')]: {
            alignItems: 'start',
            fontSize: 18,
            letterSpacing: -0.36,
        },
    },
    bottomLogoImage: {
        height: 38,
        marginTop: 20,
        [theme.breakpoints.down('mobile')]: {
            height: 19,
            marginTop: 10,
        },
    },
    bottomTextNormal: {
        marginLeft: 80,
        fontSize: '24px',
        lineHeight: '31px',
        letterSpacing: '-0.48px',
        [theme.breakpoints.down('mobile')]: {
            marginLeft: 0,
            marginTop: 10,
            fontSize: '12px',
            lineHeight: '15.5px',
            letterSpacing: '-0.24px',
        },
    },
    bottomTextRights: {
        marginTop: 22,
        fontSize: '22px',
        letterSpacing: '-0.44px',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 11,
            fontSize: '11px',
            letterSpacing: '-0.22px',
        },
    },
    drawerPaper: { '&&': {
        backgroundColor: theme.palette.background.default,
        width: 230,
        zIndex: 11,
        paddingLeft: 20,
        paddingRight: 20,
        border: 'none',
    }},
    drawerClose: { '&&': {
        alignSelf: 'end',
        marginTop: 25,
    }},
    drawerLogo: {
        alignSelf: 'center',
        width: 120,
        padding: 50,
    },
    drawerList: { '&&': {
        padding: 0,
        borderTop: '2px solid #F05A27',
        '& div:first-child': {
            border: 'none',
        },
    }},
    fixedContainer: {
        position: 'fixed',
        flexDirection: 'column',
        right: '15%',
        bottom: 100,
        zIndex: 4,
        '& > :last-child': {
            marginTop: 20,
        },
        [theme.breakpoints.down('mobile')]: {
            right: 20,
        },
    },
    fixedButtonContainer: {
        flexDirection: 'column',
        backgroundColor: '#FBFBFB',
        boxShadow: '-2px 3px 5px #00000033',
        overflow: 'hidden',
        transition: theme.transitions.create(['height', 'background-color']),
        height: 100,
        borderRadius: 10,
        '& > :first-child': {
            paddingTop: 0,
            borderTop: 'unset',
        },
        '& > :last-child': {
            paddingBottom: 0,
        },
        [theme.breakpoints.down('mobile')]: {
            height: 50,
            borderRadius: 10,
        },
    },
    fixedButtonOpen: {
        height: 552,
        [theme.breakpoints.down('mobile')]: {
            height: 276,
        },
    },
    fixedButton: {
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginLeft: 4,
        marginRight: 4,
        width: 92,
        height: 100,
        paddingTop: 6,
        paddingBottom: 6,
        borderTop: '1px solid #E9E9E9',
        [theme.breakpoints.down('mobile')]: {
            marginLeft: 2,
            marginRight: 2,
            width: 46,
            height: 50,
            paddingTop: 3,
            paddingBottom: 3,
        },
    },
    plusButton: {
        backgroundColor: '#FF8800',
    },
    fixedButtonImage: {
        width: 42,
        height: 42,
        objectFit: 'contain',
        [theme.breakpoints.down('mobile')]: {
            width: 21,
            height: 21,
        },
    },
}));

const defaultMenu = [
    {
        text: 'Brand story',
        to: '/brandstory/brandstory',
        image: require('./res/common/appbar_brandstory.jpg'),
        children: [
            { text: 'Brand story', to: '/brandstory/brandstory' },
            { text: 'Identity', to: '/brandstory/identity' },
        ]
    },
    {
        text: 'Menu',
        to: '/menu',
        image: require('./res/common/appbar_menu.jpg'),
    },
    {
        text: 'Franchise',
        to: '/foundation/competition',
        image: require('./res/common/appbar_franchise.jpg'),
        imageStyle: {
            backgroundPosition: 'bottom',
        },
        children: [
            { text: '경쟁력', to: '/foundation/competition' },
            { text: '창업절차', to: '/foundation/procedure' },
            { text: '창업비용', to: '/foundation/finance' },
            { text: '창업상담', to: '/foundation/counseling' },
            { text: 'FAQ', to: '/foundation/faq' },
        ],
    },
    {
        text: 'Stores',
        to: '/stores',
        image: require('./res/common/appbar_stores.jpg'),
        children: [
            { text: '매장 찾기', to: '/stores' },
        ],
    },
    {
        text: 'Community',
        to: '/community/notice/1',
        image: require('./res/common/appbar_community.jpg'),
        children: [
            { text: '공지사항', to: '/community/notice/1' },
        ],
    },
]

const Layout = ({ companyData, cateData }) => {
    const [hoverMenu, setHoverMenu] = useState();
    const [appbarBanner, setAppbarBanner] = useState(defaultMenu[0].to);
    const [menuClicked, setMenuClicked] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerSub, setDrawerSub] = useState();
    const [fixedButtonOpen, setFixedButtonOpen] = useState(false);
    const [hasAppbarShadow, setHasAppbarShadow] = useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('tablet'));
    const location = useLocation();
    const classes = useStyles();

    useEffect(() => hoverMenu && setAppbarBanner(defaultMenu.find(item => item.to === hoverMenu).to), [hoverMenu]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setHasAppbarShadow(true);
            } else {
                setHasAppbarShadow(false);
            }
        }
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const menu = useMemo(() => {
        const result = JSON.parse(JSON.stringify(defaultMenu));
        result[1].children = cateData.map(item => ({
            text: item.name,
            to: `/menu/${item.id}`,
        }));
        return result;
    }, [cateData])

    const openDrawer = () => {
        setDrawerSub();
        setDrawerOpen(true);
    }

    const closeDrawer = () => {
        setDrawerSub();
        setDrawerOpen(false);
    }

    return (
        <div className={classes.page}>
            <div className={classes.appbar} style={{ position: 'unset', width: 0 }}/>
            <div className={clsx(classes.appbar, { [classes.appbarShadow]: hasAppbarShadow })} style={{ zIndex: 5 }} />
            <div className={clsx(classes.appbar, {
                [classes.appbarHover]: !isTablet && hoverMenu != null,
                [classes.appbarShadow]: !isTablet && hoverMenu != null,
            })}>
                <div className={classes.appbarContent}>
                    <div className={classes.logoContainer}>
                        <Link to='/' style={{ width: 'fit-content' }}>
                            <img alt='Home' src={require('./res/logo/main.png')} className={classes.logo} />
                        </Link>
                        <div className={classes.appbarBannerContainer}>
                            {defaultMenu.map(item => (
                                <ImageBox
                                    key={item.to}
                                    className={clsx(classes.appbarBannerImage, {
                                        [classes.appbarBannerImageShow]: appbarBanner === item.to,
                                    })}
                                    style={{ ...item.imageStyle }}
                                    src={item.image}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={classes.menuContainer}>
                        {!isTablet ? menu.map(item => (
                            <div
                                key={item.to}
                                className={classes.menuItem}
                                onMouseEnter={() => !menuClicked && setHoverMenu(item.to)}
                                onMouseLeave={() => { setHoverMenu(); setMenuClicked(false); }}
                            >
                                <Link
                                    to={item.to}
                                    onClick={() => { setMenuClicked(true); setHoverMenu(); }}
                                    className={clsx(classes.menuLink, {
                                        [classes.menuSelected]: location.pathname.includes(item.to),
                                        [classes.hoverText]: hoverMenu === item.to,
                                    })}
                                >
                                    {item.text}
                                </Link>
                                <div className={classes.menuSubList}>
                                    <div className={clsx(classes.menuHoverBar, {
                                        [classes.hidden]: hoverMenu !== item.to,
                                    })} />
                                    {item.children && item.children.map(child => (
                                        <Link
                                            key={child.to}
                                            to={child.to}
                                            onClick={() => { setMenuClicked(true); setHoverMenu(); }}
                                            className={clsx(classes.menuSubLink, {
                                                [classes.menuSelected]: location.pathname.includes(child.to),
                                            })}
                                        >
                                            {child.text}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )) :
                        <IconButton
                            icon={<img alt='Menu' style={{ width: 22 }} src={require('../src/res/icon/btn_menu.png')} />}
                            onClick={openDrawer}
                            disableTooltip
                        />}
                    </div>
                </div>
            </div>
            <div className={classes.content}>
                {isTablet &&
                <Drawer
                    anchor='right'
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    PaperProps={{ className: classes.drawerPaper }}
                >
                    <IconButton
                        className={classes.drawerClose}
                        icon={<img alt='닫기' style={{ width: 20 }} src={require('../src/res/icon/btn_close.png')} />}
                        onClick={closeDrawer}
                        disableTooltip
                    />
                    <Link to='/' onClick={closeDrawer}>
                        <img alt='Home' className={classes.drawerLogo} src={require('./res/logo/shoes.png')} />
                    </Link>
                    <List className={classes.drawerList}>
                        {menu.map(item => (
                            <DrawerItem
                                key={item.to}
                                text={item.text}
                                to={item.to}
                                open={drawerSub === item.to}
                                closeDrawer={closeDrawer}
                                setDrawerSub={setDrawerSub}
                            >
                                {item.children && item.children.map(child => (
                                    <DrawerItem
                                        key={child.to}
                                        text={child.text}
                                        to={child.to}
                                        closeDrawer={closeDrawer}
                                        setDrawerSub={setDrawerSub}
                                        isChild
                                    />
                                ))}
                            </DrawerItem>
                        ))}
                    </List>
                </Drawer>}
                <div className={classes.body}>
                    <Outlet />
                </div>
                {(!location.pathname.includes('stores') || !isMobile) &&
                <div className={classes.bottom}>
                    <div className={clsx(classes.bottomTextBase, classes.bottomTextTitle)}>
                        {companyData.companyName}
                        <img alt=' ' className={classes.bottomLogoImage} src={require('./res/logo/hhlogo.png')} />
                    </div>
                    <div className={clsx(classes.bottomTextBase, classes.bottomTextNormal)}>
                        {`${companyData.companyNameFooter}`}<br />
                        {`사업자등록번호 : ${companyData.companyRegNumber}`}<br />
                        {`주소 : ${companyData.companyAddress}`}<br />
                        {`대표이사 : ${companyData.companyOwner}`}<br />
                        {`TEL : ${companyData.companyTel}`}<br />
                        {`개인정보 책임자 : ${companyData.companyPrivacyManager}`}<br />
                        <div className={clsx(classes.bottomTextBase, classes.bottomTextRights)}>
                            ⓒ 2022 {companyData.companyName} Company. All Rights Reserved.
                        </div>
                    </div>
                </div>}
            </div>
            <div className={classes.fixedContainer}>
                {(!location.pathname.includes('stores') || isMobile) &&
                <div className={clsx(classes.fixedButtonContainer, {
                    [classes.plusButton]: !fixedButtonOpen,
                    [classes.fixedButtonOpen]: fixedButtonOpen,
                })}>
                    {fixedButtonOpen ?
                    <Fragment>
                        <div
                            className={classes.fixedButton}
                            onClick={() => window.open(`http://ig.me/m/${companyData.companyInstaId}`)}
                        >
                            <img
                                alt='인스타그램'
                                className={classes.fixedButtonImage}
                                src={require('./res/icon/dm.png')}
                            />
                        </div>
                        <div
                            className={classes.fixedButton}
                            onClick={() => window.open(`https://open.kakao.com/o/s1dZIpQe`)}
                        >
                            <img
                                alt='카카오톡'
                                className={classes.fixedButtonImage}
                                src={require('./res/icon/talk.png')}
                            />
                        </div>
                        <div
                            className={classes.fixedButton}
                            onClick={() => window.location.href = `sms:${companyData.companyTel}`}
                        >
                            <img
                                alt='문자'
                                className={classes.fixedButtonImage}
                                src={require('./res/icon/mail.png')}
                            />
                        </div>
                        <div
                            className={classes.fixedButton}
                            onClick={() => window.location.href = `tel:${companyData.companyTel}`}
                        >
                            <img
                                alt='전화'
                                className={classes.fixedButtonImage}
                                src={require('./res/icon/call.png')}
                            />
                        </div>
                        <div
                            className={classes.fixedButton}
                            onClick={() => setFixedButtonOpen(false)}
                        >
                            <img
                                alt='닫기'
                                className={classes.fixedButtonImage}
                                src={require('./res/icon/minus.png')}
                            />
                        </div>
                    </Fragment> :
                    <div
                        className={classes.fixedButton}
                        onClick={() => setFixedButtonOpen(true)}
                    >
                        <img alt='열기' className={classes.fixedButtonImage} src={require('./res/icon/plus.png')} />
                    </div>}
                </div>}
                {!location.pathname.includes('stores') &&
                <div className={classes.fixedButtonContainer}>
                    <div
                        className={classes.fixedButton}
                        onClick={() => window.scrollTo(0, 0)}
                    >
                        <img alt='상단으로' className={classes.fixedButtonImage} src={require('./res/icon/top.png')} />
                    </div>
                </div>}
            </div>
        </div>
    );
}

const useDrawerItemStyle = makeStyles(theme => ({
    item: { '&&': {
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontSize: '15px',
        letterSpacing: '-0.52px',
        padding: 0,
        paddingTop: 14,
        paddingBottom: 14,
        justifyContent: 'space-between',
        borderTop: '2px solid #F2F2F2',
    }},
    childItem: { '&&': {
        color: '#888',
        paddingLeft: 20,
        backgroundColor: '#F4F4F4',
        borderTop: '1px solid #D9D9D9',
    }},
    bold: { '&&': {
        fontWeight: 500,
    }},
    innerList: {
        width: '100%',
    },
}));

const DrawerItem = ({ children, text, to, isChild, open, setDrawerSub, closeDrawer }) => {
    const classes = useDrawerItemStyle();
    const navigate = useNavigate();

    const onClick = () => {
        if (!children) {
            closeDrawer();
            navigate(to);
        } else {
            setDrawerSub(open ? undefined : to);
        }
    }

    return (
        <Fragment>
            <ListItemButton
                className={clsx(classes.item, {
                    [classes.childItem]: isChild,
                    [classes.bold]: to.includes('/foundation') && !isChild,
                })}
                onClick={onClick}
            >
                {text}
                {children &&
                (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {children &&
            <Collapse in={open} unmountOnExit style={{ display: 'block' }}>
                <List disablePadding className={classes.innerList}>
                    {children}
                </List>
            </Collapse>}
        </Fragment>
    )
}

export default App;
