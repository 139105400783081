import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { getImageUrl, query } from "../common/NetworkManager";
import { Button, Dialog, useMediaQuery } from "@mui/material";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { AccessTime, Phone, Place } from "@mui/icons-material";
import { BottomSheet } from "../components";
import { telFormat } from "../common/Utils";
import Slider from "react-slick";

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        flex: 1,
        flexDirection: 'column',
        height: 800,
        maxHeight: 'calc(100vh - 70px)',
        [theme.breakpoints.down('mobile')]: {
            height: 'unset',
        },
    },
    map: {
        flex: 1,
    },
    paper: { '&&': {
        backgroundColor: '#FEFEFE',
        position: 'absolute',
        right: 30,
        top: 30,
        bottom: 30,
        flexDirection: 'column',
        width: 300,
        zIndex: 1,
        boxShadow: '2px 2px 2px #00000029',
        borderRadius: 13,
        [theme.breakpoints.down('mobile')]: {
            position: 'unset',
            width: '100vw',
        },
    }},
    paperTitle: {
        fontFamily: 'NotoSans',
        fontSize: 18,
        margin: 18,
    },
    list: {
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        height: 0,
        gap: 16,
        padding: 16,
        paddingTop: 32,
        [theme.breakpoints.down('mobile')]: {
            maxHeight: 'calc(100vh - 250px)',
        },
    },
    listItem: {
        flexDirection: 'column',
        marginBottom: 0,
        justifyContent: 'center',
        boxShadow: '2px 2px 2px #00000029',
        borderRadius: 13,
        cursor: 'pointer',
        paddingBottom: 8,
        width: '100%',
        maxWidth: 360,
    },
    listThumbBox: {
        position: 'relative',
        height: 120,
        marginBottom: 8,
    },
    listThumbImg: {
        flex: 1,
        width: 0,
        objectFit: 'cover',
        borderTopLeftRadius: 13,
        borderTopRightRadius: 13,
    },
    listThumbTitle: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        left: 0,
        right: 0,
        bottom: 0,
        fontFamily: 'SCDream',
        color: '#FEFEFE',
        fontWeight: 500,
        fontSize: 18,
        padding: 2,
        paddingLeft: 16,
    },
    listItemFieldBox: {
        fontFamily: 'NotoSans',
        padding: 2,
        paddingLeft: 8,
        paddingRight: 8,
    },
    listItemIcon: {
        color: 'darkgray',
        marginRight: 8,
    },
}));

const Stores = () => {
    const [data, setData] = useState();
    const [dialog, setDialog] = useState();
    const mapRef = useRef();
    const bottomSheetRef = useRef();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));

    useEffect(() => {
        const fetchData = async() => {
            const data = await query('getStores');
            setData(data.map(item => ({
                ...item,
                images: JSON.parse(item.images),
            })));
        }
        fetchData();
    }, [])

    const onMarkerClick = item => {
        setDialog(item);
    }

    const onListItemClick = item => {
        const map = mapRef.current;
        const mapCenter = map.getCenter();
        const isCenter = Math.floor(mapCenter.getLat() * 1000) === Math.floor(item.latitude * 1000) &&
            Math.floor(mapCenter.getLng() * 1000) === Math.floor(item.longitude * 1000);
        
        if (!isCenter || isMobile){
            map.setCenter(new window.kakao.maps.LatLng(item.latitude, item.longitude));
            map.setLevel(4);
        }
        if (isCenter || isMobile) {
            onMarkerClick(item);
        }
        bottomSheetRef.current.setOpen(false);
    }

    return (
        <div className={classes.container}>
            <Map
                ref={mapRef}
                className={classes.map}
                center={{ lat: 35.01, lng: 128.41 }}
                level={isMobile ? 11 : 10}
            >
                {data && data.map(item => (
                    <MapMarker
                        key={item.id}
                        position={{ lat: item.latitude, lng: item.longitude }}
                        clickable
                        onClick={() => onMarkerClick(item)}
                        image={{
                            src: require('../res/icon/marker.png'),
                            size: { width: 46, height: 64 },
                        }}
                    />
                ))}
            </Map>
            {data &&
            <BottomSheet
                ref={bottomSheetRef}
                className={{ [classes.paper]: !isMobile }}
                style={isMobile ? null : { top: null }}
            >
                <div className={classes.paperTitle}>
                    매장 찾기
                </div>
                <img alt=' ' src={require('../res/ui/mask_orange.png')} />
                <div style={{ flex: 1, height: 0, flexDirection: 'column', overflowY: 'auto' }}>
                    <div className={classes.list}>
                    {data.map(item => (
                        <div key={item.id} className={classes.listItem} onClick={() => onListItemClick(item)}>
                            <div className={classes.listThumbBox}>
                                <img alt=' ' className={classes.listThumbImg} src={getImageUrl(item.images[0] + '_store')} />
                                <div className={classes.listThumbTitle}>{item.name}</div>
                            </div>
                            <div className={classes.listItemFieldBox}>
                                <Place className={classes.listItemIcon} />
                                <div>{item.address}</div>
                            </div>
                            <div className={classes.listItemFieldBox}>
                                <Phone className={classes.listItemIcon} />
                                <div>{telFormat(item.tel)}</div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </BottomSheet>}
            <StoreDialog
                open={dialog != null}
                onClose={() => setDialog()}
                data={dialog}
            />
        </div>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialog: {'&&': {
        justifyContent: 'center',
    }},
    paper: {'&&': {
        alignItems: 'center',
        width: 500,
        minHeight: 300,
        borderRadius: 13,
        overflow: 'hidden',
        paddingTop: 70,
        [theme.breakpoints.down('mobile')]: {
            width: 300,
            paddingTop: 50,
            borderRadius: 7,
        },
    }},
    slider: {
        width: '100%',
        '& .slick-track': {
            width: 'max-content !important',
        },
        '& .slick-slide': {
            [theme.breakpoints.up('mobile')]: {
                transition: theme.transitions.create('filter'),
                filter: 'brightness(60%)',
            }
        },
        '& .slick-current': {
            filter: 'brightness(100%) !important',
        },
    },
    detailImage: {
        position: 'relative',
        width: 360,
        objectFit: 'contain',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 16,
        },
    },
    fixedContainer: {
        position: 'absolute',
        alignItems: 'start',
        top: -20,
        left: 0,
        [theme.breakpoints.down('mobile')]: {
            top: -20,
            left: -10,
        },
    },
    detailContent: {
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        flexDirection: 'column',
        wordBreak: 'keep-all',
        alignItems: 'center',
        flex: 1,
        paddingLeft: 20,
        paddingRight: 20,
        width: '100%',
        boxSizing: 'border-box',
        [theme.breakpoints.down('mobile')]: {
        },
    },
    detailName: {
        marginTop: 25,
        fontFamily: 'SCDream',
        fontWeight: 500,
        fontSize: 29,
        letterSpacing: -0.99,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 25,
            fontSize: 17,
            letterSpacing: -0.58,
        },
    },
    detailAddress: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: -0.81,
        lineHeight: '23px',
        marginTop: 18,
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('mobile')]: {
            textAlign: 'center',
            fontSize: 13,
            letterSpacing: -0.45,
            lineHeight: '15px',
            marginTop: 8,
        },
    },
    detailTel: {
        alignItems: 'center',
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: -0.39,
        marginTop: 22,
        cursor: 'pointer',
        [theme.breakpoints.down('mobile')]: {
            alignSelf: 'stretch',
            fontSize: 16,
            letterSpacing: -0.24,
            marginTop: 21,
        },
    },
    detailTime: {
        marginTop: 16,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 11,
            alignSelf: 'stretch',
        }
    },
    detailTimeText: {
        flexDirection: 'column',
        fontWeight: 500,
        fontSize: 15,
        letterSpacing: -0.23,
        gap: 6,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.18,
            gap: 2,
        },
    },
    icon: { '&&': {
        color: 'darkgray',
        marginRight: 8,
        fontSize: 24,
    }},
}));

const StoreDialog = ({ data, open, onClose }) => {
    const [storedData, setStoredData] = useState();
    const classes = useDialogStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
    const paperRef = useRef();

    useEffect(() => {
        data && setStoredData({ ...data });
    }, [data])

    return (
        <Dialog
            className={classes.dialog}
            PaperProps={{ ref: paperRef, className: classes.paper }}
            open={open}
            onClose={onClose}
        >
            {storedData?.images.length === 1 ?
            <img alt=' ' className={classes.detailImage} src={getImageUrl(storedData?.images[0] + '_store')} style={{ width: paperRef.current?.clientWidth * 0.8 - 20 }} /> :
            <Slider
                className={classes.slider}
                slidesToShow={1}
                variableWidth
                centerMode
                infinite
                autoplay
                autoplaySpeed={5000}
                swipeToSlide
                pauseOnHover={false}
            >
                {storedData?.images.map((item, index) => (
                    <div key={index} style={{ width: paperRef.current?.clientWidth * 0.8 }}>
                        <img alt=' ' className={classes.detailImage} src={getImageUrl(item + '_store')} style={{ width: paperRef.current?.clientWidth * 0.8 - 20, marginLeft: 10 }} />
                    </div>
                ))}
            </Slider>}
            <div className={classes.detailContent}>
                <div className={classes.detailName}>{storedData?.name}</div>
                <div className={classes.detailAddress}>
                    {!isMobile &&
                    <Place className={classes.icon} fontSize='inherit' />}
                    {storedData?.address}
                </div>
                <div className={classes.detailTel} onClick={() => window.location.href = `tel:${storedData?.tel}`}>
                    <Phone className={classes.icon} fontSize='inherit' />
                    {telFormat(storedData?.tel)}
                </div>
                <div className={classes.detailTime}>
                    <AccessTime className={classes.icon} fontSize='inherit' />
                    <div className={classes.detailTimeText}>
                        <div>{storedData?.openDay}</div>
                        <div>
                            {storedData?.openTime} ~ {storedData?.closeTime}
                        </div>
                    </div>
                </div>
            </div>
            <Button
                onClick={onClose}
                variant="contained"
                style={{ alignSelf: 'stretch', backgroundColor: 'gray', marginTop: 24, borderRadius: 0 }}
                color='primary'
            >
                닫기
            </Button>
        </Dialog>
    )
}

export default Stores;