import { alpha, FormControl, FormHelperText, InputBase, InputLabel } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    component: {
        '&&': {
            color: theme.palette.text.primary,
        },
        'label + &': {
            marginTop: 42,
            [theme.breakpoints.down('mobile')]: {
                marginTop: 24,
            },
        },
        '& .MuiInputBase-input': {
            borderRadius: 13,
            position: 'relative',
            backgroundColor: '#F3F3F3',
            fontFamily: 'NotoSans',
            fontSize: 16,
            letterSpacing: -0.11,
            lineHeight: '24px',
            padding: '30px 15px',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            [theme.breakpoints.down('mobile')]: {
                padding: '15px 7.5px',
                borderRadius: 7,
            },
            '&:focus': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.secondary.main,
            },
        },
    },
    label: {
        '&&': {
            color: theme.palette.text.primary,
            fontFamily: 'NotoSans',
            fontSize: 24,
            letterSpacing: -1.68,
            [theme.breakpoints.down('mobile')]: {
                fontSize: 16,
                letterSpacing: -1.12,
            },
        },
    },
}));

const TextField = ({ label, onChange, maxLength, value, errorText, className, InputProps, inputProps, ...props }) => {
    const classes = useStyles();
    
    return (
        <FormControl variant='standard' className={className} error={errorText != null} {...props}>
            <InputLabel shrink className={classes.label}>
                {label}
            </InputLabel>
            <InputBase
                className={classes.component}
                inputProps={{ maxLength, ...inputProps }}
                value={value}
                onChange={evt => onChange(evt.currentTarget.value)}
                {...InputProps}
            />
            {errorText != null &&
            <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    )
}

export default TextField;