import { CircularProgress, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    dialog: {
        justifyContent: 'center',
    },
    dialogPaper : { '&&': {
        backgroundColor: 'transparent',
        overflow: 'hidden',
        boxShadow: 'none',
        backgroundImage: 'none',
    }},
}))

const ProgressDialog = ({ open }) => {
    const classes = useStyles();
    return (
        <Dialog
            className={classes.dialog}
            PaperProps={{ className: classes.dialogPaper }}
            open={open}
        >
            <CircularProgress />
        </Dialog>
    )
}

export default ProgressDialog;