import { makeStyles } from "@mui/styles";
import { Fade } from "react-awesome-reveal";
import { RouteChangeTracker } from "../common/Utils";
import { PaperStylePage } from "../components";

const useStyles = makeStyles(theme => ({
    shoesLogo: {
        width: 418,
        height: 228,
        marginTop: 120,
        [theme.breakpoints.down('mobile')]: {
            width: 176,
            height: 96,
            marginTop: 59,
        },
    },
    textTitle: {
        fontFamily: 'SCDream',
        fontSize: 52,
        marginTop: 107,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 16,
            marginTop: 40,
        },
    },
    textSubTitle: {
        fontFamily: 'NotoSans',
        fontSize: 38,
        marginTop: 45,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            marginTop: 23,
            whiteSpace: 'pre-line',
        },
    },
    textContent: {
        flexDirection: 'column',
        fontFamily: 'NotoSans',
        fontWeight: 300,
        fontSize: 24,
        letterSpacing: -1.32,
        lineHeight: '32px',
        marginTop: 53,
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.84,
            lineHeight: '15px',
            marginTop: 12,
            whiteSpace: 'pre-line',
        },
    },
    textContentBold: {
        position: 'relative',
        fontWeight: 500,
    },
    underline: {
        position: 'absolute',
        width: 'calc(100% + 18px)',
        height: 14,
        backgroundColor: '#F46E0F',
        top: 19,
        right: 0,
        opacity: 0.65,
        zIndex: -1,
        [theme.breakpoints.down('mobile')]: {
            width: 'calc(100% + 12px)',
            height: 7,
            top: 8,
        },
    },
    identityImage: {
        width: 484,
        marginTop: 108,
        marginBottom: 133,
        [theme.breakpoints.down('mobile')]: {
            width: 242,
            marginTop: 42,
            marginBottom: 35,
        },
    },
}));

const Identity = () => {
    RouteChangeTracker();
    const classes = useStyles();

    return (
        <PaperStylePage>
            <Fade fraction={0.66} triggerOnce>
                <img alt=' ' className={classes.shoesLogo} src={require('../res/logo/shoes.png')} />
            </Fade>
            <Fade direction='up' fraction={0.66} triggerOnce>
                <div className={classes.textTitle}>로고 그 이상의 가치</div>
            </Fade>
            <Fade direction='up' fraction={0.66} triggerOnce>
                <div className={classes.textSubTitle}>
                    {`전세계 가장 맛있는 와플이
                    한 곳에 모인 와플스튜디오.`}
                </div>
            </Fade>
            <div className={classes.textContent}>
                <div>
                    {`와플스튜디오는 '와플' 시장을
                    이끌어갈 트렌드 리더로서`}
                </div>
                <div>
                    <div className={classes.textContentBold}>
                        <div className={classes.underline} />
                        choose to your preference
                    </div>
                    라는 컨셉 아래,
                </div>
                <div>세계 각국의 다양한 와플을 한곳에 담아내기 위한</div>
                <div>
                    <div className={classes.textContentBold}>
                        <div className={classes.underline} />
                        끊임없는 연구 & 개발
                    </div>
                    을 거듭해 가고 있습니다.
                </div>
                <div>
                    {`단순 '와플 전문점' 이 아닌
                    그이상의 가치를 담고 있습니다.`}
                </div>
            </div>
            <img alt=' ' className={classes.identityImage} src={require('../res/common/identity.png')} />
        </PaperStylePage>
    )
}

export default Identity;