import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        width: '100%',
        alignItems: 'start',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
    },
    banner: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    reveal: {
        width: '100%',
        justifyContent: 'center',
    },
    contentContainer: {
        width: '100%',
        maxWidth: 1280,
        zIndex: 1,
        backgroundColor: theme.palette.background.default,
        boxShadow: '4px 5px 20px #00000029',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 13,
        marginTop: 210,
        marginBottom: 300,
        opacity: '1 !important',
        overflow: 'hidden',
        [theme.breakpoints.down('mobile')]: {
            maxWidth: 320,
            borderRadius: 7,
            marginTop: 60,
            marginBottom: 75,
        }
    },
    titleLogo: {
        marginTop: 204,
        width: 320,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 60,
            width: 140,
        },
    },
    subtitleLogo: {
        marginTop: 15,
        width: 200,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 5,
            width: 60,
        },
    },
    mask: {
        width: 148,
        marginTop: 50,
        [theme.breakpoints.down('mobile')]: {
            width: 50,
            marginTop: 20,
        },
    },
    shoesLogo: {
        width: 418,
        marginTop: 120,
        [theme.breakpoints.down('mobile')]: {
            width: 176,
            marginTop: 59,
        },
    },
    textTitle: {
        fontFamily: 'SCDream',
        fontSize: 52,
        marginTop: 107,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 16,
            marginTop: 40,
        },
    },
    textSubTitle: {
        fontFamily: 'NotoSans',
        fontSize: 38,
        marginTop: 45,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            marginTop: 23,
            whiteSpace: 'pre-line',
        },
    },
    textContent: {
        flexDirection: 'column',
        fontFamily: 'NotoSans',
        fontWeight: 300,
        fontSize: 24,
        letterSpacing: -1.32,
        lineHeight: '28px',
        marginTop: 53,
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.84,
            lineHeight: '15px',
            marginTop: 12,
            whiteSpace: 'pre-line',
        },
    },
    textContentBold: {
        position: 'relative',
        fontWeight: 500,
    },
    underline: {
        position: 'absolute',
        width: 'calc(100% + 18px)',
        height: 14,
        backgroundColor: '#F46E0F',
        top: 19,
        right: 0,
        opacity: 0.65,
        zIndex: -1,
        [theme.breakpoints.down('mobile')]: {
            width: 'calc(100% + 12px)',
            height: 7,
            top: 8,
        },
    },
    identityImage: {
        width: 484,
        marginTop: 108,
        [theme.breakpoints.down('mobile')]: {
            width: 242,
            marginTop: 42,
        },
    },
    content: {
        flexDirection: 'column',
        alignItems: 'center',
    },  
    endLogo: {
        width: 240,
        marginBottom: 200,
        [theme.breakpoints.down('mobile')]: {
            width: 70,
            marginBottom: 70,
        }
    },
}));

const animation = keyframes`
    from {
        transform: translateY(100px);
    }
    to {
        transform: translateY(0px);
    }
`

const PaperStylePage = ({ children, className }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <img alt=' ' className={classes.banner} src={require('../res/common/banner_brand.png')}/>
            <Reveal className={classes.reveal} keyframes={animation} fraction={0} triggerOnce>
                <div className={classes.contentContainer}>
                    <img
                        alt='WAFFLE STUDIO'
                        className={classes.titleLogo}
                        src={require('../res/logo/logo3.png')}
                    />
                    <img
                        alt='coffee and waffle'
                        className={classes.subtitleLogo}
                        src={require('../res/logo/coffee_and_waffle.png')}
                    />
                    <img
                        alt=' '
                        className={classes.mask}
                        src={require('../res/ui/mask_double.png')}
                    />
                    <div className={clsx(classes.content, className)}>
                        {children}
                    </div>
                    <img
                        alt='WAFFLE STUDIO'
                        className={classes.endLogo}
                        src={require('../res/logo/logo3.png')}
                    />
                </div>
            </Reveal>
        </div>
    )
}

export default PaperStylePage;