import { Assignment, Category, Chat, Close, Group, Home, Info, Key, LiveHelp, Logout, Restaurant, ScatterPlot, Settings } from "@mui/icons-material";
import { AppBar, Avatar, Button, createTheme, Dialog, DialogTitle, Divider, Drawer, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, TextField, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconButton, ProgressPanel } from "../../components";
import BurstModeIcon from '@mui/icons-material/BurstMode';
import { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { query } from "../../common/NetworkManager";
import { SHA256 } from "crypto-js";
import release from '../../releaseNote.json';

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: 'NotoSans',
        fontSize: 13,
    },
    breakpoints: {
        values: {
            mobile: 768,
            tablet: 1024,
        }
    }
});

const AdminApp = () => (
    <ThemeProvider theme={theme}>
        <AdminLayout />
    </ThemeProvider>
)

const useStyles = makeStyles(theme => ({
    page: {
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
    },
    drawer: {
        '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
        },
    },
    appbar: { '&&': {
        justifyContent: 'space-between',
        height: TOOLBAR_HEIGHT,
        paddingLeft: DRAWER_WIDTH + 16,
    }},
    toolbar: {
        justifyContent: 'center',
        height: TOOLBAR_HEIGHT,
    },
    logo: {
        width: 160,
    },
    body: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        justifyContent: 'center',
        height: 0,
        paddingTop: TOOLBAR_HEIGHT,
        paddingLeft: DRAWER_WIDTH,
        color: theme.palette.text.primary,
    },
    dialog: {
        display: 'block',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    pwDialogPaper: { '&&': {
        overflowY: 'hidden',
    }},
    pwDialogInput: { '&&': {
        margin: 16,
        marginTop: 0,
    }},
    pwDialogButton: {
        minHeight: 55,
    },
    versionDialogContent: {
        flexDirection: 'column',
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 40,
    },
}));

const DRAWER_WIDTH = 260;
const TOOLBAR_HEIGHT = 75;

const menu = [
    { text: 'OVERVIEW', to: '/admin', icon: <Home /> },
    { text: 'MENU' },
    { text: '카테고리 관리', to: '/admin/category', icon: <Category /> },
    { text: '메뉴 관리', to: '/admin/menu', icon: <Restaurant /> },
    { text: '토핑 관리', to: '/admin/topping', icon: <ScatterPlot /> },
    { text: 'CLIENT' },
    { text: '창업 상담', to: '/admin/counseling', icon: <Chat /> },
    { text: 'FAQ', to: '/admin/faq', icon: <LiveHelp /> },
    { text: '공지사항', to: '/admin/notice', icon: <Assignment /> },
    { text: 'REPORT' },
    { text: '방문자', to: '/admin/visitor', icon: <Group /> },
    { text: 'CONFIGURATION' },
    { text: '배너 관리', to: '/admin/banner', icon: <BurstModeIcon /> },
    { text: '환경 변수', to: '/admin/env', icon: <Settings /> },
]

const PW_DEFAULT = { password: '', reconfirm: '' }

const AdminLayout = () => {
    const [loginData, setLoginData] = useState();
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [pwDialog, setPwDialog] = useState(false);
    const [pwInput, setPwInput] = useState(PW_DEFAULT);
    const [pwLoading, setPwLoading] = useState(false);
    const [versionDialog, setVersionDialog] = useState(false);
    
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!loginData) {
            navigate('/admin/login');
        }
    }, [loginData, navigate])

    const onLogout = () => {
        sessionStorage.clear();
        setLoginData();
        setMenuAnchor(null);
    }

    const onExit = () => {
        sessionStorage.clear();
        setLoginData();
        navigate('/');
    }

    const isPwValid = () => {
        const pattern1 = /[0-9]/
        const pattern2 = /[a-zA-Z]/
        const pattern3 = /[`~!@#$%^&*()\-_=+\\|,<.>/?;:[{\]}'" ]/
        const pw = pwInput.password;

        if (!pattern1.test(pw) || !pattern2.test(pw) || !pattern3.test(pw) || pw.length < 8) {
            enqueueSnackbar('PASSWORD는 8자리 이상 문자, 숫자, 특수문자로 구성하여야 합니다.', { variant: 'error' });
            return false;
        }
        if (pw !== pwInput.reconfirm) {
            enqueueSnackbar('비밀번호가 다릅니다. 재확인을 다시 입력해주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onPwChange = async() => {
        if (isPwValid()) {
            setPwLoading(true);

            const id = loginData.id;
            const password = SHA256(pwInput.password).toString();
            await query('saveAdminPw', { id, password });

            enqueueSnackbar('비밀번호가 변경되었습니다.', { variant: 'success' });

            setPwInput(PW_DEFAULT);
            setPwDialog(false);
            setPwLoading(false);
        }
    }

    const onPwKeyDown = evt => {
        if (evt.key === 'Enter') {
            onPwChange();
        }
    }

    return (
        !loginData ? <Outlet context={[loginData, setLoginData]} /> :
        <div className={classes.page}>
            <AppBar>
                <Toolbar className={classes.appbar}>
                    <div>
                        <ListItemButton ref={menuAnchor} onClick={evt => setMenuAnchor(evt.currentTarget)}>
                            <ListItemAvatar>
                                <Avatar />
                            </ListItemAvatar>
                            <ListItemText primary={loginData.id} />
                        </ListItemButton>
                    </div>
                    <div>
                        <IconButton
                            onClick={() => setVersionDialog(true)}
                            tooltip='프로그램 정보'
                            icon={<Info />}
                        />
                        <IconButton
                            onClick={onExit}
                            tooltip='종료'
                            icon={<Logout />}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent' className={classes.drawer}>
                <Toolbar className={classes.toolbar}>
                    <Link to='/admin'>
                        <img alt=' ' src={require('../../res/logo/main.png')} className={classes.logo} />
                    </Link>
                </Toolbar>
                <Divider />
                <List>
                    {menu.map((item, index) => (
                        item.to ?
                        <ListItemButton
                            key={item.to}
                            component={Link}
                            to={item.to}
                            selected={location.pathname === item.to}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton> :
                        <ListSubheader key={index}>{item.text}</ListSubheader>
                    ))}
                </List>
            </Drawer>
            <div className={classes.body}>
                <Outlet context={[loginData, setLoginData]} />
            </div>
            <Menu
                anchorEl={menuAnchor}
                open={menuAnchor != null}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem onClick={() => { setPwDialog(true); setMenuAnchor(null); }}>
                    <ListItemIcon>
                        <Key />
                    </ListItemIcon>
                    <ListItemText>비밀번호 변경</ListItemText>
                </MenuItem>
                <MenuItem onClick={onLogout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText>로그아웃</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog
                className={classes.dialog}
                PaperProps={{ className: classes.pwDialogPaper }}
                open={pwDialog}
                onClose={() => setPwDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    비밀번호 변경
                    <IconButton
                        tooltip='닫기'
                        icon={<Close />}
                        onClick={() => setPwDialog(false)}
                    />
                </DialogTitle>
                <TextField
                    className={classes.pwDialogInput}
                    label='아이디'
                    value={loginData.id}
                    disabled
                />
                <TextField
                    className={classes.pwDialogInput}
                    label='변경할 비밀번호'
                    value={pwInput.password}
                    onChange={evt => setPwInput({ ...pwInput, password: evt.currentTarget.value })}
                    onKeyDown={onPwKeyDown}
                    inputProps={{ type: 'password' }}
                />
                <TextField
                    className={classes.pwDialogInput}
                    label='비밀번호 재확인'
                    value={pwInput.reconfirm}
                    onChange={evt => setPwInput({ ...pwInput, reconfirm: evt.currentTarget.value })}
                    onKeyDown={onPwKeyDown}
                    inputProps={{ type: 'password' }}
                />
                {pwLoading ? <ProgressPanel className={classes.pwDialogButton} /> :
                <Button
                    className={classes.pwDialogButton}
                    onClick={onPwChange}
                >
                    확인
                </Button>}
            </Dialog>
            <Dialog
                className={classes.dialog}
                open={versionDialog}
                onClose={() => setVersionDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    프로그램 정보
                    <IconButton
                        tooltip='닫기'
                        icon={<Close />}
                        onClick={() => setVersionDialog(false)}
                    />
                </DialogTitle>
                <div className={classes.versionDialogContent}>
                    <Typography fontSize={18}>Version</Typography>
                    <Typography> - {release.version}</Typography>
                    <Typography fontSize={18} style={{ marginTop: 16 }}>Note</Typography>
                    {release.note.map((item, index) => (
                        <Typography key={index}>
                            - {item}
                        </Typography>
                    ))}
                </div>
            </Dialog>
        </div>
    );
}

export default AdminApp;