import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PopupBanner = () => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(location.pathname === '/');

    const onDetail = () => {
        setOpen(false);
        navigate('/foundation/competition');
    }
    
    return (
        <Modal className={classes.modal} open={open} onClose={() => setOpen(false)} disableAutoFocus>
            <div className={classes.container}>
                <img alt='popup' className={classes.img} src={require('../res/common/popup.jpg')} />
                <div>
                    <div className={classes.button} onClick={() => setOpen(false)} style={{ backgroundColor: 'white' }}>
                        확 인
                    </div>
                    <div className={classes.button} onClick={onDetail} style={{ backgroundColor: '#F37021', color: 'white' }}>
                        자세히보기
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const useStyles = makeStyles(theme => ({
    modal: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        flexDirection: 'column',
        maxWidth: 360,
        width: '70%',
    },
    img: {
        minWidth: 0,
        objectFit: 'contain',
    },
    button: {
        flex: 1,
        fontFamily: 'NotoSans',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 24,
        color: '#323232',
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderTop: '1px solid #DADADA'
    },
}));

export default PopupBanner;