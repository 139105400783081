import { AddPhotoAlternate, Check } from "@mui/icons-material";
import { InputBase, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getImageUrl, query, uploadImage } from "../../common/NetworkManager";
import { FilePicker, IconButton, ProgressDialog } from "../../components";

const useStyles = makeStyles(theme => ({
    page: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        maxWidth: 1000,
        flexDirection: 'column',
        paddingBottom: 16,
    },
    title: { '&&': {
        fontSize: 30,
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        margin: 24,
    }},
    contentContainer: { '&&': {
        flex: 1,
        height: 0,
        marginTop: 16,
    }},
    contentImage: {
        maxWidth: 'calc(100% - 16px)',
    },
}));

const AdminNoticeWrite = () => {
    const [data, setData] = useState({ title: '', content: [''] });
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            setData(location.state.data);
        }
    }, [location]);

    const isValid = () => {
        if (data.title.trim() === '') {
            enqueueSnackbar('제목을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onSave = async() => {
        if (isValid()) {
            setLoading(true);

            const result = await query('saveNotice', data);
            const id = result.insertId || data.id;
            navigate('/admin/notice/read/' + id);

            enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
        }
        setLoading(false);
    }

    const removeImage = (index) => {
        const result = [...data.content];
        const removed = result.splice(index, 2);
        result[index - 1] += '\n' + removed[1];
        setData({ ...data, content: result });
    }

    const onImageSelect = async(file) => {
        setLoading (true);

        const key = await uploadImage(file);
        if (key != null) {
            setData({ ...data, content: [...data.content, key, ''] });
        }

        setLoading (false);
    }

    const onKeyDown = (evt, index) => {
        const pos = evt.target.selectionStart;
        if (pos !== evt.target.selectionEnd) return;
        switch (evt.key) {
            case 'Delete':
                if (pos !== evt.target.value.length) break;
                if (index === data.content.length - 1) break;
                
                removeImage(index + 1);
                evt.preventDefault();
                break;
            case 'Backspace':
                if (pos !== 0) break;
                if (index === 0) break;

                removeImage(index - 1);
                evt.target.parentElement.previousSibling.previousSibling.children[0].focus();
                evt.preventDefault();
                break;
            case 'ArrowUp':
            case 'ArrowLeft':
                if (index === 0) break;
                if (pos !== 0) break;

                evt.target.parentElement.previousSibling.children[2].children[0].focus();
                evt.preventDefault();
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                if (index === data.content.length - 1) break;
                if (pos !== evt.target.value.length) break;

                evt.target.parentElement.nextSibling.children[0].children[0].focus();
                evt.preventDefault();
                break;
            default:
                break;
        }
    }

    const onImageLeftKeyDown = (evt, index) => {
        switch (evt.key) {
            case 'Delete':
                removeImage(index);
            case 'Backspace': // eslint-disable-line no-fallthrough
            case 'ArrowUp':
            case 'ArrowLeft':
                evt.target.parentElement.parentElement.previousSibling.children[0].focus();
                evt.preventDefault();
                break;
            case 'ArrowDown':
                evt.target.parentElement.parentElement.nextSibling.children[0].focus();
                evt.preventDefault();
                break;
            case 'ArrowRight':
                evt.target.parentElement.nextSibling.nextSibling.children[0].focus();
                evt.preventDefault();
                break;
            default:
                break;
        }
    }

    const onImageRightKeyDown = (evt, index) => {
        switch (evt.key) {
            case 'Backspace':
                removeImage(index);
            case 'ArrowUp': // eslint-disable-line no-fallthrough
                evt.target.parentElement.parentElement.previousSibling.children[0].focus();
                evt.preventDefault();
                break;
            case 'Delete':
            case 'ArrowDown':
            case 'ArrowRight':
                evt.target.parentElement.parentElement.nextSibling.children[0].focus();
                evt.preventDefault();
                break;
            case 'ArrowLeft':
                evt.target.parentElement.previousSibling.previousSibling.children[0].focus();
                evt.preventDefault();
                break;
            default:
                break;
        }
    }

    return (
        <div className={classes.page}>
        <div className={classes.container}>
            <Typography className={classes.title}>{`공지사항 ${data.id ? '수정' : '작성'}`}</Typography>
            <TextField
                label='제목'
                value={data.title}
                onChange={evt => setData(data => ({ ...data, title: evt.target.value }))}
                inputProps={{ maxLength: 100 }}
            />
            <OutlinedDiv
                className={classes.contentContainer}
                label='내용'
                shrink={data.content.length > 1 || data.content[0] !== ''}
            >
                {data.content.map((item, i) => (
                    typeof(item) === 'string' ?
                    <InputBase
                        key={i}
                        value={data.content[i]}
                        multiline
                        onKeyDown={evt => onKeyDown(evt, i)}
                        onChange={evt => setData(data => {
                            const value = [...data.content];
                            value[i] = evt.target.value;
                            return { ...data, content: value };
                        })}
                        style={i === data.content.length - 1 ? { flex: 1, alignItems: 'start' } : {}}
                    /> :
                    <div key={i}>
                        <InputBase
                            value=''
                            onKeyDown={evt => onImageLeftKeyDown(evt, i)}
                            style={{ width: 2, alignItems: 'end' }}
                        />
                        <img
                            alt={'image' + i}
                            className={classes.contentImage}
                            src={getImageUrl(item)}
                            onClick={evt => evt.target.nextSibling.children[0].focus()}
                        />
                        <InputBase
                            value=''
                            onKeyDown={evt => onImageRightKeyDown(evt, i)}
                            style={{ flex: 1, alignItems: 'end' }}
                        />
                    </div>
                ))}
            </OutlinedDiv>
            <div>
                <FilePicker
                    tooltip='이미지 첨부'
                    icon={<AddPhotoAlternate />}
                    onChange={onImageSelect}
                />
                <IconButton
                    tooltip='저장'
                    icon={<Check />}
                    onClick={onSave}
                />
            </div>
            <ProgressDialog open={loading} />
        </div>
        </div>
    )
}

const InputComponent = ({ inputRef, ...other }) => <div {...other} style={{ display:'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', justifyContent: 'left' }} />;
const OutlinedDiv = ({ children, label, shrink, ...props }) => {
    return (
        <TextField
            variant="outlined"
            label={label}
            multiline
            InputLabelProps={{ shrink }}
            InputProps={{ inputComponent: InputComponent, style: { height: '100%' } }}
            inputProps={{ children }}
            {...props}
        />
    );
};

export default AdminNoticeWrite;