import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { PaperStylePage } from "../../components";
import { RouteChangeTracker } from "../../common/Utils";

const useStyles = makeStyles(theme => ({
    subTitle: {
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontWeight: 300,
        fontSize: 36,
        letterSpacing: -2.52,
        marginTop: 70,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.84,
            marginTop: 45,
        },
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 75,
        letterSpacing: -4.13,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 28,
            letterSpacing: -1.4,
        },
    },
    itemList: {
        flexDirection: 'column',
        marginTop: 135,
        marginBottom: 100,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 30,
            marginBottom: 30,
        },
    },
    item: {
        alignItems: 'center',
        marginTop: 25,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 15,
        },
    },
    circle: {
        minWidth: 189,
        height: 189,
        borderRadius: 95,
        boxShadow: '1px 1px 1px #00000029',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('mobile')]: {
            minWidth: 100,
            height: 100,
            borderRadius: 50,
        },
    },
    circleLine: {
        width: 144,
        height: 144,
        borderRadius: 79,
        border: '7px solid #F68D4D',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('mobile')]: {
            width: 80,
            height: 80,
            borderRadius: 44,
            borderWidth: 3.5,
        },
    },
    circleLineOdd: {
        borderColor: theme.palette.primary.main,
    },
    circleText: {
        fontFamily: 'NotoSans',
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontSize: 24,
        letterSpacing: -1.2,
        lineHeight: '25px',
        maxWidth: 90,
        wordBreak: 'keep-all',
        textAlign: 'center',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.54,
            lineHeight: '15px',
            maxWidth: 45,
        },
    },
    itemContentContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 90,
        [theme.breakpoints.down('mobile')]: {
            marginLeft: 22,
        },
    },
    itemTitle: {
        alignItems: 'center',
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 28,
        letterSpacing: -1.96,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 15,
            letterSpacing: -1.05,
        },
    },
    itemNumber: {
        fontFamily: 'NotoSans',
        fontSize: 12,
        width: 22,
        height: 22,
        borderRadius: 11,
        marginRight: 12,
        letterSpacing: 0,
        backgroundColor: '#F37021',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.background.default,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 10,
            width: 16,
            height: 16,
            borderRadius: 8,
            marginRight: 4,
        }
    },
    itemContent: {
        maxWidth: 550,
        whiteSpace: 'pre-line',
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontSize: 16,
        letterSpacing: -1.12,
        marginLeft: 34,
        marginTop: 4,
        wordBreak: 'keep-all',
        [theme.breakpoints.down('mobile')]: {
            maxWidth: 125,
            fontSize: 12,
            letterSpacing: -0.35,
            marginLeft: 0,
            marginTop: 0,
        },
    },
}));

const data = [
    {
        title: '가맹 상담',
        content: '사업설명, 자금운영 상담, 투자자금 계획, 개설절차·조건 등 창업에 필요한 전반적인 사항 안내'
    },
    {
        title: '상권분석 및 점포선정',
        content: '입점 후보지 현장방문조사, 적합한 최적의 입지 물색, 후보지 선정하여 타당성 분석'
    },
    {
        title: '가맹점 계약',
        content: '점포확정, 가맹점 계약 체결'
    },
    {
        title: '인테리어 공사',
        content: '점포실측, 도면확정, 디자인 결정 - 개인적으로 인테리어 시공업체 연결가능'
    },
    {
        title: '교육',
        content: '와플 및 음료 레시피교육, 매장운영교육, 점포실습교육, 리허설'
    },
    {
        title: '가오픈',
        content: '최종사항 점검완료 확인 및 인테리어, 집기, 초도물품 등 체크'
    },
    {
        title: '개업 및 관리',
        content: '매장 오픈 및 영업개시, 본사의 지속적인 현황체크'
    },
]

const Procedure = () => {
    RouteChangeTracker();
    const classes = useStyles();

    return (
        <PaperStylePage>
            <div className={classes.subTitle}>와플스튜디오 창업절차</div>
            <div className={classes.title}>PROCESS STEP</div>
            <div className={classes.itemList}>
                {data.map((item, index) => (
                    <div key={index} className={classes.item}>
                        <div className={classes.circle}>
                            <div className={clsx(classes.circleLine, {
                                [classes.circleLineOdd]: index % 2 === 1,
                            })}>
                                <div className={classes.circleText}>
                                    {item.title}
                                </div>
                            </div>
                        </div>
                        <div className={classes.itemContentContainer}>
                            <div className={classes.itemTitle}>
                                <div className={classes.itemNumber}>{index + 1}</div>
                                {item.title}
                            </div>
                            <div className={classes.itemContent}>
                                {item.content}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </PaperStylePage>
    )
}

export default Procedure;