import { CircularProgress, Dialog, FormControlLabel, Radio } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { sendCounseling } from "../../common/NetworkManager";
import { RouteChangeTracker } from "../../common/Utils";
import { IconButton, PaperStylePage, TextField } from "../../components";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 75,
        letterSpacing: -4.13,
        lineHeight: '78px',
        textAlign: 'center',
        whiteSpace: 'pre-line',
        marginTop: 70,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 22,
            letterSpacing: -1.12,
            lineHeight: '24px',
            marginTop: 40,
        },
    },
    inputContainer: {
        alignSelf: 'stretch',
        flexDirection: 'column',
        marginTop: 144,
        marginLeft: 68,
        marginRight: 68,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 30,
            marginLeft: 5,
            marginRight: 5,
        },
        '& > div': {
            [theme.breakpoints.down('mobile')]: {
                flexDirection: 'column',
            }
        },
    },
    textField: { '&&': {
        flex: 1,
        margin: 16,
        [theme.breakpoints.down('mobile')]: {
            margin: 20,
        },
    }},
    multilineField: {
        height: '302px !important',
        [theme.breakpoints.down('mobile')]: {
            height: '150px !important',
        }
    },
    privacyCheck: {
        marginTop: 34,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 30,
        },
        '& .MuiTypography-root': {
            fontFamily: 'NotoSans',
            fontWeight: 300,
            color: '#919191',
            fontSize: 18,
            letterSpacing: -0.9,
            [theme.breakpoints.down('mobile')]: {
                fontSize: 12,
                letterSpacing: -0.6,
            },
        },
    },
    errorText: {
        fontFamily: 'NotoSans',
        fontSize: 18,
        letterSpacing: -0.9,
        color: theme.palette.error.main,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.6,
        },
    },
    privacyButton: {
        marginTop: 10,
        width: 180,
        height: 55,
        borderRadius: 13,
        fontSize: 16,
        letterSpacing: -0.11,
        backgroundColor: '#F3F3F3',
        fontFamily: 'NotoSans',
        color: '#A1A1A1',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 4,
            width: 193,
            height: 50,
            borderRadius: 7,
            fontSize: 15,
        },
    },
    remark: {
        fontFamily: 'NotoSans',
        fontWeight: 300,
        color: '#F5841E',
        fontSize: 18,
        letterSpacing: -0.9,
        marginTop: 70,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 20,
            fontSize: 12,
            letterSpacing: -0.6,
        },
    },
    sendButton: {
        marginTop: 10,
        marginBottom: 100,
        width: 180,
        height: 55,
        borderRadius: 13,
        fontSize: 16,
        letterSpacing: -0.11,
        backgroundColor: '#F5841E',
        fontFamily: 'NotoSans',
        color: theme.palette.background.default,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 10,
            marginBottom: 35,
            width: 193,
            height: 50,
            borderRadius: 7,
            fontSize: 14,
        },
    },
    dialog: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    privacyDialogPaper: { '&&': {
        color: theme.palette.text.primary,
        alignItems: 'center',
        fontFamily: 'SCDream',
        borderRadius: 13,
        fontSize: 34,
        letterSpacing: -1.16,
        paddingBottom: 52,
        [theme.breakpoints.down('mobile')]: {
            borderRadius: 7,
            fontSize: 17,
            letterSpacing: -0.58,
            paddingBottom: 26,
        },
    }},
    privacyDialogClose: { '&&': {
        alignSelf: 'end',
        marginTop: 60,
        marginRight: 60,
        marginBottom: 34,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 30,
            marginRight: 30,
            marginBottom: 17,
        }
    }},
    privacyDialogCloseImage: {
        width: 30,
        height: 30,
        [theme.breakpoints.down('mobile')]: {
            width: 20,
            height: 20,
        },
    },
    privacyFrame: {
        width: 400,
        height: 477,
        marginLeft: 100,
        marginRight: 100,
        marginTop: 32,
        [theme.breakpoints.down('mobile')]: {
            width: 200,
            height: 238,
            marginLeft: 50,
            marginRight: 50,
            marginTop: 16,
        },
    },
    confirmDialogPaper: { '&&': {
        color: '#F5841E',
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontFamily: 'NotoSans',
        fontWeight: 300,
        borderRadius: 13,
        fontSize: 20,
        letterSpacing: -0.14,
        lineHeight: '24px',
        padding: 30,
        paddingTop: 45,
        [theme.breakpoints.down('mobile')]: {
            borderRadius: 7,
            fontSize: 15,
            letterSpacing: -0.1,
            lineHeight: '20px',
        },
    }},
    dialogButton: {
        backgroundColor: '#F5841E',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.background.default,
        width: 180,
        height: 55,
        fontSize: 16,
        marginTop: 24,
        borderRadius: 13,
        whiteSpace: 'pre-wrap',
        cursor: 'pointer',
        [theme.breakpoints.down('mobile')]: {
            width: 130,
            height: 50,
            fontSize: 20,
            marginTop: 26,
            borderRadius: 6.5,
        },
    },
}));

const DEFAULT_VALUE = {
    name: '',
    tel: '',
    email: '',
    region: '',
    content: '',
    privacy: false,
}

const Counseling = () => {
    RouteChangeTracker();
    const [data, setData] = useState(DEFAULT_VALUE);
    const [loading, setLoading] = useState(false);
    const [privacyDialog, setPrivacyDialog] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [errorField, setErrorField] = useState({});
    const inputRef = useRef();
    const classes = useStyles();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const isPrivacyValid = () => {
        if (data.privacy) {
            setErrorField(value => {
                value = { ...value };
                delete value.privacy;
                return value;
            })
            return true;
        } else {
            setErrorField(value => ({ ...value, privacy: '개인정보처리 동의가 필요합니다.' }))
            return false;
        }
    }

    const isNameValid = () => {
        if (data.name.trim() !== '') {
            setErrorField(value => {
                value = { ...value };
                delete value.name;
                return value;
            })
            return true;
        } else {
            setErrorField(value => ({ ...value, name: '이름을 입력해주세요.' }))
            return false;
        }
    }

    const isTelValid = () => {
        if (data.tel.length >= 11) {
            setErrorField(value => {
                value = { ...value };
                delete value.tel;
                return value;
            })
            return true;
        } else {
            setErrorField(value => ({ ...value, tel: '올바른 전화번호를 입력해주세요.' }))
            return false;
        }
    }

    const isEmailValid = () => {
        if (data.email.trim() !== '' &&
        data.email.includes('@') &&
        data.email.includes('.')) {
            setErrorField(value => {
                value = { ...value };
                delete value.email;
                return value;
            })
            return true;
        } else {
            setErrorField(value => ({ ...value, email: '올바른 이메일을 입력해주세요.' }))
            return false;
        }
    }

    const isValid = () => {
        let result = isPrivacyValid();
        result = isNameValid() && result;
        result = isTelValid() && result;
        result = isEmailValid() && result;
        return result;
    }

    const onSend = async() => {
        if (isValid()) {
            setLoading(true);

            const token = await executeRecaptcha('counseling');
            const sendData = {
                name: data.name,
                tel: data.tel,
                email: data.email,
                region: data.region,
                content: data.content,
                token: token,
            }
            await sendCounseling(sendData);
            setData(DEFAULT_VALUE);
            setConfirmDialog(true);
            setLoading(false);
        } else {
            setErrorField(value => {
                if (Object.keys(value).length > 1 || data.privacy) {
                    window.scrollTo(0, inputRef.current.offsetTop);
                }
                return value;
            })
        }
    }

    const onTelInput = (value) => {
        const numValue = value.replace(/[^0-9]/g, '').replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
        if (numValue.length <= 13) {
            setData({ ...data, tel: numValue });
        }
    }

    return (
        <PaperStylePage className={classes.container}>
            <div className={classes.title}>{'와플스튜디오\n창업상담 신청하기'}</div>
            <div ref={inputRef} className={classes.inputContainer}>
                <div>
                    <TextField
                        label='이름'
                        value={data.name}
                        className={classes.textField}
                        onChange={value => setData({ ...data, name: value })}
                        onBlur={isNameValid}
                        maxLength={20}
                        errorText={errorField.name}
                    />
                    <TextField
                        label='창업희망지역'
                        value={data.region}
                        className={classes.textField}
                        onChange={value => setData({ ...data, region: value })}
                        maxLength={50}
                    />
                </div>
                <div>
                    <TextField
                        label='연락처'
                        value={data.tel}
                        className={classes.textField}
                        onChange={value => onTelInput(value)}
                        onBlur={isTelValid}
                        errorText={errorField.tel}
                    />
                    <TextField
                        label='이메일'
                        value={data.email}
                        className={classes.textField}
                        onChange={value => setData({ ...data, email: value })}
                        onBlur={isEmailValid}
                        maxLength={70}
                        errorText={errorField.email}
                    />
                </div>
                <TextField
                    label='상담내용'
                    value={data.content}
                    className={classes.textField}
                    onChange={value => setData({ ...data, content: value })}
                    maxLength={500}
                    inputProps={{ className: classes.multilineField  }}
                    InputProps={{ multiline: true }}
                />
            </div>
            <FormControlLabel
                className={classes.privacyCheck}
                label='개인정보처리방침을 읽었으며 이에 동의합니다.'
                control={
                    <Radio
                        size='small'
                        checked={data.privacy}
                        color='secondary'
                        onChange={() => {
                            setData({ ...data, privacy: true });
                            setErrorField(value => {
                                value = { ...value };
                                delete value.privacy;
                                return value;
                            })
                        }}
                    />
                }
            />
            {errorField.privacy != null &&
            <div className={classes.errorText}>{errorField.privacy}</div>}
            <div className={classes.privacyButton} onClick={() => setPrivacyDialog(true)}>개인정보처리방침</div>
            <div className={classes.remark}>와플스튜디오는 당신의 성공과 함께합니다.</div>
            {loading ?
            <CircularProgress /> :
            <div className={classes.sendButton} onClick={onSend}>신청하기</div>}
            <Dialog
                open={privacyDialog}
                onClose={() => setPrivacyDialog(false)}
                className={classes.dialog}
                PaperProps={{ className: classes.privacyDialogPaper }}
            >
                <IconButton
                    className={classes.privacyDialogClose}
                    tooltip='닫기'
                    icon={<img className={classes.privacyDialogCloseImage} src={require('../../res/icon/btn_close.png')} alt='닫기' />}
                    onClick={() => setPrivacyDialog(false)}
                />
                개인정보취급방침
                <iframe src='/privacy.html' className={classes.privacyFrame} title='privacy' />
                <div
                    className={classes.dialogButton}
                    onClick={() => {
                        setData({ ...data, privacy: true });
                        setErrorField(value => {
                            value = { ...value };
                            delete value.privacy;
                            return value;
                        });
                        setPrivacyDialog(false);
                    }}
                >
                    동     의
                </div>
            </Dialog>
            <Dialog
                open={confirmDialog}
                className={classes.dialog}
                PaperProps={{ className: classes.confirmDialogPaper }}
            >
                {'신청이\n완료되었습니다.'}
                <div className={classes.dialogButton} onClick={() => setConfirmDialog(false)}>
                    확     인
                </div>
            </Dialog>
        </PaperStylePage>
    )
}

export default Counseling;