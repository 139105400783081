import axios from 'axios';
import { AES } from 'crypto-js';

const getMenu = async() => {
    const res = await axios.post('/api/menu');
    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            '서버와의 통신이 실패하였습니다.',
            { variant: 'error' }
        )
    }
    return res.data;
}

const query = async(queryName, data) => {
    if (typeof(data) === 'object') {
        data = Object.keys(data).reduce((result, key) => ({
            ...result,
            [key]: data[key] != null && typeof(data[key]) === 'object' ? JSON.stringify(data[key]) : data[key],
        }), {})
    }
    queryName = AES.encrypt(queryName, process.env.REACT_APP_AES256_KEY).toString().replace(/\//gi, '@');
    const res = await axios.post(`/api/query/${queryName}`, data);
    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            '서버와의 통신이 실패하였습니다.',
            { variant: 'error' }
        )
    }
    return res.data;
}

const sendCounseling = async(data) => {
    const res = await axios.post('/api/sendcounseling', data);
    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            '서버와의 통신이 실패하였습니다.',
            { variant: 'error' }
        )
    }
}

const getBanner = async() => {
    const res = await axios.post('/api/banner');
    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            '서버와의 통신이 실패하였습니다.',
            { variant: 'error' }
        )
    }
    return res.data;
}

const getYesterdayVisitors = async() => {
    const res = await axios.post('/api/yesterdayvisitors');
    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            '서버와의 통신이 실패하였습니다.',
            { variant: 'error' }
        )
    }
    return res.data;
}
const getWeekVisitors = async() => {
    const res = await axios.post('/api/weekvisitors');
    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            '서버와의 통신이 실패하였습니다.',
            { variant: 'error' }
        )
    }
    return res.data;
}

const uploadMenuImage = async(file) => {
    if (file.size > 9000000) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `9MB 이하의 파일만 업로드 가능합니다.`,
            { variant: 'error' }
        )
        return;
    }
    if (file.name.substring(file.name.length - 4) !== '.png') {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `PNG파일만 업로드 가능합니다.`,
            { variant: 'error' }
        )
        return;
    }

    const formData = new FormData();
    formData.append('files', file);

    const res = await axios.post('/api/uploadmenuimage', formData);

    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `파일 업로드가 실패하였습니다.
            코드 : ${res.data.err.errno || res.data.err.code}`,
            { variant: 'error' }
        )
    }
    return res.data;
}

const uploadBannerImage = async(file) => {
    if (file.size > 9000000) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `9MB 이하의 파일만 업로드 가능합니다.`,
            { variant: 'error' }
        )
        return;
    }
    if (file.name.substring(file.name.length - 4) !== '.png') {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `PNG파일만 업로드 가능합니다.`,
            { variant: 'error' }
        )
        return;
    }
    const formData = new FormData();
    formData.append('files', file);

    const res = await axios.post('/api/uploadbannerimage', formData);

    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `파일 업로드가 실패하였습니다.
            코드 : ${res.data.err.errno || res.data.err.code}`,
            { variant: 'error' }
        )
    }
    return res.data;
}

const uploadImage = async(file) => {
    if (file.size > 9000000) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `9MB 이하의 파일만 업로드 가능합니다.`,
            { variant: 'error' }
        )
        return;
    }
    if (file.name.substring(file.name.length - 4) !== '.png') {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `PNG파일만 업로드 가능합니다.`,
            { variant: 'error' }
        )
        return;
    }
    const formData = new FormData();
    formData.append('files', file);

    const res = await axios.post('/api/uploadimage', formData);

    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `파일 업로드가 실패하였습니다.
            코드 : ${res.data.err.errno || res.data.err.code}`,
            { variant: 'error' }
        )
    }
    return res.data;
}

const getImageUrl = (key) => `/api/image?key=${key}`;

const downloadFile = (fileKey, seq) => window.open(getImageUrl(fileKey, seq));

const getFileList = async(fileKey) => {
    const res = await axios.post('/api/file_list', { fileKey });
    if (res.data.err != null) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `파일정보 불러오기를 실패하였습니다.
            코드 : ${res.data.err.errno || res.data.err.code}`,
            { variant: 'error' }
        )
    }
    return res.data;
}

const sendKakao = async(to, templateId, conf, text, buttons = []) => {
    const res = await axios.post('/api/kakao', {
        apiKey: conf['SOLAPI_KEY'],
        apiSecret: conf['SOLAPI_SECRET'],
        to,
        text,
        from: conf['SOLAPI_SENDER'],
        pfId: conf['SOLAPI_PFID'],
        templateId,
        buttons
    });

    if (res.data.err) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `알림톡 전송에 실패하였습니다.
            메세지 : ${res.data.err.msg}`,
            { variant: 'error' }
        )
    }

    return res.data;
}

const getInstaData = async() => {
    let res;
    try{
        res = await axios.get('/insta/users/web_profile_info/?username=wafflestudioseoul', {
            headers: { 'x-ig-app-id': '936619743392459' }
        })
        res = res.data.data.user.edge_owner_to_timeline_media.edges.map(item => item.node.thumbnail_src);
    } catch(err) {
        res = { err };
    }
    return res;
}

export {
    query,
    getMenu,
    sendCounseling,
    getBanner,
    getYesterdayVisitors,
    getWeekVisitors,
    sendKakao,
    uploadMenuImage,
    uploadBannerImage,
    uploadImage,
    downloadFile,
    getImageUrl,
    getFileList,
    getInstaData,
}