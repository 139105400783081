import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import { getImageUrl } from "../common/NetworkManager";
import { RouteChangeTracker, useWindowSize } from "../common/Utils";
import { MenuItemSlider, ScrollProgress } from "../components";
import { Link } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        overflow: 'hidden',
        width: '100%',
    },
    slider: {
        width: '100%',
        '& .slick-track': {
            width: 'max-content !important',
        },
        '& .slick-slide': {
            [theme.breakpoints.up('mobile')]: {
                transition: theme.transitions.create('filter'),
                filter: 'brightness(60%)',
            }
        },
        '& .slick-current': {
            filter: 'brightness(100%) !important',
        },
    },
    sliderScroll: { '&&': {
        display: 'none',
        [theme.breakpoints.down('mobile')]: {
            display: 'unset',
        },
    }},
    bannerLink: {
        outline: 'none',
    },
    unclickable: {
        cursor: 'default',
        pointerEvents: 'none',
    },
    bannerImage: {
        display: 'block',
        backgroundSize: 'cover',
        aspectRatio: '16 / 9',
        objectFit: 'cover',
    },
    bannerMargin: {
        marginLeft: 10,
        marginRight: 10,
    },
    mainLogoContainer: {
        position: 'relative',
        width: '100%',
        maxWidth: 1050,
        justifyContent: 'space-between',
        marginTop: 170,
        [theme.breakpoints.down('mobile')]: {
            maxWidth: 320,
            height: 187,
            marginTop: 30,
            alignItems: 'center',
        },
    },
    mainLogo: {
        width: 420,
        [theme.breakpoints.down('mobile')]: {
            width: 'calc(100% - 10px)',
            position: 'absolute',
            left: 5,
            opacity: 0.1,
        },
    },
    mainLogoTextContainer: {
        display: 'block',
        marginTop: 42,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 'unset',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    mainLogoTitle: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: '38px',
        letterSpacing: '-1.9px',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 4,
            marginLeft: 10,
            fontSize: '22.5px',
            whiteSpace: 'pre-line',
            letterSpacing: '-1.13px',
            lineHeight: '30px',
        },
    },
    mainLogoText: {
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontSize: '18px',
        letterSpacing: '-0.9px',
        lineHeight: '27px',
        whiteSpace: 'pre-line',
        marginTop: 20,
        [theme.breakpoints.down('mobile')]: {
            width: 108,
            marginTop: 8,
            marginRight: 37,
            fontSize: '14px',
            letterSpacing: '-0.7px',
            lineHeight: '17.5px',
            wordBreak: 'keep-all',
        },
    },
    conceptWaffle: {
        marginTop: 198,
        width: '100%',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 54,
        },
    },
    menuSliderContainer: {
        width: '100%',
        flexDirection: 'column',
        marginTop: 112,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 52,
        },
    },
    maskOrange: {
        width: '100%',
        marginTop: 109,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 105,
            height: 46,
        },
    },
    videoOuterContainer: {
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        marginTop: 220,
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 65,
        },
    },
    videoContainer: {
        maxWidth: '100%',
        flexDirection: 'column',
        zIndex: 1,
    },
    conceptWall: {
        position: 'absolute',
        width: 1345,
        height: 447,
        top: 188,
        right: '35%',
        opacity: 0.35,
        [theme.breakpoints.down('mobile')]: {
            width: 234,
            height: 148,
            top: 64,
            left: 20,
            right: 'unset',
            opacity: 1,
            objectFit: 'cover',
        },
    },
    artworkContainer: {
        flexDirection: 'column',
        alignItems: 'end',
        alignSelf: 'start',
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.down('mobile')]: {
            alignSelf: 'end',
        },
    },
    artworkText: {
        whiteSpace: 'pre-line',
        textAlign: 'end',
        fontFamily: 'NotoSans',
        color: theme.palette.text.primary,
        marginTop: 14,
        marginRight: 8,
        fontSize: 40,
        letterSpacing: -1.4,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 16,
            letterSpacing: -0.56,
            marginTop: 0,
            marginRight: 0,
        },
    },
    vlogo: {
        width: 390,
        [theme.breakpoints.down('mobile')]: {
            width: 152,
        }
    },
    video: {
        width: '680px !important',
        height: 'auto !important',
        alignSelf: 'end',
        marginRight: 20,
        [theme.breakpoints.down('mobile')]: {
            width: '100% !important',
            margin: 0,
            marginTop: 84,
            alignSelf: 'center',
            transform: 'unset',
        },
    },
    videoConceptContainer: {
        width: '100%',
        overflowX: 'auto',
    },
    videoConceptImg: {
        width: 290,
        height: 290,
        opacity: 0.9,
        objectFit: 'cover',
        marginTop: 40,
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.down('mobile')]: {
            width: 145,
            height: 145,
            marginTop: 5,
            marginLeft: 5,
            marginRight: 5,
        },
    },
    competitiveOuterContainer: {
        flexDirection: 'column',
        width: '100%',
        marginTop: 210,
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 100,
        },
    },
    competitiveContainer: {
        flexDirection: 'column',
    },
    competitiveMask: {
        width: '100%',
        filter: 'drop-shadow(0px 7px 3px #00000040)',
        position: 'absolute',
        zIndex: 1,
        height: 172,
        [theme.breakpoints.down('mobile')]: {
            height: 40,
        },
    },
    competitiveImage: {
        width: '100%',
        maxWidth: 1225,
        marginTop: 4,
    },
    competitiveLink: {
        width: 'fit-content',
        marginTop: 30,
        textDecoration: 'none',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 30,
            marginLeft: 20,
        },
    },
    competitiveTitle: {
        fontFamily: 'SCDream',
        fontSize: 40,
        color: theme.palette.text.primary,
        letterSpacing: -1.4,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 25,
            letterSpacing: -0.87,
        },
    },
    competitiveText: {
        whiteSpace: 'pre-line',
        fontFamily: 'NotoSans',
        color: theme.palette.text.primary,
        fontSize: 20,
        letterSpacing: -0.6,
        lineHeight: '29px',
        marginTop: 14,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 10,
            fontSize: 14,
            letterSpacing: -0.42,
            lineHeight: '20.5px',
        },
    },
    competitiveButtonContainer: {
        color: '#787878',
        fontFamily: 'NotoSans',
        fontSize: 15,
        letterSpacing: -0.45,
        alignItems: 'end',
        marginTop: 20,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 15,
            fontSize: 13,
            letterSpacing: -0.39,
        },
    },
    instaTitleContainer: {
        position: 'relative',
        height: 97,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 65,
        cursor: 'pointer',
        [theme.breakpoints.down('mobile')]: {
            height: 40,
            marginTop: 58,
        },
    },
    instaIcon: {
        width: 40,
        height: 40,
        marginTop: 16,
        [theme.breakpoints.down('mobile')]: {
            width: 20,
            height: 20,
            marginTop: 4,
        },
    },
    instaTitleText: {
        flexDirection: 'column',
        fontFamily: 'SCDream',
        fontSize: 50,
        letterSpacing: -1.5,
        color: theme.palette.text.primary,
        alignItems: 'center',
        marginTop: 12,
        marginLeft: 14,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 18,
            letterSpacing: -0.54,
            marginTop: 4,
            marginLeft: 5,
        },
    },
    instaMask: {
        position: 'absolute',
        width: 448,
        bottom: 0,
        marginLeft: 16,
        [theme.breakpoints.down('mobile')]: {
            width: 161,
            height: 6.5,
            marginLeft: 6,
        },
    },
    instaTwinkle: {
        width: 36,
        height: 43,
        alignSelf: 'start',
        marginLeft: 8,
        [theme.breakpoints.down('mobile')]: {
            width: 16,
            height: 18.5,
            marginLeft: 5,
        },
    },
    instaContainer: {
        width: '100%',
        overflowX: 'auto',
        marginTop: 30,
        marginBottom: 200,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 25,
            marginBottom: 100,
        },
    },
    instaImage: {
        cursor: 'pointer',
        width: 290,
        height: 290,
        marginLeft: 6,
        marginRight: 6,
        [theme.breakpoints.down('mobile')]: {
            width: 79,
            height: 79,
            marginLeft: 1,
            marginRight: 1,
        },
    },
}));

const instaData = [
    { fileName: '1.jpg', pid: 'ClX6NfXv0TU' },
    { fileName: '2.jpg', pid: 'ClVGH8eJ3i3' },
    { fileName: '3.jpg', pid: 'ClFqykvJi05' },
    { fileName: '4.jpg', pid: 'ClDTJ6GpdXE' },
    { fileName: '5.jpg', pid: 'CkxdbTspiSj' },
    { fileName: '6.jpg', pid: 'Ckkl6fiJxSv' },
    { fileName: '7.jpg', pid: 'CkhfHprp0QW' },
    { fileName: '8.jpg', pid: 'CkNMJarpMHP' },
    { fileName: '9.jpg', pid: 'CkKcmYaJM_7' },
    { fileName: '10.jpg', pid: 'CkH35_DJMz-' },
    { fileName: '11.jpg', pid: 'Cj7ULk0uz_U' },
    { fileName: '12.jpg', pid: 'CjrmuMdpeT_' },
    { fileName: '13.jpg', pid: 'CjpDc7MpwI2' },
    { fileName: '14.jpg', pid: 'CjZpj7cJ-in' },
    { fileName: '15.jpg', pid: 'CihEjJ1pyjr' },
    { fileName: '16.jpg', pid: 'CiEu4FVuw01' },
    { fileName: '17.jpg', pid: 'ChzDoowuXbG' },
    { fileName: '18.jpg', pid: 'ChosZvoOU_P' },
    { fileName: '19.jpg', pid: 'ChZPJz9uuyu' },
    { fileName: '20.jpg', pid: 'ChB2GTPusP8' },
    { fileName: '21.jpg', pid: 'Cg3wHifOzPj' },
    { fileName: '22.jpg', pid: 'Cgyi4zbOiCx' },
    { fileName: '23.jpg', pid: 'CggoM0Wu-7c' },
    { fileName: '24.jpg', pid: 'CgWC-GJJVYk' },
]

const Home = ({ bannerData, cateData, menuData, companyData }) => {
    RouteChangeTracker();
    const [scroll, setScroll] = useState(0);
    const classes = useStyles();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
    const windowSize = useWindowSize();

    const summaryData = useMemo(() => (
        cateData.reduce((result, item) => ({
            ...result,
            [item.id]: {
                ...item,
                menuItems: item.children.flatMap(childItem => menuData.filter(menuItem => menuItem.categoryId === childItem.id)),
            }
        }), {})
    ), [cateData, menuData]);

    return (
        <div className={classes.container}>
            <Slider
                className={classes.slider}
                slidesToShow={1}
                variableWidth
                centerMode
                infinite
                autoplay
                autoplaySpeed={parseInt(companyData.sliderBannerSpeed)}
                swipeToSlide
                pauseOnHover={false}
                beforeChange={(current, next) => setScroll(next / (bannerData.length - 1))}
            >
                {bannerData.map((item, index) => (
                    <Link
                        key={item.id}
                        to={item.linkUrl}
                        className={clsx(classes.bannerLink, {
                            [classes.unclickable]: item.linkUrl === '',
                        })}
                    >
                        <img
                            alt={'banner' + index}
                            className={clsx(classes.bannerImage, {
                                [classes.bannerMargin]: windowSize.width > 1280,
                            })}
                            style={{ width: windowSize.width > 1300 ? 1300 : windowSize.width }}
                            src={getImageUrl(item.image + '_banner')}
                        />
                    </Link>
                ))}
            </Slider>
            <ScrollProgress value={scroll} className={classes.sliderScroll} />
            <div className={classes.mainLogoContainer}>
                <Fade direction='left' duration={isMobile ? 0 : 1500} fraction={isMobile ? 0 : 0.66} triggerOnce>
                    <img alt=' ' className={classes.mainLogo} src={require('../res/logo/shoes.png')} />
                </Fade>
                <div className={classes.mainLogoTextContainer}>
                    <Fade direction='right' duration={isMobile ? 0 : 1800} fraction={isMobile ? 0 : 0.66} triggerOnce>
                        <div className={classes.mainLogoTitle}>
                            {`Choose
                            your
                            preference`}
                        </div>
                        <div className={classes.mainLogoText}>
                            {`전 세계 가장 맛있는 와플들이 한 곳에!
                            무엇을 선택하든 기대 그 이상!
                            와플스튜디오만의 독보적 프리미엄`}
                        </div>
                    </Fade>
                </div>
            </div>
            <img alt=' ' className={classes.conceptWaffle} src={require('../res/common/concept_waffles.png')} />
            <div className={classes.menuSliderContainer}>
                {Object.values(summaryData).map(item => item.menuItems.length < 3 ? null : (
                    <MenuSlider
                        key={item.id}
                        id={item.id}
                        title={item.name}
                        data={item.menuItems}
                        sliderSpeed={companyData.sliderMenuSpeed}
                    />
                ))}
            </div>
            <img alt=' ' className={classes.maskOrange} src={require('../res/ui/mask_orange.png')} />
            <div className={classes.videoOuterContainer}>
                <img alt=' ' className={classes.conceptWall} src={require('../res/common/concept_wall.png')} />
                <div className={classes.videoContainer}>
                    <div className={classes.artworkContainer}>
                        <img alt=' ' className={classes.vlogo} src={require('../res/logo/vlogo.png')} />
                        <div className={classes.artworkText}>
                            {`with
                            the ARTWORK`}
                        </div>
                    </div>
                    <ReactPlayer
                        className={classes.video}
                        url={require('../res/intro.mp4')}
                        controls
                        pip={false}
                        config={{ file: { 
                            attributes: {
                               controlsList: 'noplaybackrate nodownload',
                               disablePictureInPicture: true,
                            }
                        }}}
                    />
                    <div className={classes.videoConceptContainer}>
                        <img alt=' ' className={classes.videoConceptImg} src={require('../res/common/concept_video1.jpg')} />
                        <img alt=' ' className={classes.videoConceptImg} src={require('../res/common/concept_video2.jpg')} />
                        <img alt=' ' className={classes.videoConceptImg} src={require('../res/common/concept_video3.jpg')} />
                        <img alt=' ' className={classes.videoConceptImg} src={require('../res/common/concept_video4.jpg')} />
                    </div>
                </div>
            </div>

            <div className={classes.competitiveOuterContainer}>
                <img alt=' ' className={classes.competitiveMask} src={require('../res/ui/mask_B1.png')} />
                <div className={classes.competitiveContainer}>
                    <img alt=' ' className={classes.competitiveImage} src={require('../res/common/concept_blue.jpg')} />
                    <Link to='/foundation/competition' className={classes.competitiveLink}>
                        <div className={classes.competitiveTitle}>Competitive</div>
                        <div className={classes.competitiveText}>
                            {`맛과 비주얼의 유니크함!
                            창업의 정답은 와플스튜디오`}
                        </div>
                        <div className={classes.competitiveButtonContainer}>
                            more
                            <img alt=' ' src={require('../res/icon/more.png')} style={{ marginLeft: 10, marginBottom: 4 }} />
                        </div>
                    </Link>
                </div>
            </div>
            <div
                className={classes.instaTitleContainer}
                onClick={() => window.open(`https://www.instagram.com/${companyData.companyInstaId}/`)}
            >
                <img alt='Instagram' className={classes.instaIcon} src={require('../res/icon/insta.png')} />
                <div className={classes.instaTitleText}>
                    {companyData.companyInstaId}
                </div>
                <img alt=' ' className={classes.instaTwinkle} src={require('../res/ui/twinkle.png')} />
                <img alt=' ' className={classes.instaMask} src={require('../res/ui/mask_blue.png')} />
            </div>
            <div className={classes.instaContainer}>
                {instaData.map((item, index) => (
                    <img
                        alt={'인스타 피드' + index}
                        key={item.pid}
                        className={classes.instaImage}
                        src={require(`../res/insta/${item.fileName}`)}
                        onClick={() => window.open(`https://www.instagram.com/p/${item.pid}/`)}
                    />
                ))}
            </div>
        </div>
    )
}

const useMenuSliderStyles = makeStyles(theme => ({
    container: {
        flexDirection: 'column',
        marginTop: 86,
        marginBottom: 86,
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 28,
            marginBottom: 28,
        },
    },
    titleContainer: {
        marginTop: -8,
    },
    title: {
        position: 'relative',
        cursor: 'default',
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 50,
        letterSpacing: -1.53,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 25,
            letterSpacing: -0.77,
        },
    },
    titleSymbol1: {
        position: 'absolute',
        objectFit: 'contain',
        width: 39,
        height: 39,
        left: -75,
        top: -20,
        [theme.breakpoints.down('mobile')]: {
            width: 20,
            height: 20,
            left: -38,
            top: -10,
        },
    },
    titleSymbol2: {
        position: 'absolute',
        objectFit: 'contain',
        width: 29,
        height: 29,
        right: 65,
        top: -40,
        [theme.breakpoints.down('mobile')]: {
            width: 15,
            height: 15,
            left: 33,
            top: -20,
        },
    },
    titleSymbol3: {
        position: 'absolute',
        objectFit: 'contain',
        width: 40,
        height: 47,
        right: -70,
        top: -25,
        [theme.breakpoints.down('mobile')]: {
            width: 20,
            height: 24,
            right: -35,
            top: -13,
        }
    },
    leftImage: {
        width: 106,
        height: 'fit-content',
        marginTop: 6,
        marginRight: 20,
        [theme.breakpoints.down('mobile')]: {
            width: 53,
            marginTop: 3,
            marginRight: 10,
        },
    },
    rightImage: {
        marginTop: 12,
        width: 120,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 6,
            width: 60,
        },
    },
    moreButton: {
        display: 'flex',
        width: 156,
        height: 47,
        paddingBottom: 8,
        borderRadius: 13,
        backgroundColor: '#F3F3F3',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'NotoSans',
        fontSize: 30,
        color: '#B7B7B7',
        letterSpacing: -0.21,
        marginTop: 26,
        textDecoration: 'none',
        [theme.breakpoints.down('mobile')]: {
            width: 78,
            height: 28,
            borderRadius: 7,
            fontSize: 15,
            letterSpacing: -0.1,
            marginTop: 7,
        },
        [theme.breakpoints.up('mobile')]: {
            '&:hover': {
                backgroundColor: '#F6C59A',
            },
        },
    },
}));

const MenuSlider = ({ id, title, data, sliderSpeed }) => {
    const classes = useMenuSliderStyles();

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                WAFFLE STUDIO
                <img alt=' ' className={classes.titleSymbol1} src={require('../res/ui/flower1.png')} />
                <img alt=' ' className={classes.titleSymbol2} src={require('../res/ui/flower2.png')} />
                <img alt=' ' className={classes.titleSymbol3} src={require('../res/ui/twinkle.png')} />
            </div>
            <div className={classes.titleContainer}>
                <img alt='이전' className={classes.leftImage} src={require('../res/ui/title_symbol_left.png')} />
                <div className={classes.title}>{title}</div>
                <img alt='다음' className={classes.rightImage} src={require('../res/ui/title_symbol_right.png')} />
            </div>
            <MenuItemSlider data={data} speed={sliderSpeed} />
            <Link className={classes.moreButton} to={'/menu/' + id}>more</Link>
        </div>
    )
}

export default Home;