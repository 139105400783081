import { Add } from "@mui/icons-material";
import { Fab, Pagination, Portal, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { query } from "../../common/NetworkManager";
import { RouteChangeTracker } from "../../common/Utils";
import { PaperStylePage } from "../../components";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        marginBottom: 100,
        [theme.breakpoints.down('mobile')]: {
            marginBottom: 37,
        },
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 75,
        letterSpacing: -4.13,
        lineHeight: '78px',
        marginTop: 70,
        textAlign: 'center',
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 22,
            letterSpacing: -1.12,
            lineHeight: '22px',
            marginTop: 40,
        },
    },
    tableContainer: { '&&': {
        width: 'calc(100% - 32px)',
        display: 'block',
        maxWidth: 1110,
        whiteSpace: 'nowrap',
        marginTop: 184,
        marginBottom: 16,
        borderTop: '2px solid #F5841E',
        [theme.breakpoints.down('mobile')]: {
            maxWidth: 270,
            marginTop: 30,
            '& td:nth-child(n+2)': {
                display: 'none',
            },
        },
        '& td': {
            cursor: 'pointer',
            color: theme.palette.text.primary,
            fontFamily: 'NotoSans',
            fontWeight: 300,
            fontSize: 18,
            letterSpacing: -0.17,
            padding: 20,
            [theme.breakpoints.down('mobile')]: {
                padding: 15,
            },
        },
        '& tr': {
            textAlign: 'center',
            [theme.breakpoints.up('mobile')]: {
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        '& td, th': {
            textAlign: 'center',
        },
        '& td:nth-child(1)': {
            position: 'relative',
            textAlign: 'left',
            width: '100%',
            fontWeight: 400,
            fontSize: 22,
            whiteSpace: 'pre',
            [theme.breakpoints.down('mobile')]: {
                fontSize: 14,
                letterSpacing: -0.1,
            },
        },
        '& td:nth-child(2 + 1)': {
            textAlign: 'left',
        },
    }},
    itemTitle: {
        position: 'absolute',
        padding: 'inherit',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    pagination: {
        '&& .Mui-selected': {
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
        },
    },
}));

const Notice = ({ admin }) => {
    RouteChangeTracker('/community/notice');
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = useState();
    const [page, setPage] = useState(1);
    const [pageCnt, setPageCnt] = useState();

    useEffect(() => {
        const fetchData = async() => {
            const data = await query('getNotice', { page: params.page || 1 });
            setData(data[0]);
            setPage(data[1][0].page);
            setPageCnt(data[1][0].pageCnt);
        }
        fetchData();
    }, [params]);

    const url = useMemo(() => admin ? '/admin/notice' : '/community/notice', [admin]);

    return (
        <PaperStylePage className={classes.container}>
            <div className={classes.title}>와플스튜디오 공지사항</div>
            <TableContainer className={classes.tableContainer}>
            <Table>
                <TableBody>
                    {data && data.map(item => (
                        <TableRow key={item.id} onClick={() => navigate(`${url}/read/${item.id}`)}>
                            <TableCell>{' '}<div className={classes.itemTitle}>{item.title}</div></TableCell>
                            <TableCell>{item.regDttm}</TableCell>
                            <TableCell>{item.views}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
            <Pagination
                className={classes.pagination}
                page={page}
                count={pageCnt}
                shape='circular'
                onChange={(evt, number) => navigate(`${url}/${number}`)}
                color= 'secondary'
            />
            {admin &&
            <Portal>
                <Fab
                    color='primary'
                    style={{ position: 'fixed', bottom: 32, right: 32 }}
                    onClick={() => navigate('/admin/notice/edit')}
                >
                    <Add />
                </Fab>
            </Portal>}
        </PaperStylePage>
    )
}

export default Notice;