import { IconButton as MuiButton, Tooltip } from '@mui/material';
import React from 'react';

const IconButton = ({ tooltip, icon, disableTooltip, disabled, ...btnProps }) => {
    return (
        disableTooltip ?
        <MuiButton component='div' disabled={disabled} {...btnProps}>
            {icon}
        </MuiButton> :
        <Tooltip title={tooltip}>
            <MuiButton component='div' disabled={disabled} {...btnProps}>
                {icon}
            </MuiButton>
        </Tooltip>
    )
}

export default IconButton;