import clsx from 'clsx';
import { Check, Close } from "@mui/icons-material";
import { CircularProgress, Dialog, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useState } from "react";
import IconButton from "./IconButton";

const useStyles = makeStyles(theme => ({
    dialog: {
        justifyContent: 'center',
        whiteSpace: 'pre-line',
    },
    paper: {
        padding: 28,
    },
    buttonContainer: {
        justifyContent: 'end',
        marginTop: 16,
    },
    error: {
        color: theme.palette.error.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    success: {
        color: theme.palette.success.main,
    },
}));

const QuestionDialog = ({ open, onClose, onConfirm, variant, msg }) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const handleConfirm = async() => {
        setLoading(true);
        
        await onConfirm();

        setLoading(false);
        onClose();
    }

    return (
        <Dialog
            className={classes.dialog}
            PaperProps={{ className: classes.paper }}
            open={open}
        >
            <Typography fontSize={18}>{msg}</Typography>
            <div className={classes.buttonContainer}>
                {loading ?
                <CircularProgress /> :
                <Fragment>
                    <IconButton
                        tooltip='취소'
                        icon={<Close />}
                        onClick={onClose}
                    />
                    <IconButton
                        tooltip='확인'
                        icon={<Check className={clsx({
                            [classes.error]: variant === 'error',
                            [classes.warning]: variant === 'warning',
                            [classes.success]: variant === 'success',
                        })} />}
                        onClick={handleConfirm}
                    />
                </Fragment>}
            </div>
        </Dialog>
    )
}

export default QuestionDialog;