import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { getImageUrl } from "../common/NetworkManager";
import { numberFormat } from "../common/Utils";

const useStyles = makeStyles(theme => ({
    container: {
        width: 300,
        margin: 20,
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
            width: 150,
            margin: 8,
        },
    },
    imageContainer: {
        backgroundColor: '#FEFEFE',
        position: 'relative',
        display: 'flex',
        width: 300,
        height: 300,
        marginBottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '2px 2px 2px #00000029',
        borderRadius: 13,
        overflow: 'hidden',
        cursor: 'pointer',
        boxSizing: 'border-box',
        border: '2px solid transparent',
        [theme.breakpoints.down('mobile')]: {
            width: 150,
            height: 150,
            borderRadius: 7,
        },
    },
    clickable: {
        [theme.breakpoints.up('mobile')]: {
            '&:hover': {
                border: '2px solid #F6C59A',
            },
        }
    },
    unclickable: {
        cursor: 'default',
    },
    image: {
        width: 200,
        height: 200,
        objectFit: 'contain',
        [theme.breakpoints.down('mobile')]: {
            width: 100,
            height: 100,
        },
    },
    fixedContainer: {
        position: 'absolute',
        alignItems: 'start',
        top: 14,
        left: 14,
        [theme.breakpoints.down('mobile')]: {
            top: 6,
            left: 6,
        },
    },
    best: {
        justifyContent: 'center',
        color: theme.palette.secondary.main,
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: -0.49,
        paddingLeft: 4,
        paddingRight: 4,
        border: '2px solid ' + theme.palette.secondary.main,
        [theme.breakpoints.down('mobile')]: {
            transform: 'scale(0.5)',
        },
    },
    season: {
        justifyContent: 'center',
        color: theme.palette.primary.main,
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: -0.49,
        paddingLeft: 4,
        paddingRight: 4,
        border: '2px solid ' + theme.palette.primary.main,
        [theme.breakpoints.down('mobile')]: {
            transform: 'scale(0.5)',
        },
    },
    hot: {
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: 4,
        right: 50,
        bottom: 36,
        backgroundColor: '#F85C5C',
        [theme.breakpoints.down('mobile')]: {
            width: 4,
            height: 4,
            borderRadius: 2,
            right: 24,
            bottom: 17,
        },
    },
    ice: {
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: 4,
        right: 38,
        bottom: 36,
        backgroundColor: '#5CAAF8',
        [theme.breakpoints.down('mobile')]: {
            width: 4,
            height: 4,
            borderRadius: 2,
            right: 18,
            bottom: 17,
        },
    },
    nameText: {
        fontFamily: 'NotoSans',
        fontSize: 15,
        color: theme.palette.text.primary,
        letterSpacing: -0.38,
        marginTop: 19.5,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 14,
            letterSpacing: -0.35,
            marginTop: 5,
        },
    },
    priceContainer: {
        alignItems: 'center',
        marginTop: 8.5,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 2,
        },
    },
    priceText: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 15,
        },
    },
    unitText: {
        fontFamily: 'NotoSans',
        fontSize: 15,
        color: theme.palette.text.primary,
        marginLeft: 2,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 14,
        },
    },
}));

const MenuItem = ({ data, onClick }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div
                onClick={() => onClick(data)}
                className={clsx(classes.imageContainer, {
                    [classes.unclickable]: onClick == null,
                    [classes.clickable]: onClick != null,
                })}
            >
                <img alt=' ' className={classes.image} src={getImageUrl(`${data.image}_240`)} />
                <div className={classes.fixedContainer}>
                    {!!data.best && <div className={classes.best}>BEST</div>}
                    {!!data.season && <div className={classes.season}>SEASON</div>}
                </div>
                {!!data.hot && <div className={classes.hot} />}
                {!!data.ice && <div className={classes.ice} />}
            </div> 
            <div className={classes.nameText}>{data.name}</div>
            <div className={classes.priceContainer}>
                <div className={classes.priceText}>{numberFormat(data.price)}</div>
                <div className={classes.unitText}>원</div>
            </div>
        </div>
    )
}

export default MenuItem;