import clsx from 'clsx';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    image: {
        flex: 1,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
}));

const ImageBox = ({ src, className, opacity = 1, style, ...props }) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.image, className)}
            style={{
                backgroundImage: `linear-gradient(rgba(255,255,255,${1 - opacity}), rgba(255,255,255,${1 - opacity})), url(${src})`,
                ...style
            }}
            {...props}
        />
    )
}

export default ImageBox;