import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import MenuItem from "./MenuItem";

const useStyles = makeStyles(theme => ({
    slider: {
        width: 998,
        marginTop: 30,
        '& .arrowButton': {
            position: 'absolute',
            top: 120,
            width: 60,
            height: 60,
            background: '#F9F9F9',
            boxShadow: '2px 2px 2px #00000029',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        [theme.breakpoints.down('mobile')]: {
            width: 493,
        },
        [theme.breakpoints.up('mobile')]: {
            '& .arrowButton:hover': {
                backgroundColor: '#F6C59A',
            },
        }
    },
}));

const SamplePrevArrow = ({ style, onClick, direction }) => {
    return (
        <div
            style={{
                ...style,
                display: 'flex',
                right: direction === 'right' ? -106 : 'unset',
                left: direction === 'left' ? -106 : 'unset',
            }}
            className='arrowButton'
            onClick={onClick}
        >
            <img
                alt={direction === 'left' ? '이전' : '다음'}
                src={direction === 'left' ? require('../res/icon/arrow_L.png') : require('../res/icon/arrow_R.png')}
            />
        </div>
    );
}

const MenuItemSlider = ({ data, onClick, speed = 5000, ...props }) => {
    const classes = useStyles();

    return (
        <Slider
            prevArrow={<SamplePrevArrow direction='left' />}
            nextArrow={<SamplePrevArrow direction='right' />}
            className={classes.slider}
            autoplay
            autoplaySpeed={parseInt(speed)}
            pauseOnHover={false}
            slidesToShow={3}
            infinite
            {...props}
        >
            {data.map(item => (
                <MenuItem key={item.id} data={item} onClick={onClick} />
            ))}
        </Slider>
    )
}

export default MenuItemSlider;