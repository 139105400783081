import { makeStyles } from "@mui/styles";
import { RouteChangeTracker } from "../../common/Utils";
import { PaperStylePage } from "../../components";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
    },
    subTitle: {
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontWeight: 300,
        fontSize: 36,
        letterSpacing: -2.52,
        marginTop: 70,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 10,
            letterSpacing: -0.66,
            marginTop: 45,
        },
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: 'SCDream',
        fontSize: 75,
        letterSpacing: -4.13,
        lineHeight: '78px',
        textAlign: 'center',
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 22,
            letterSpacing: -1.12,
            lineHeight: '22px',
            marginTop: 4,
        },
    },
    remark: {
        fontFamily: 'NotoSans',
        fontSize: 18,
        letterSpacing: -0.9,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.6,
        },
    },
    tableContainer: {
        flexDirection: 'column',
        alignSelf: 'center',
        width: 'calc(100% - 32px)',
        maxWidth: 1110,
        marginTop: 160,
        marginBottom: 100,
        [theme.breakpoints.down('mobile')]: {
            width: 'calc(100% - 8px)',
            maxWidth: 270,
            marginTop: 50,
            marginBottom: 35,
        },
    },
    table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        marginTop: 20,
        marginBottom: 30,
        wordBreak: 'keep-all',
        [theme.breakpoints.down('mobile')]: {
            marginTop: 10,
            marginBottom: 15,
            wordBreak: 'unset',
        },
        '& th': {
            border: '1px inset #FEFEFE',
            color: theme.palette.background.default,
            backgroundColor: '#F5841E',
            fontFamily: 'NotoSans',
            fontWeight: 400,
            fontSize: 18,
            letterSpacing: -0.9,
            padding: 16,
            whiteSpace: 'nowrap',
            textAlign: 'center',
            [theme.breakpoints.down('mobile')]: {
                fontSize: 12,
                letterSpacing: -0.6,
                padding: 8,
            },
        },
        '& td': {
            borderLeft: '1px inset #DCDCDC',
            borderBottom: '1px inset #333333',
            color: theme.palette.text.primary,
            fontFamily: 'NotoSans',
            fontSize: 18,
            letterSpacing: -0.9,
            lineHeight: '30px',
            padding: 16,
            height: 140,
            backgroundColor: theme.palette.background.default,
            textAlign: 'center',
            wordBreak: 'keep-all',
            [theme.breakpoints.down('mobile')]: {
                fontSize: 12,
                letterSpacing: -0.6,
                lineHeight: '15px',
                padding: 8,
                height: 'unset',
            },
        },
        '& td:first-child': {
            borderLeft: 'unset',
        },
        '& tr:last-child > td': {
            backgroundColor: '#003DA8',
            color: theme.palette.background.default,
            borderBottom: 'unset',
            height: 'unset',
        },
        '& tr:last-child > td:nth-child(n+3)': {
            borderLeft: 'none',
        },
    },
    remark2: {
        fontFamily: 'NotoSans',
        fontSize: 18,
        letterSpacing: -0.9,
        marginTop: 10,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.6,
            marginTop: 2,
            lineHeight: '12.5px',
        },
    },
    excelDown: {
        fontFamily: 'NotoSans',
        fontSize: 18,
        letterSpacing: -0.9,
        marginTop: 70,
        marginLeft: 8,
        color: theme.palette.text.primary,
        cursor: 'pointer',
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.6,
            marginTop: 26,
            marginLeft: 6,
        },
    },
    excelDownImage: {
        marginLeft: 32,
        [theme.breakpoints.down('mobile')]: {
            marginLeft: 8,
            width: 16,
            height: 16,
        },
    },
}));

const Procedure = () => {
    RouteChangeTracker();
    const classes = useStyles();
    
    return (
        <PaperStylePage className={classes.container}>
            <div className={classes.subTitle}>와플스튜디오 창업비용</div>
            <div className={classes.title}>{'FINANCIAL\nINFORMATION'}</div>
            <div className={classes.tableContainer}>
                <div className={classes.remark}>33.05㎡(10평) 기준 비용</div>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <th>구분</th>
                            <th>비용</th>
                            <th>적용범위</th>
                            <th>비고</th>
                        </tr>
                        <tr>
                            <td>가맹비</td>
                            <td>5,000,000</td>
                            <td>영업관리 및 경영교육 노하우전수, 브랜드사용 등</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>이행보증금</td>
                            <td>1,000,000</td>
                            <td>계약종료시 반환 / 부가세 없음</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>교육비</td>
                            <td>3,000,000</td>
                            <td>본사 교육 3일, 현장 교육 3일</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>인테리어</td>
                            <td>30,000,000</td>
                            <td>내외부공사, 간판, 어닝 등</td>
                            <td>철거, 승압, 냉난방기, 의탁자 협의</td>
                        </tr>
                        <tr>
                            <td>기기설비</td>
                            <td>15,000,000</td>
                            <td>냉장고, 냉동고, 와플기, 빙수기, 커피머신, 젤라또 쇼케이스 등</td>
                            <td>카페형 추가 기물(디스펜서, 그라인더 등)에 따른 추가 비용 발생</td>
                        </tr>
                        <tr>
                            <td>총 합계</td>
                            <td>54,000,000</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div className={classes.remark2}>상기 개설 금액은 점포 상황 및 기타 요구사항에 따라 비용이 달라질 수 있습니다.</div>
                <div className={classes.remark2}>초도물품 약 500만원 : 최초 오픈 시 각종 원부재료 세팅비용</div>
                <div className={classes.remark2}>평당감리비 : 30만원</div>
                <div className={classes.remark2}>로열티 : 월 20만원</div>
                <div className={classes.excelDown} onClick={() => window.location.href = '/와플스튜디오_창업비용.xlsx'}>
                    엑셀 파일 다운로드
                    <img
                        alt=' '
                        className={classes.excelDownImage}
                        src={require('../../res/icon/down.png')}
                    />
                </div>
            </div>
        </PaperStylePage>
    )
}

export default Procedure;