import { Delete, Drafts, Mail } from "@mui/icons-material";
import { AppBar, Avatar, CircularProgress, Divider, List, ListItemAvatar, ListItemButton, ListItemText, Paper, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useState } from "react";
import { query } from "../../common/NetworkManager";
import { IconButton, ProgressDialog, QuestionDialog } from "../../components";

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
    },
    leftPanel: {
        flexDirection: 'column',
    },
    rightPanel: {
        flexDirection: 'column',
        flex: 1,
    },
    title: { '&&': {
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
        margin: 24,
    }},
    list: {
        width: 270,
        overflowY: 'scroll',
        overflowX: 'hidden',
        '& .MuiListItemText-root': {
            display: 'block',
        },
        whiteSpace: 'nowrap',
        flex: 1,
    },
    idText: {
        backgroundColor: '#757575',
        alignSelf: 'center',
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: 4,
        minWidth: 20,
    },
    newMail: { '&&': {
        backgroundColor: theme.palette.primary.main,
    }},
    toolbar: {
        justifyContent: 'space-between',
    },
    content: {
        flexDirection: 'column',
        flex: 1,
        height: 0,
    },
    textContainer: {
        flexDirection: 'column',
        overflowY: 'auto',
        padding: 24,
        flex: 1,
    },
    progressContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        padding: 12,
        paddingLeft: 24,
        '& :first-child': {
            width: 200,
            fontWeight: 'bold',
        },
    },
}))

const AdminCounseling = () => {
    const [data, setData] = useState();
    const [selection, setSelection] = useState();
    const [selectData, setSelectData] = useState({});
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const fetchData = useCallback(async() => {
        setLoading(true);

        const data = await query('getCounseling');
        setData(data);

        setLoading(false);
    }, []);

    useEffect(() => { fetchData() }, [fetchData]);

    const onSelect = async(id) => {
        setSelection(id);
        setSelectData({});

        const queryData = await query('readCounseling', { id });
        if (queryData[0].id != null) {
            setSelectData(queryData[0]);
        } else {
            setSelectData(queryData[1][0]);
        }

        setData(data.map(item => item.id === id ? { ...item, isRead: true } : item));
    }

    const onDelete = async() => {
        await query('delCounseling', { id: selectData.id });
        await fetchData();
        enqueueSnackbar('삭제가 완료되었습니다.', { variant: 'success' });
        setSelection();
        setSelectData({});
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.leftPanel}>
                <Typography className={classes.title}>창업 상담</Typography>
                <List className={classes.list}>
                    {data && data.map(item => (
                        <ListItemButton
                            key={item.id}
                            selected={selection === item.id}
                            onClick={() => onSelect(item.id)}
                            disabled={selection != null && selectData.id == null}
                        >
                            <ListItemAvatar>
                                <Avatar className={item.isRead ? '' : classes.newMail}>
                                    {item.isRead ? <Drafts /> : <Mail />}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<div>
                                    <Typography className={classes.idText} fontSize={11}>{item.id}</Typography>
                                    <Typography style={{ marginLeft: 4 }}>{item.name}</Typography>
                                </div>}
                                secondary={`${item.dttm}`}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </div>
            <div className={classes.rightPanel}>
                <AppBar position='static'>
                    <Toolbar className={classes.toolbar} component="h2" variant="h6">
                        <div style={{ alignItems: 'center' }}>
                            <Avatar>
                                <Drafts />
                            </Avatar>
                            <Typography className={classes.idText} sx={{ marginLeft: 2, marginRight: 2, fontWeight: 'bold' }}>
                                {selectData.id}
                            </Typography>
                            <Typography>
                                {selectData.name}{selectData.region && `(${selectData.region})`}
                            </Typography>
                        </div>
                        <IconButton
                            tooltip='삭제'
                            icon={<Delete style={{ color: selectData.id != null ? 'red' : 'unset' }} />}
                            onClick={() => setDeleteDialog(true)}
                            disabled={selectData.id == null}
                        />
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    {selection != null && selectData.id == null ?
                    <div className={classes.progressContainer}>
                        <CircularProgress />
                    </div> :
                    <div className={classes.textContainer}>
                        {selectData.content && selectData.content.split('\n').map((content, i) => (
                            <Typography key={i}>{content}</Typography>
                        ))}
                    </div>}
                    <div style={{ flexDirection: 'column' }}>
                        <Divider />
                        {Object.keys(fieldName).map(field => <Fragment key={field}>
                            <div className={classes.row}>
                                <Typography>{fieldName[field]}</Typography>
                                <Typography>{selectData[field]}</Typography>
                            </div>
                            <Divider />
                        </Fragment>)}
                    </div>
                </div>
            </div>
            <QuestionDialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                onConfirm={onDelete}
                variant='error'
                msg='삭제하시겠습니까?'
            />
            <ProgressDialog open={loading} />
        </Paper>
    )
}

const fieldName = {
    name: '이름',
    region: '창업희망지역',
    tel: '전화번호',
    email: '이메일',
    ip: 'IP',
    dttm: '문의일시',
}

export default AdminCounseling;