import clsx from 'clsx';
import { Add, AddPhotoAlternate, Check, Clear, Delete, ImageSearch, KeyboardArrowDown, KeyboardArrowUp, Save, Sort } from "@mui/icons-material";
import { AppBar, Avatar, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMemo, useState } from "react";
import { getImageUrl, query, uploadBannerImage } from "../../common/NetworkManager";
import { FilePicker, IconButton, ImageBox, ProgressDialog, QuestionDialog } from "../../components";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
    container: {
        flex: 1,
    },
    leftPanel: {
        flexDirection: 'column',
    },
    rightPanel: {
        flexDirection: 'column',
        flex: 1,
    },
    header: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: { '&&': {
        fontSize: 30,
        fontWeight: 'bold',
        color: 'white',
        margin: 24,
    }},
    list: {
        flexDirection: 'column',
        width: 320,
        overflowY: 'scroll',
        flex: 1,
    },
    listItem: {
        flexDirection: 'column',
        backgroundColor: 'rgba(255, 255, 255, 0.06)',
        margin: 16,
        minHeight: 220,
        borderRadius: 12,
    },
    listFooter: {
        padding: 12,
        borderTop: '1px solid rgba(255, 255, 255, 0.12)',
        alignItems: 'center',
    },
    idText: { '&&': {
        backgroundColor: '#757575',
        alignSelf: 'center',
        textAlign: 'center',
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: 4,
        minWidth: 20,
        marginRight: 4,
    }},
    selected: {
        background: 'rgba(144, 202, 249, 0.20)',
    },
    noImage: { '&&': {
        width: 100,
        height: 100,
        bottom: 'unset',
        right: 'unset',

    }},
    toolbar: {
        justifyContent: 'space-between',
    },
    content: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 0,
        overflowX: 'auto',
    },
    contentImage: {
        height: 'calc(100% - 100px)',
        aspectRatio: '16 / 9',
        objectFit: 'cover',
    },
    contentPicker: {
        width: 80,
        height: 80,
    },
    desc: {
        alignSelf: 'end',
    },
}));

const DEFAULT_DATA = {
    linkUrl: ''
}

const AdminBanner = ({ data, fetchBannerData }) => {
    const [selected, setSelected] = useState();
    const [sortSelected, setSortSelected] = useState();
    const [isChanged, setIsChanged] = useState(false);
    const [sortData, setSortData] = useState();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [changeDialog, setChangeDialog] = useState();
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const selectedIdx = useMemo(() => selected && data.findIndex(item => item.id === selected.id), [data, selected]);
    const sortSelectedIdx = useMemo(() => sortData && sortData.findIndex(item => item.id === sortSelected), [sortData, sortSelected]);

    const onItemSelect = item => {
        if (sortData) {
            setSortSelected(item.id);
        } else {
            if (isChanged) {
                setChangeDialog(() => () => {
                    setSelected(item);
                    setIsChanged(false);
                });
            } else {
                setSelected(item);
                setIsChanged(false);
            }
        }
    }

    const onImageSelect = async(file) => {
        setLoading(true);

        const key = await uploadBannerImage(file);
        if (key != null) {
            setSelected(addData => ({ ...addData, image: key }));
            setIsChanged(true);
        }

        setLoading(false);
    }

    const isValid = () => {
        if (selected.image == null) {
            enqueueSnackbar('사진을 선택해 주세요.', { variant: 'error' });
            return false;
        }
        if (selected.linkUrl !== '' && selected.linkUrl[0] !== '/') {
            enqueueSnackbar('Link URL을 올바로 입력해주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onSave = async() => {
        if (isValid()) {
            setLoading(true);
    
            const result = await query('saveBanner', selected);
            await fetchBannerData();
    
            const id = selected.id != null ? selected.id : result.insertId;
            setSelected({ ...selected, id });
    
            enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });
            setIsChanged(false);
            setLoading(false);
        }
    }

    const onDelete = async() => {
        await query('delBanner', { id: selected.id });
        await fetchBannerData();
        enqueueSnackbar('삭제가 완료되었습니다.', { variant: 'success' });
        setSelected();
    }

    const onSortSave = async() => {
        setLoading(true);

        await query('saveBannerSort', { json: sortData.map((item, idx) => ({ id: item.id, sort: idx })) });
        await fetchBannerData();

        setSortData();
        enqueueSnackbar('저장이 완료되었습니다.', { variant: 'success' });

        setLoading(false);
    }

    const handleSort = () => {
        if (isChanged) {
            setChangeDialog(() => onSortMode);
        } else {
            onSortMode();
        }
    }

    const onSortMode = () => {
        setSelected();
        setSortSelected(data[0].id);
        setSortData([...data]);
        setIsChanged(false);
    }

    const onSortUp = () => {
        const selected = sortData.splice(sortSelectedIdx, 1);
        sortData.splice(sortSelectedIdx - 1, 0, selected[0]);
        setSortData([...sortData]);
    }

    const onSortDown = () => {
        const selected = sortData.splice(sortSelectedIdx, 1);
        sortData.splice(sortSelectedIdx + 1, 0, selected[0]);
        setSortData([...sortData]);
    }
    
    return (
        <Paper className={classes.container}>
            <div className={classes.leftPanel}>
                <div className={classes.header}>
                    <Typography className={classes.title}>배너 관리</Typography>
                    {!sortData ?
                    <div>
                        <IconButton
                            tooltip='추가'
                            icon={<Add />}
                            onClick={() => onItemSelect(DEFAULT_DATA)}
                        />
                        <IconButton
                            tooltip='정렬'
                            icon={<Sort />}
                            onClick={handleSort}
                            disabled={data.length === 0}
                        />
                    </div> :
                    <div>
                        <IconButton
                            tooltip='위로'
                            icon={<KeyboardArrowUp />}
                            onClick={onSortUp}
                            disabled={sortSelectedIdx === 0}
                        />
                        <IconButton
                            tooltip='뒤로'
                            icon={<KeyboardArrowDown />}
                            onClick={onSortDown}
                            disabled={sortSelectedIdx === sortData.length - 1}
                        />
                        <IconButton
                            tooltip='저장'
                            icon={<Check />}
                            onClick={onSortSave}
                        />
                        <IconButton
                            tooltip='취소'
                            icon={<Clear />}
                            onClick={() => setSortData()}
                        />
                    </div>}
                </div>
                <div className={classes.list}>
                    {(sortData || data).map((item, index) => (
                        <div
                            key={item.id}
                            className={clsx(classes.listItem, {
                                [classes.selected]: sortData ? sortSelected === item.id : selected != null && selected.id === item.id
                            })}
                            onClick={() => onItemSelect(item)}
                        >
                            <ImageBox src={getImageUrl(item.image)} />
                            <div className={classes.listFooter}>
                                <Typography className={classes.idText} fontSize={11}>{index + 1}</Typography>
                                <Typography fontSize={13}>{`Link URL : ${item.linkUrl || '-'}`}</Typography>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.rightPanel}>
                <AppBar position='static'>
                    <Toolbar className={classes.toolbar} component="h2" variant="h6">
                        <div>
                            {selected && (
                            selected.id ?
                            <Avatar>
                                <Typography style={{ color: 'white' }}>{selectedIdx + 1}</Typography>
                            </Avatar> :
                            <Typography fontSize={18}>새 배너 추가</Typography>)}
                        </div>
                        <div>
                            <FilePicker
                                icon={selected && selected.image ? <ImageSearch /> : <AddPhotoAlternate />}
                                onChange={onImageSelect}
                                accept='image'
                                disabled={selected == null}
                            />
                            <IconButton
                                tooltip='저장'
                                icon={<Save />}
                                onClick={onSave}
                                style={{ marginLeft: 8 }}
                                disabled={selected == null}
                            />
                            {selected && selected.id &&
                            <IconButton
                                tooltip='삭제'
                                icon={<Delete style={{ color: 'red' }} />}
                                onClick={() => setDeleteDialog(true)}
                                style={{ marginLeft: 8 }}
                            />}
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    {(selected) &&
                    selected.image ?
                    <img alt=' ' className={classes.contentImage} src={getImageUrl(selected.image)} /> :
                    <FilePicker
                        className={classes.contentPicker}
                        icon={<AddPhotoAlternate />}
                        onChange={onImageSelect}
                        accept='image'
                        disabled={selected == null}
                    />}
                </div>
                <Typography className={classes.desc}>
                    이미지 해상도는 16:9로만 표현됩니다. 해상도 비율이 다를 시 이미지가 잘려서 보일 수 있습니다.
                    </Typography>
                <TextField
                    label='Link URL'
                    placeholder='ex) /community/notice/read/78'
                    value={selected ? selected.linkUrl : ''}
                    onChange={evt => {
                        setSelected({ ...selected, linkUrl: evt.target.value });
                        setIsChanged(true);
                    }}
                    variant="filled"
                    disabled={selected == null}
                    inputProps={{ maxLength: 100 }}
                />
            </div>
            <QuestionDialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                onConfirm={onDelete}
                variant='error'
                msg='삭제하시겠습니까?'
            />
            <QuestionDialog
                open={changeDialog != null}
                onClose={() => setChangeDialog()}
                onConfirm={changeDialog}
                variant='warning'
                msg={`저장되지 않은 내용이 사라집니다.\n계속하시겠습니까?`}
            />
            <ProgressDialog open={loading} />
        </Paper>
    )
}

export default AdminBanner;