import { Button, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { ImageBox, ProgressPanel } from "../../components";
import { SHA256 } from "crypto-js";
import { query } from "../../common/NetworkManager";
import { useNavigate, useOutletContext } from "react-router-dom";
import release from '../../releaseNote.json';

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
    },
    bgLogo: {
        position: 'absolute',
        filter: 'brightness(50%)',
        width: '100%',
        height: '100%',
        backgroundSize: 'auto',
        backgroundRepeat: 'space',
    },
    paper: {
        padding: 50,
        flexDirection: 'column',
        zIndex: 1,
    },
    logo: { '&&': {
        width: 300,
        marginBottom: 50,
    }},
    input: { '&&': {
        marginBottom: 16,
    }},
    button: { '&&': {
        minHeight: 55,
    }},
    version: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white',
    },
}));

const AdminLogin = () => {
    const [input, setInput] = useState({ id: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [loginData, setLoginData] = useOutletContext();
    
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const classes = useStyles();
    
    useEffect(() => {
        if (loginData) {
            navigate('/admin');
        } else if (sessionStorage.getItem('loginId')) {
            const id = sessionStorage.getItem('loginId');
            const password = sessionStorage.getItem('loginKey');
            reqLogin({ id, password });
        } 
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onValid = () => {
        const pattern1 = /[0-9]/
        const pattern2 = /[a-zA-Z]/
        const pattern3 = /[`~!@#$%^&*()\-_=+\\|,<.>/?;:[{\]}'" ]/
        const pw = input.password;

        if (input.id.trim() === '') {
            enqueueSnackbar('ID를 입력해주세요.', { variant: 'error' });
            return false;
        }
        if (!pattern1.test(pw) || !pattern2.test(pw) || !pattern3.test(pw) || pw.length < 8) {
            enqueueSnackbar('PASSWORD는 8자리 이상 문자, 숫자, 특수문자로 구성하여야 합니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const reqLogin = async(data) => {
        setLoading(true);

        const result = await query('login', data);

        if (result.length === 1) {
            sessionStorage.setItem('loginId', data.id);
            sessionStorage.setItem('loginKey', data.password);
            setLoginData(result[0]);
            navigate('/admin');
        } else {
            enqueueSnackbar('ID 또는 PASSWORD를 다시 확인해주세요.', { variant: 'error' });
        }
        setLoading(false);
    }

    const onLogin = async() => {
        if (onValid()) {
            const id = input.id;
            const password = SHA256(input.password).toString();
            reqLogin({ id, password });
        }
    }

    const onKeyDown = evt => {
        if (evt.key === 'Enter') {
            onLogin();
        }
    }
    
    return (
        <div className={classes.container}>
            <ImageBox className={classes.bgLogo} src={require('../../res/logo/vlogo.png')} />
            <Paper className={classes.paper}>
                <img alt=' ' className={classes.logo} src={require('../../res/logo/shoes.png')} />
                <TextField
                    label='ID'
                    value={input.id}
                    onChange={evt => setInput({ ...input, id: evt.currentTarget.value })}
                    onKeyDown={onKeyDown}
                    className={classes.input}
                    inputProps={{ maxLength: 20 }}
                />
                <TextField
                    label='PASSWORD'
                    value={input.password}
                    onChange={evt => setInput({ ...input, password: evt.currentTarget.value })}
                    onKeyDown={onKeyDown}
                    className={classes.input}
                    inputProps={{ maxLength: 20, type: 'password' }}
                />
                {loading ? <ProgressPanel className={classes.button} /> :
                <Button className={classes.button} onClick={onLogin}>
                    LOGIN
                </Button>}
            </Paper>
            <Typography className={classes.version}>v{release.version}</Typography>
        </div>
    )
}

export default AdminLogin;