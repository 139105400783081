import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { PaperStylePage } from "../../components";
import { RouteChangeTracker } from "../../common/Utils";
import { Fade } from 'react-awesome-reveal';

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down('mobile')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    hypen: {
        alignSelf: 'start',
        backgroundColor: theme.palette.text.primary,
        width: 40,
        height: 5,
        marginTop: 130,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 80,
        }
    },
    whyContainer: {
        alignSelf: 'start',
        alignItems: 'center',
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontWeight: 500,
        marginTop: 10,
        [theme.breakpoints.down('mobile')]: {
            flexDirection: 'column',
            alignItems: 'start',
            marginTop: 5,
        },
    },
    whyText1: {
        fontSize: 55,
        letterSpacing: 2.75,
        marginRight: 24,
    },
    whyText2: {
        fontSize: 23,
        letterSpacing: 1.15,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 10,
        },
    },
    content: {
        flexDirection: 'column',
        marginTop: 118,
        [theme.breakpoints.down('mobile')]: {
            marginTop: 138,
        },
        '& > div:nth-child(n+2)': {
            marginTop: 140,
            [theme.breakpoints.down('mobile')]: {
                marginTop: 92,
            },
        },
    },
    item: {
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
            flexDirection: 'column',
        },
    },
    itemHeader: {
        alignItems: 'center',
        backgroundColor: '#F37021',
        minWidth: 280,
        width: 280,
        height: 120,
        paddingLeft: 10,
        [theme.breakpoints.down('mobile')]: {
            minWidth: 'unset',
            width: '100%',
        },
    },
    image1: { '&&': {
        width: 215,
        transform: 'translateY(-14px)'
    }},
    text1: { '&&': {
        marginLeft: -25,
    }},
    itemHeaderImage: {
        width: 180,
    },
    itemHeaderText: {
        flexDirection: 'column',
        wordBreak: 'keep-all',
        color: theme.palette.background.default,
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 11,
        letterSpacing: -0.55,
        lineHeight: '13px',
        marginLeft: 11,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 13,
            letterSpacing: -0.65,
            lineHeight: '14px',
            marginLeft: 10,
        },
    },
    itemHeaderText2: {
        marginTop: 10,
    },
    itemText: {
        display: 'block',
        maxWidth: 500,
        flexDirection: 'column',
        whiteSpace: 'pre-line',
        wordBreak: 'keep-all',
        color: theme.palette.text.primary,
        fontFamily: 'NotoSans',
        fontSize: 18,
        letterSpacing: -0.9,
        lineHeight: '24px',
        marginLeft: 100,
        '& u': {
            textDecoration: 'none',
            background: 'linear-gradient(to top, #F37021 30%, transparent 0%)'
        },
        [theme.breakpoints.down('mobile')]: {
            fontSize: 12,
            letterSpacing: -0.6,
            lineHeight: '20px',
            marginTop: 46,
            marginLeft: 0,
            maxWidth: '100%',
        },
    },
    footer: {
        position: 'relative',
        justifyContent: 'center',
        flexWrap: 'wrap',
        whiteSpace: 'pre',
        fontFamily: 'NotoSans',
        fontWeight: 500,
        fontSize: 32,
        letterSpacing: -1.66,
        marginTop: 150,
        marginBottom: 100,
        [theme.breakpoints.down('mobile')]: {
            fontSize: 24,
            letterSpacing: -1.25,
            marginTop: 50,
            marginBottom: 40,
            lineHeight: '28px',
        },
    },
    footerUnderline: {
        position: 'absolute',
        backgroundColor: '#F37021',
        width: '105%',
        height: 16,
        bottom: -1,
        zIndex: -1,
        [theme.breakpoints.down('mobile')]: {
            width: '100%',
            height: 14,
            bottom: -7,
        },
    },
}));

const Competition = () => {
    RouteChangeTracker();
    const classes = useStyles();

    return (
        <PaperStylePage className={classes.container}>
            <div className={classes.hypen} />
            <div className={classes.whyContainer}>
                <div className={classes.whyText1}>Why?</div>
                <div className={classes.whyText2}>"와플스튜디오"인가요?</div>
            </div>
            <div className={classes.content}>
                <div className={classes.item}>
                    <div className={classes.itemHeader}>
                        <Fade fraction={0.66} triggerOnce>
                            <img
                                alt=' '
                                className={clsx(classes.itemHeaderImage, classes.image1)}
                                src={require('../../res/common/competition1.png')}
                            />
                        </Fade>
                        <div className={clsx(classes.itemHeaderText, classes.text1)}>
                            <div>체계적<br />근무시스템</div>
                            <div className={classes.itemHeaderText2}>트렌디한 메뉴</div>
                        </div>
                    </div>
                    <div className={classes.itemText}>
                        - 다른 브랜드에서 찾아볼 수 없는 <b><u>차별성 있는 와플</u></b><br />
                        - 고객니즈에 맞는 다양한 와플 종류<br />
                        - 홀/포장/배달 등 <b>다양한 매출시스템</b>으로 매출 극대화<br />
                        - 체계화된 메뉴얼 및 레시피로 <b>전문적인 교육가능</b><br />
                        - 매장 안정성을 높이기 위해 본사 차원에 <b><u>지속적이고 철저한 사후관리</u></b>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.itemHeader}>
                        <Fade fraction={0.66} triggerOnce>
                            <img
                                alt=' '
                                className={classes.itemHeaderImage}
                                src={require('../../res/common/competition2.png')}
                            />
                        </Fade>
                        <div className={classes.itemHeaderText}>
                            <div>합리적 가격</div>
                            <div className={classes.itemHeaderText2}>우수한 품질</div>
                        </div>
                    </div>
                    <div className={classes.itemText}>
                        - 본사의 구매/물류 경쟁력을 통해 우수한 식자재를 빠르고 <b>효율적으로 제공합니다</b><br />
                        - NoSmokeWithOutFire의 엄선된 스페셜 블랜딩만을 사용하여 <b>고퀄리티의 원두</b>로 커피맛을 더욱 끌어올렸습니다<br />
                        - 가격에 비해 월등히 높은 품질을 유지하기 위한 본사의 노력으로, <b><u>낮은 단가에 대한 점주님들의 염려를 덜어드립니다</u></b>
                    </div>
                </div>
                <div className={classes.item}>
                    <div className={classes.itemHeader}>
                        <Fade fraction={0.66} triggerOnce>
                            <img
                                alt=' '
                                className={classes.itemHeaderImage}
                                src={require('../../res/common/competition3.png')}
                            />
                        </Fade>
                        <div className={classes.itemHeaderText}>
                            <div>소비트렌드 분석 독자적 상품</div>
                            <div className={classes.itemHeaderText2}>오직 와플스튜디오!</div>
                        </div>
                    </div>
                    <div className={classes.itemText}>
                        - <b>소비 트렌드를 반영한 신제품의 지속적인 개발, 출시</b>로 기존 고객의 단골 유지, 신규 고객 유치에 유리합니다.<br />
                        - 와플과 음료, 식자재 관련 <b>트렌드를 빠르게 파악하고 분석</b>하여 소비니즈에 맞는 신메뉴를 출시합니다.<br />
                        - 세분화된 분석을 통해 메뉴의 in&out을 진행하며 매년 시즌에 맞는 <u><b>와플스튜디오만의 메뉴</b>를 개발하여 판매합니다.</u>
                    </div>
                </div>
            </div>
            <div className={classes.footer}>
                <div>와플스튜디오는 </div>
                <div>당신의 성공과 함께합니다</div>
                <div className={classes.footerUnderline} />
            </div>
        </PaperStylePage>
    )
}

export default Competition;